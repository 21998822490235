@font-face {
    font-family: 'Acrom';
    src: url('../fonts/Acrom-Regular.eot');
    src: local('Acrom Regular'), local('Acrom-Regular'),
    url('../fonts/Acrom-Regular.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Acrom-Regular.woff') format('woff'),
    url('../fonts/Acrom-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Acrom';
    src: url('../fonts/Acrom-Bold.eot');
    src: local('Acrom Bold'), local('Acrom-Bold'),
    url('../fonts/Acrom-Bold.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Acrom-Bold.woff') format('woff'),
    url('../fonts/Acrom-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Acrom';
    src: url('../fonts/Acrom-Thin.eot');
    src: local('Acrom Thin'), local('Acrom-Thin'),
    url('../fonts/Acrom-Thin.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Acrom-Thin.woff') format('woff'),
    url('../fonts/Acrom-Thin.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'Acrom';
    src: url('../fonts/Acrom-Light.eot');
    src: local('Acrom Light'), local('Acrom-Light'),
    url('../fonts/Acrom-Light.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Acrom-Light.woff') format('woff'),
    url('../fonts/Acrom-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Acrom';
    src: url('../fonts/Acrom-ExtraBold.eot');
    src: local('Acrom ExtraBold'), local('Acrom-ExtraBold'),
    url('../fonts/Acrom-ExtraBold.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Acrom-ExtraBold.woff') format('woff'),
    url('../fonts/Acrom-ExtraBold.ttf') format('truetype');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'Acrom';
    src: url('../fonts/Acrom-Medium.eot');
    src: local('Acrom Medium'), local('Acrom-Medium'),
    url('../fonts/Acrom-Medium.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Acrom-Medium.woff') format('woff'),
    url('../fonts/Acrom-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'CANDARA';
    src: url('../fonts/CANDARA.eot');
    src: local('CANDARA'), url('../fonts/CANDARA.woff') format('woff'), url('../fonts/CANDARA.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}


@font-face {
    font-family: 'HeliosCond';
    src: url('../fonts/HeliosCond-Bold.eot');
    src: local('HeliosCond Bold'), local('HeliosCond-Bold'),
    url('../fonts/HeliosCond-Bold.eot?#iefix') format('embedded-opentype'),
    url('../fonts/HeliosCond-Bold.woff') format('woff'),
    url('../fonts/HeliosCond-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'HeliosCond';
    src: url('../fonts/HeliosCond-Bold-Italic.eot');
    src: local('HeliosCond Bold Italic'), local('HeliosCond-Bold-Italic'),
    url('../fonts/HeliosCond-Bold-Italic.eot?#iefix') format('embedded-opentype'),
    url('../fonts/HeliosCond-Bold-Italic.woff') format('woff'),
    url('../fonts/HeliosCond-Bold-Italic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'HeliosCond';
    src: url('../fonts/HeliosCond-Italic.eot');
    src: local('HeliosCond Italic'), local('HeliosCond-Italic'),
    url('../fonts/HeliosCond-Italic.eot?#iefix') format('embedded-opentype'),
    url('../fonts/HeliosCond-Italic.woff') format('woff'),
    url('../fonts/HeliosCond-Italic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'HeliosCond';
    src: url('../fonts/HeliosCond.eot');
    src: local('HeliosCond Regular'), local('HeliosCond'),
    url('../fonts/HeliosCond.eot?#iefix') format('embedded-opentype'),
    url('../fonts/HeliosCond.woff') format('woff'),
    url('../fonts/HeliosCond.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}


@font-face {
    font-family: 'icons';
    src: url('../fonts/icons.eot?1kn2uv');
    src: url('../fonts/icons.eot?1kn2uv#iefix') format('embedded-opentype'),
    url('../fonts/icons.ttf?1kn2uv') format('truetype'),
    url('../fonts/icons.woff?1kn2uv') format('woff'),
    url('../fonts/icons.svg?1kn2uv#icons') format('svg');
    font-weight: normal;
    font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icons' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-down-arrow:before {
    content: "\e919";
    color: #e2068c;
}
.icon-i_phone:before {
    content: "\e918";
    color: #fff;
}
.icon-check-symbol:before {
    content: "\e917";
    color: #e2068c;
}
.icon-i_metro:before {
    content: "\e911";
    color: #e2068c;
}
.icon-i_brush:before {
    content: "\e912";
    color: #e2068c;
}
.icon-i_heart:before {
    content: "\e913";
    color: #442463;
}
.icon-star:before {
    content: "\e914";
    color: #e2068c;
}
.icon-i_tree:before {
    content: "\e915";
    color: #e2068c;
}
.icon-sofa:before {
    content: "\e916";
    color: #e2068c;
}
/*.icon-i_advants1:before {
    content: "\e90e";
    color: #e2068c;
}*/
.icon-i_advants1:before {
    content: "\e90f";
    color: #e2068c;
}
/*.icon-i_advants1:before,*/
.icon-i_advants2:before {
    content: "\e910";
    color: #e2068c;
}
.icon-i_flat3:before {
    content: "\e90d";
    color: #e2068c;
}
.icon-i_flat2:before {
    content: "\e90c";
    color: #e2068c;
}
.icon-i_flat1:before {
    content: "\e90b";
    color: #e2068c;
}
.icon-play:before {
    content: "\e90a";
    color: #fff;
}
.icon-i2:before {
    content: "\e904";
    color: #e5047f;
}
.icon-i3:before {
    content: "\e905";
    color: #b47fb6;
}
.icon-i4:before {
    content: "\e906";
    color: #59b466;
}
.icon-i5:before {
    content: "\e907";
    color: #cfe3ad;
}
.icon-i6:before {
    content: "\e908";
    color: #adc5de;
}
.icon-i1:before {
    content: "\e909";
    color: #63b2d0;
}
.icon-vk:before {
    content: "\e902";
    color: #7e5c9c;
}
.icon-inst:before {
    content: "\e903";
    color: #7e5c9c;
}
.icon-i_panoram:before {
    content: "\e901";
    color: #442463;
}
.icon-i_video:before {
    content: "\e900";
    color: #442463;
}


@font-face {
  font-family: 'iconsnew';
  src:  url('../fonts/iconsnew.eot?1qn20w');
  src:  url('../fonts/iconsnew.eot?1qn20w#iefix') format('embedded-opentype'),
    url('../fonts/iconsnew.ttf?1qn20w') format('truetype'),
    url('../fonts/iconsnew.woff?1qn20w') format('woff'),
    url('../fonts/iconsnew.svg?1qn20w#iconsnew') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="iconsnew-"]:before, [class*=" iconsnew-"]:before {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'iconsnew' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.iconsnew-1:before {
  content: "\e900";
}
.iconsnew-2:before {
  content: "\e901";
}
.iconsnew-3:before {
  content: "\e902";
}
.iconsnew-4:before {
  content: "\e903";
}
.iconsnew-5:before {
  content: "\e904";
}
.iconsnew-6:before {
  content: "\e905";
}
.iconsnew-7:before {
  content: "\e906";
}
.iconsnew-8:before {
  content: "\e907";
}
.iconsnew-9:before {
  content: "\e908";
}
.iconsnew-10:before {
  content: "\e909";
}
.iconsnew-11:before {
  content: "\e90a";
}
.iconsnew-12:before {
  content: "\e90b";
}
.iconsnew-13:before {
  content: "\e90c";
}

body, div, dl, dt, dd, ul, ol, li, h1, h2, h3, h4, h5, h6, pre, form, fieldset, input, p, blockquote, th, td {
    margin: 0;
    padding: 0;
}

img, object, embed, fieldset {
    vertical-align: middle;
    border: 0;
}

address, caption, cite, code, dfn, em, th, var {
    font-weight: normal;
    font-style: normal;
}

ol, ul {
    list-style: none;
}

caption, th {
    text-align: left;
}

h1, h2, h3, h4, h5, h6 {
    font-size: 100%;
}


input, textarea, button, select {
    outline: none;
}

html {
    height: 100%;
}

body {
    font-family: 'Acrom';
    background: #fff;
    font-weight: normal;
    color: #000;
    width: 100%;
    height: 100%;
}

a {
    text-decoration: underline;

    &:hover {
        text-decoration: none;
    }
}

b, strong {
}

section, header, footer {
    width: 100%;
    overflow: hidden;
    position: relative;
    margin: auto;
    z-index: 1;
}

* {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

*:before,
*:after {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

.clear {
    clear: both;
}

.loading {
    position: absolute;
    width: 100%;
    height: 100%;
    overflow: hidden;
    cursor: wait !important;

    * {
        cursor: wait !important;
        // pointer-events: none !important;
    }

    menu {
        opacity: 0;
    }
}

.box {
    max-width: 1820px;
    margin: auto;
    height: 100%;
    width: 100%;
    display: block;
}

header {
    position: fixed;
    background: #fff;
    z-index: 98;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    -webkit-transition: height 0.5s linear;
    transition: height 0.5s linear;

    overflow: hidden;
    overflow-y: visible;

    .box {
        position: relative;
        /*max-width: 1605px;*/
        max-width: none;
        width: auto;
        margin-left: 5.4%;
    }
}


.loader {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 99;
    -webkit-transition: all 0.4s linear;
    transition: all 0.4s linear;
    left: 0;
    top: 0;

    .logo {
        width: 148px;
        height: 56px;
        position: absolute;
        left: 50%;
        top: 50%;

        -webkit-transform: translate(-50%, -50%) scale(2);
        transform: translate(-50%, -50%) scale(2);
        // -webkit-transition: all 0.2s linear;
        // transition: all 0.2s linear;
        overflow: hidden;
        -webkit-animation-duration: .5s;
        animation-duration: .5s;
        -webkit-animation-name: zoomInLogo;
        animation-name: zoomInLogo;
        -webkit-animation-fill-mode: both;
        animation-fill-mode: both;
        .logo_in {
            /*
            background: url('/img/logo.svg') no-repeat 0 0;
            background-size: contain;
            */
            position: absolute;
            left: 0;
            top: 0;
            height: 100%;
            width: 100%;
            z-index: 0;

            img {
                position: absolute;
                left: 0;
                top: 0;
                height: 100%;
                width: 100%;
                -webkit-transition: all 0.3s;
                transition: all 0.3s;

                & + img {
                    opacity: 0;
                }
            }
        }

        .logo_item {
            position: absolute;
            left: 0;
            top: 0;
            height: 100%;
            width: 100%;
            background-size: contain;
            background-repeat: no-repeat;
            background-position: 0 0;
            z-index: 1;
            -webkit-animation-name: logo_item1_anim;
            animation-name: logo_item1_anim;
            -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
            -webkit-animation-duration: 1s;
            animation-duration: 1s;

            -webkit-animation-timing-function: linear;
            animation-timing-function: linear;

            -webkit-animation-fill-mode: both;
            animation-fill-mode: both;

            img {
                position: absolute;
                left: 0;
                top: 0;
                height: 100%;
            }
        }

        .logo_item1 {
            /*background-image: url('/img/logo_items/logo_i1.svg');*/
        }

        .logo_item2 {
            /*background-image: url('/img/logo_items/logo_i2.svg');*/
            -webkit-animation-delay: 0.5s;
            animation-delay: 0.5s;
        }

        .logo_item3 {
            /*background-image: url('/img/logo_items/logo_i3.svg');*/
            -webkit-animation-duration: 1.2s;
            animation-duration: 1.2s;
        }

        .logo_item4 {
            /*background-image: url('/img/logo_items/logo_i4.svg');*/
        }

        .logo_item5 {
            /*background-image: url('/img/logo_items/logo_i5.svg');*/
            -webkit-animation-delay: 0.5s;
            animation-delay: 0.5s;
        }

        .logo_item6 {
            /*background-image: url('/img/logo_items/logo_i6.svg');*/
            -webkit-animation-delay: 0.35s;
            animation-delay: 0.35s;
        }

        .logo_item7 {
            /*background-image: url('/img/logo_items/logo_i7.svg');*/
            -webkit-animation-duration: 1.2s;
            animation-duration: 1.2s;
            -webkit-animation-delay: 0.2s;
            animation-delay: 0.2s;
        }

        .logo_item8 {
            /*background-image: url('/img/logo_items/logo_i8.svg');*/
            -webkit-animation-delay: 0.5s;
            animation-delay: 0.5s;
        }


    }

}
a.logo.active{
    cursor: default !important;
}
.openForm{
        text-decoration: underline;
        font-weight: 500;
        color: #e2068c;
        text-transform: uppercase;
        margin-left: 30px;
        cursor: pointer;
        transition: 300ms;
        margin-top: -20px;
        margin-bottom: 20px;
        &:hover{
            text-decoration: none;
            color: #fff;
        }
}

.head__tel {
    // position: absolute;
    // top: 0;
    // left: 19.4%;
    // margin-right: 30px;
    font-size: 22px;
    line-height: 22px;
    color: #442463;
    text-align: right;
    /*margin-top: -17px;*/
    height: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;

    transition: all 0.2s 0.1s;
    opacity: 0;
    transform: scale(0.5);
    font-weight: 500;
    white-space: nowrap;

    &__txt{
        font-size: 10px;
        color: #321349;
        text-transform: uppercase;
        margin-top: 5px;
        margin-bottom: -4px
    }

    &--right, &--left{
        text-align: left;
        span{
            font-size: 10px;
            color: #442463;
            text-transform: uppercase;
            line-height: 1;
            display: flex;
            padding-top: 4px;
            justify-content: space-between;
            justify-content: flex-end;
        }
        .popup__lnk{margin-left: 5px;}
    }
    &--left{
        text-align: right;
        span{
            justify-content: flex-end;
        }
    }
    &--right{
        color: #e2068c;
    }
    a{
        color: inherit;
        text-decoration: none;
        transition: 600ms;
        &:hover{transform: scale(1.05); }
    }

    .popup__lnk {
        display: block;
        text-decoration: underline;
        font-size: 10px;
        font-weight: 500;
        color: #e2068c;
        text-transform: uppercase;
        cursor: pointer;

        &:hover {
            text-decoration: none;
        }

    }
    &--white2{
        font-family: 'Acrom';
        text-align: left;
        a{font-size:20px;}
        color: #fff;
        .head__tel__txt{color: #fff; font-size: 13px; padding: 5px 0 8px;}
        .popup__lnk{font-size: 16px;}
    }
}

@media (max-width: 1200px) {
    
    .head__tel--right, .head__tel--left{
        span{
            font-size: 9px;
        }
    }
    .head-links__item{font-size: 12px !important;}
}
.header__area {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 120px;
    z-index: 1;
    display:flex;
    padding-left: 150px;
    padding-right: 90px;
    justify-content: space-around;

    &:hover {
        a {
            -webkit-transition-delay: 0s;
            transition-delay: 0s;
        }
    }

    .but {
        // position: absolute;
        // right: 24.3%;
        // top: 50%;
        // margin-top: -25px;
        opacity: 0;
        transform: scale(0.5);
        transition: all 0.2s ease 0.4s;
    }
}
.head-links{
    display: flex;
    align-items: center;
    transition: all 0.2s 0.1s;
    opacity: 0;
    a{
        margin-left: 0;
        border-color: #442463;
        &:hover{
            border-color: #e2068c;
        }
    }
    &__item{
        color: #e2068c;
        font-size: 13px;text-transform: uppercase;
        font-family: 'Acrom';
        font-weight: 600;
        text-decoration: none;
        transition: 600ms;        
        padding-left: 10px;
        padding-right: 10px;
        &:hover{color: #442463;}
    }
    .but{
        margin-right: 10px;
        color: #442463
    }
}

.head__buts {
    // position: absolute;
    // top: 0;
    // margin-right: 30px;
    height: 100%;
    // left: 40.2%;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
    padding-left: 10px;

    a {
        margin-right: 30px;

        img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }

        &:last-child {
            margin-right: 0 !important;
        }

        &:hover {
            -webkit-transform: scale(1.2);
            transform: scale(1.2);
        }
    }
}

.video__lnk {
    display: block;
    position: relative;
    width: 37px;
    height: 23px;    
    margin-right: 30px;
    transition: all 0.2s ease 0.2s;
    opacity: 0;
    transform: scale(0.5);
    text-decoration: none;
    cursor: pointer;

    &:before {
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        font-size: 23px;
    }
    &:hover {
        transform: scale(1.2) !important;
    }
    @media(max-width: 1500px) {
        margin-right: 15px;
    }
}
.video__lnk__popup{
    opacity: 0;
    position: fixed;
    width: 270px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #e2068c;
    color: #fff;
    top: 105px;
    left: 602px;
    z-index: 999999;
    margin-left: 5.4vw;
    padding: 10px 5px;
    box-sizing: border-box;
    transition: 300ms opacity;
    pointer-events: none;
    &.visible{
        opacity: 1;
        pointer-events: initial !important;
    }
    a{
        display: inline-block;
        height: 100%;
        border: 2px solid #fff;
        width: 50%;
        margin: 0 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        text-decoration: none;
        font-size: 14px;
        transition: 300ms;
         z-index: 1;
         position: relative;
        &:hover{
            background-color: #fff;
            color: #e2068c;
        }
    }
    &:before{
        content: '';
        position: absolute;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 25px 0 0 19px;
        border-color: transparent transparent transparent #e2068c;
        top: 0;
        left: 0;
        transform: translateY(-100%);
    }
    &:after{
        content: '';
        position: absolute;
        width: 120%;
        height: 130px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    @media screen and (max-width: 1665px){
        margin-left: 30px;
    }
}
.video__lnk__popup-mobile{
    width: 100%;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #e2068c;
    color: #fff;
    padding: 10px 5px;
    box-sizing: border-box;
    margin-top: 20px;
    position: relative;
    a{
        display: inline-block;
        height: 100%;
        border: 2px solid #fff;
        width: 50%;
        margin: 0 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        text-decoration: none;
        font-size: 14px;
        transition: 300ms;
        &:hover{
            background-color: #fff;
            color: #e2068c;
        }
    }
    &:before{
        content: '';
        position: absolute;width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 7px 20px 7px;
        border-color: transparent transparent #e2068c transparent;
        top: 0;
        left: 50%;
        transform: translate(-50%, -100%);
    }
}
.panoram__lnk {
    display: block;
    position: relative;
    width: 36px;
    height: 36px;
    line-height: 36px;
    /*
    background: url('/img/i_panoram.svg') no-repeat center center;
    background-size: 100%;
    */
    color: #442463;
    text-align: center;
    font-size: 11px;
    font-family: 'HeliosCond';
    font-weight: bold;
    -webkit-transition: all 0.2s 0.3s;
    transition: all 0.2s 0.3s;
    opacity: 0;
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
    text-decoration: none;

    .icon-i_panoram {
        position: absolute;
        display: block;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        font-size: 36px;
    }
}

.progress__lnk {
	font-size: 32px;
    color: #442662;
    text-decoration: none;
    display: block;
    line-height: 1;
	-webkit-transition: all 0.2s ease 0.2s;
    transition: all 0.2s ease 0.2s;
    opacity: 0;
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
}

.ready {
    header {
        height: 120px;
    }

    .loader {
        width: 148px;

        .logo {
            -webkit-animation-duration: 0.5s;
            animation-duration: 0.5s;
            -webkit-animation-name: zoomNormalLogo;
            animation-name: zoomNormalLogo;

            .logo_in {
                img {
                    opacity: 0;

                    & + img {
                        opacity: 1;
                    }
                }
            }

            .logo_item {
                -webkit-animation-name: none;
                animation-name: none;
                opacity: 0;
            }

        }

        &:hover .logo.active, .logo:hover {
            .logo_in {
                img {
                    opacity: 1;

                    & + img {
                        opacity: 0;
                    }
                }
            }

            .logo_item {
                -webkit-animation-duration: 0.75s;
                animation-duration: 0.75s;
                -webkit-animation-name: logo_item1_anim;
                animation-name: logo_item1_anim;
            }

            .logo_item2 {
                -webkit-animation-delay: 0.37s;
                animation-delay: 0.37s;
            }

            .logo_item3 {
                -webkit-animation-duration: 0.85s;
                animation-duration: 0.85s;
            }

            .logo_item5 {
                -webkit-animation-delay: 0.37s;
                animation-delay: 0.37s;
            }

            .logo_item6 {
                -webkit-animation-delay: 0.25s;
                animation-delay: 0.25s;
            }

            .logo_item7 {
                -webkit-animation-duration: 0.85s;
                animation-duration: 0.85s;
                -webkit-animation-delay: 0.15s;
                animation-delay: 0.15s;
            }

            .logo_item8 {
                -webkit-animation-delay: 0.37s;
                animation-delay: 0.37s;
            }
        }

    }

    .head__tel, .video__lnk, .panoram__lnk, .progress__lnk, .but, .head-links {
        -webkit-transform: scale(1);
        transform: scale(1);
        opacity: 1;

    }

    menu {
        &.open {
            -webkit-transform: translateX(0px);
            transform: translateX(0px);
        }

        &.fixed {
            position: fixed;
        }
        z-index: 999
    }

    .content__block {
        -webkit-transition: opacity 0.5s 0.3s;
        transition: opacity 0.5s 0.3s;
        opacity: 1;
    }

}


.but {
    position: relative;
    margin: auto;
    height: 51px;
    line-height: 45px;
    color: #e2068c;
    border: solid 3px #e2068c;
    text-align: center;
    padding: 0 27px;
    text-decoration: none;
    text-transform: uppercase;
    font-size: 13px;
    font-weight: bold;
    background-color: #fff;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    display: block;

    &:hover {
        background-color: #e2068c;
        color: #fff;
    }
}
.submenu {
    display: none;
}
.menu-ready .submenu {
    display: block;
}
.overlay{
    position: fixed;
    top: 0;
    left: 0;
    width: 98%;
    height: 100%;
    
}

menu {
    width: 370px;
    transform: translateX(310px);
    height: 60px;
    position: fixed;
    right: 0;
    top: 32px;
    color: #fff;
    margin: 0;
    padding: 0;
    transition: top 0.5s, padding 0.5s, height 0.5s, transform 0.5s 0.5s;
    float: right;

    .menu__out {
        position: absolute;
        width: 100%;
        height: 100%;
        overflow: hidden;
        z-index: 1;
        background-color: #442463;
    }

    .menu__detect {
        color: #fff;
        text-transform: uppercase;
        font-size: 15px;
        line-height: 14px;
        font-weight: 500;
        display: block;
        position: relative;
        top: 0;
        left: 0;
        width: 100%;
        height: 50px;
        padding: 21px 0 0 21px;
        transition: all 0.5s;
        text-decoration: none;
        cursor: pointer;

        font-size: 0;

        .burger {
            display: inline-block;
            width: 18px;
            height: 14px;
            margin-left: 0;
            position: relative;
            top: 1px;
            transition: all 0.5s;

            span {
                position: absolute;
                top: 50%;
                left: 0;
                width: 100%;
                height: 2px;
                background-color: #fff;
                border-radius: 2px;
                margin-top: -1px;
                transition: all 0.3s;
            }

            &:before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 2px;
                background-color: #fff;
                border-radius: 2px;
                transition: all 0.3s;
            }

            &:after {
                content: '';
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                height: 2px;
                background-color: #fff;
                border-radius: 2px;
                transition: all 0.3s;
            }
        }


    }


    &.open {
        top: 0;
        margin-top: 0;
        height: 100%;
        transform: translateX(370px);
        transition: top 0.5s, padding 0.5s, height 0.5s, transform 0.5s;
        overflow: visible;

        .menu__out {
            overflow: hidden;
            overflow-y: auto;
        }

        .menu__detect {
            top: 32px;
            -webkit-transform: translateX(13px);
            transform: translateX(13px);
            font-size: 15px;
            padding: 21px 0 0 51px;

            .burger {
                -webkit-transform: translateX(124px);
                transform: translateX(124px);
                margin-left: 29px;
            }
        }


        .menu__detect .burger {
            span {
                opacity: 0;
            }

        }

        .menu__detect span::before {
            top: 6px;
            transform: rotate(45deg);
        }

        .menu__detect span::after {
            bottom: 6px;
            transform: rotate(-45deg);
        }

        .menu__detect span::before,
        .menu__detect span::after {
            transition-delay: 0s, 0.3s;
            background-color: #e2068c;
        }

        .submenu {
            &.active {
                // overflow: hidden;
                // overflow-y: auto;
                -webkit-transform: translateX(0);
                transform: translateX(0);
            }
        }

    }



}


.mdgroup {
    width: 107px;
    height: 60px;
    margin-bottom: 30px;
    position: relative;

    &:before {
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        -webkit-transition: all 0.3s;
        transition: all 0.3s;
        background-image: url('/img/md_group.png');
        background-size: contain;
    }

    &:after {
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        -webkit-transition: all 0.3s;
        transition: all 0.3s;
        background-image: url('/img/md_group_white.png');
        background-size: contain;
        opacity: 0;
    }

}
.menu__content {
    padding: 85px 64px 20px 64px;
    font-family: 'CANDARA';
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    flex-direction: column;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    height: auto;

    .menu__in {
        a {
            color: #fff;
            display: block;
            font-size: 17px;
            line-height: 19px;
            margin-bottom: 22px;
            position: relative;

            &:last-child {
                margin-bottom: 0;
            }

            &:hover, &.active {
                color: #d50b88;
                text-decoration: none;
            }

        }
    }

    .menu__bottom {
        color: #7e5c9c;
        font-size: 12px;
        line-height: 14px;
        margin: 30px 0 30px 0;
        -webkit-transition: all 0.3s;
        transition: all 0.3s;

        &:hover {
            color: #fff;

            .mdgroup {
                &:before {
                    opacity: 0;
                }

                &:after {
                    opacity: 1;
                }
            }
        }

        a {
            /*color: #7e5c9c;*/
            position: relative;
            color: #d50b88;

            &:hover {
                color: #fff;
            }

        }

        a.terms__links {
        }


    }


    .menu__socials {
        margin-top: 64px;
        margin-top: 30px;

        a {
            width: 31px;
            height: 31px;
            margin-right: 10px;
            display: inline-block;
            -webkit-transition: all 0.3s;
            transition: all 0.3s;
            position: relative;
            text-decoration: none;
            font-size: 0;

            &:before {
                font-size: 31px;
                -webkit-transition: all 0.3s;
                transition: all 0.3s;
            }

            img {
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                -webkit-transition: all 0.3s;
                transition: all 0.3s;

                & + img {
                    opacity: 0;
                }
            }

            &:hover {
                -webkit-transform: scale(1.18);
                transform: scale(1.18);

                &:before {
                    color: #fff;
                }

                img {
                    opacity: 0;

                    & + img {
                        opacity: 1;
                    }
                }
            }

            &:last-child {
                margin-right: 0;
            }
        }

        /*
         .menu_vk {
           background: url('/img/vk.svg') no-repeat center center;
           background-size: contain;
           &:before {
             content: '';
             background-image: url('/img/vk_wh.svg');
             opacity: 0;
           }
           &:hover {
             background-image: url('/img/vk_wh.svg');
           }
         }
         .menu_inst {
           background: url('/img/inst.svg') no-repeat center center;
           background-size: contain;
           &:before {
             content: '';
             background-image: url('/img/inst_wh.svg');
             opacity: 0;
           }
           &:hover {
             background-image: url('/img/inst_wh.svg');
           }
         }*/
        .menu_vk {
        }

    }

}
.made__in {
    span {
        /*color: #fff;*/
        position: relative;
        display: inline-block;
        text-decoration: underline;
    }

    &:hover {
        span {
            text-decoration: none;
        }
    }
}

.submenu {
    position: absolute;
    height: 100%;
    // right: 100%;
    left: -228px;
    top: 0;
    background-color: #f6f9fd;
    width: 228px;
    -webkit-transition: all 0.5s;
    transition: all 0.5s;
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
    z-index: 0;
    // overflow-y: auto;
    font-family: 'CANDARA';
    overflow: hidden;

    .submenu__in {
        padding: 134px 30px 0 43px;
    }

    a {
        display: block;
        color: #e2068c;
        font-size: 17px;
        line-height: 19px;
        margin-bottom: 22px;

        &:last-child {
            margin-bottom: 0;
        }
        &.active{
            color: #442463;
            text-decoration: none;
        }
    }
}

.submenu-arrow {
    position: absolute;
    top: 130px;
    border: 11px solid transparent;
    border-left: 11px solid #f6f9fd;
    opacity: 0;
    pointer-events: none;
    transition: all 0.3s;
    z-index: 1;
}
.submenu-arrow_sub2{
    top: 172px;
}
.submenu-arrow_sub3{
    top: 212px;
}
.open {
    .submenu-arrow_sub1, .submenu-arrow_sub2, .submenu-arrow_sub3{
        opacity: 1;
    }
}

.main__item--comfort {
    position: absolute;
    height: 100%;
    left: 51%;
    right: 0;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    flex-direction: column;
    -webkit-justify-content: center;
    justify-content: center;
    color: #fff;
    font-family: 'Acrom';
    font-size: 20px;
    line-height: 20px;
    font-weight: 500;
    -webkit-transition: all 0.5s;
    transition: all 0.5s;

    .main__item--title {
        font-weight: bold;
        font-size: 70px;
        line-height: 70px;
        margin-bottom: 45px;
        padding-right: 20%;
    }
}
.main__item--tc{
    color: #fff;
    right: auto;
    left: 0;
    padding-left: 5%;
    padding-right: 0;
    width: 52%;
    background-color: rgba(68, 36, 99, 0.75);
    .main__item--title {
        padding: 0;
        font-size: 40px !important;
        font-weight: 300;
        line-height: 1.2 !important;
        text-transform: uppercase;
        div {
            font-size: 80px !important;
        }
    }
    .main__item--but{
        background-color: #e2068c;
        transform-origin: left;
    }
}

.main__item--findflat {
    padding: 30px;
    position: absolute;
    height: 100%;
    left: 49.8%;
    right: 0;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    flex-direction: column;
    -webkit-justify-content: center;
    justify-content: center;
    color: #fff;
    font-family: 'Acrom';
    font-size: 20px;
    line-height: 20px;
    -webkit-transition: all 0.5s;
    transition: all 0.5s;
    background-color: rgba(68, 36, 99, 0.75);
    text-align: center;

    .main__item--but {
        margin: 20px auto 0 auto;
    }

    .main__item--title {
        font-size: 40px;
        line-height: 1;
        margin-bottom: 20px;

        p {
            margin-top: 6px;
        }

        p small {
            font-size: 40px;
            line-height: 40px;
        }
    }

}
.main__item--but.main__item--but__star{
    text-align: center !important;
    justify-content: center;
}
.main__item--flatprice {
    padding: 30px;
    position: absolute;
    height: 100%;
    left: 0;
    right: 0;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    flex-direction: column;
    -webkit-justify-content: center;
    justify-content: center;
    color: #442463;
    font-family: 'Acrom';
    font-size: 20px;
    line-height: 20px;
    transition: all 0.5s;
    text-align: center;

    .main__item--title {
        font-size: 70px;
        line-height: 95px;

        span {
            font-size: 180px;
        }

        p {
            opacity: 0;
            transform: translateY(30px);
            transition: all 1.5s;
        }
    }

    .main__item--but {
        color: #e2068c;
        margin: 35px auto 0 auto;
        height: 60px;
    }

    .main__item--flatprice__but {
        opacity: 0;
        transform: scale(0.5);
        transition: all 1s;
    }

}

.main__item_present {
	width: 100%;
    height: 100%;
	display: flex;
	justify-content:space-between;
           
	& > div {
	    position: relative;
		width:48%;
		@media (max-width: 1000px) {
			width:100%;
		}
	}
    &-three .main__item--present {
        & > div {
            width:30.2%;
        }
    }
    &-single {
        &>div{width: 100%;}
        .main__item--present{
             padding: 0 0 30px 50px !important;
            & > div {
                width:100%;
                top: 0;
                padding: 0;
            }
            .main__item--small{
                position: static !important;
                padding-top: 25px;
            }
            .main__item--but--whithout-link{
                margin-top: 25px !important;
                max-width: 270px;
                box-sizing: border-box;
                min-width: 0 !important;
            }
            .main__item--text{
                font-size: 1.7vw  !important;
                max-width: 100% !important;
                width: 100%;
                .small{font-size: 1.7vw  !important; max-width: 100% !important; }
            }
        }
    }
}
.main__item--tc.main__item--present {
	position: absolute;
    height: 100%;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    width: 100%;
    padding: 0 7% 21% 10.8%;
    background: none;
    color: #fff;
    font-family: 'Acrom';
    font-size: 20px;
    line-height: 20px;
    font-weight: 300;
    -webkit-transition: all 0.5s;
    transition: all 0.5s;

    &.main__item--present-shad {
        box-shadow: inset 0px -400px 100px -100px rgba(0,0,0,0.4);
    }

    .main__item--title {
		font-size: 2.18vw !important;
		@media (max-width: 1000px) {
			font-size: 42px !important;
		}
		@media (max-width: 1000px) {
			font-size: 35px !important;
		}
		
		div {
			font-size: 1.37vw !important;
			margin-bottom: 3px;
			@media (max-width: 1000px) {
				font-size: 29px !important;
			}
			@media (max-width: 1000px) {
				font-size: 22px !important;
			}
		}
	}
	.main__item--text {
		font-size: 1.1vw;
		max-width: 385px;
		line-height: 1.2;
		margin-top: 21px;
		div {
			font-size: 1.61vw;
			font-weight: 500;
			line-height: 1;
            &.small {
                font-size: 1vw;
                line-height: 1.2;
            }
			@media (max-width: 1000px) {
				font-size: 18px;
				display: inline-block;
			}
			@media (max-width: 1000px) {
				font-size: 14px;
                &.small {
                    font-size: 18px;
                }
			}
		}
		@media (max-width: 1000px) {
			font-size: 16px;
			line-height: 1.2;
		}
		@media (max-width: 350px) {
			font-size: 14px;
			line-height: 1.3;			
			margin-top: 18px;
		}
		&_bold {
			font-weight: 500;
		}
	}
	.main__item--small {
		font-size: 0.82vw;
		position: absolute;
		left: 12.8%;
		right: 18%;
		bottom: 3%;
		line-height:1.25;
		
		@media (max-width: 1000px) {
			position:relative;
			left:0;
			right:0;
			bottom:0;
			margin-top:19px;
			font-size: 14px;
			line-height: 1.3;
			min-height:2px;
		}
		
		@media (max-width: 350px) {
			margin-top: 12px;
			font-size: 12px;
		}
	}
	.main__item--but {
		margin-top: 41px;
		max-width: 270px;
		i {
			margin-right:43px;
			@media (max-width: 1350px) {
				margin-right:25px;
			}
		}
        &.main__item--but--whithout-link {
            background: none;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
            border: solid 2px #fff;
            transform: none !important;
            text-transform: none;
            font-size: 1.4vw;
            line-height: 1.1;
            padding: 0 17px;
            font-weight: 500;
            white-space: nowrap;
            min-width: 80%;
            span {
                font-size: 1.1vw;
            }
        }
		
		@media (max-width: 1100px) {
		    height: 55px;
		}
		@media (max-width: 1000px) {
		    margin: 28px 0 0;
            &.main__item--but--whithout-link {
                font-size: 22px;
                min-width: auto;

                span {
                    font-size: 16px;
                }
            }
		}
		@media (max-width: 350px) {
			margin: 18px 0 0;
		}
	}
	@media (max-width: 1000px) {
		padding: 8% 14%;
	}
	@media (max-width: 350px) {
		padding: 40px 25px;
	}
}

.animated .main__item--flatprice__but{
    opacity: 1;
    transform: scale(1);
}
.animated .main__item--flatprice .main__item--title p{
    opacity: 1;
    transform: translateY(0);
}

.icon-i_house {
    height: 100%;
    &:before {
        content: '';
        display: block;
        position: relative;
        background: url('/img/i_house.svg') no-repeat center center;
        background-size: contain;
        width: 58px;
        height: 100%;
    }
}

.main__item--but i.icon-i_shop {
    margin-right: 43px;
}

.icon-i_shop {
	width: 55px;
    height: 100%;
    &:before {
        content: '';
        display: block;
        position: relative;
        background: url('/img/i_shop.svg') no-repeat center center;
        background-size: contain;
        width: 55px;
        height: 100%;
		@media (max-width: 1350px) {
			width: 44px;
		}
    }
}

.main__item--but i.icon-i_shop {
    margin-right: 40px;
}

.main__item--mortgage {
    padding: 100px 30px;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: baseline;
    justify-content: space-around;
    color: #fff;
    font-family: 'Acrom';
    font-size: 20px;
    line-height: 20px;
    -webkit-transition: all 0.5s;
    transition: all 0.5s;
    text-align: center;

    .main__item--title {
        font-size: 90px;
        line-height: 90px;
        text-align: left;
        font-weight: 300;
        text-transform: uppercase;
        span {
            font-size: 70px;
            text-transform: none;
        }
    }

    .main__item--but {
        -webkit-align-self: flex-end;
        align-self: flex-end;
    }
    .main__item--but.main__item--but__white {
        color: #e2068c;
    }
}


.main__item--ecology {
    padding: 100px 30px;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: baseline;
    justify-content: space-around;
    color: #fff;
    font-family: 'Acrom';
    font-size: 20px;
    line-height: 20px;
    -webkit-transition: all 0.5s;
    transition: all 0.5s;
    text-align: center;

    .main__item--title {
        font-size: 70px;
        line-height: 80px;
        text-align: left;
    }

    .main__item--but {
        -webkit-align-self: flex-end;
        align-self: flex-end;
    }
}


.main__item--life {
    padding: 30px 100px;
    position: absolute;
    height: 100%;
    left: 0;
    right: 46%;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    flex-direction: column;
    -webkit-justify-content: center;
    justify-content: center;
    color: #442463;
    font-family: 'Acrom';
    font-weight: 500;
    font-size: 20px;
    line-height: 20px;
    -webkit-transition: all 0.5s;
    transition: all 0.5s;
    text-align: left;

    .main__item--title {
        font-weight: 300;
        font-size: 50px;
        line-height: 60px;
    }

    .main__item--text {
        margin-top: 70px;
    }

}

.main__item--photo {
    padding: 0;
    position: absolute;
    height: 100%;
    left: 0;
    right: 0;
    color: #442463;
    font-family: 'Acrom';
    font-weight: 500;
    font-size: 20px;
    line-height: 20px;
    transition: all 0.5s;
    text-align: left;

    .main__item--but {
        position: absolute;
        right: 0;
        bottom: 0;
        -webkit-transform-origin: bottom right;
        transform-origin: bottom right;
    }

    .main__item--title {
        position: absolute;
        right: 0;
        bottom: 92px;
        width: 635px;
        text-align: left;
        font-weight: 500;
        color: #e2068c;
        font-size: 50px;
        line-height: 50px;

        p {
            font-weight: 300;
            color: #442463;
            font-size: 40px;
            line-height: 45px;
        }

    }
}

.main__item--photo1 {
    position: absolute;
    /*width: 749px;
    height: 574px;*/
    max-width: 749px;
    max-height: 574px;
    width: 58.3%;
    height: 80.9%;
    background: url('/img/content/photo3.png') no-repeat center bottom;
    background-size: contain;
    left: 25%;
    top: -20.5%;
    z-index: 1;
}

.main__item--photo2 {
    position: absolute;
    /*
    width: 370px;
    height: 450px;
    */
    max-width: 370px;
    max-height: 450px;
    width: 28.8%;
    height: 63.4%;
    background: url('/img/content/photo1.png') no-repeat center bottom;
    background-size: contain;
    left: 19%;
    top: 15%;
    opacity: 1;
    transform: translate3d(-200%, 0, 0) rotate3d(0, 0, 1, -120deg);
}

.main__item--photo3 {
    position: absolute;
    max-width: 563px;
    max-height: 574px;
    width: 43.8%;
    height: 80.9%;
    background: url('/img/content/photo2.png') no-repeat center bottom;
    background-size: contain;
    top: -12%;
    right: -12%;
    z-index: 2;
    opacity: 1;
    -webkit-transform: translate3d(200%, 0, 0) rotate3d(0, 0, 1, 120deg);
    transform: translate3d(200%, 0, 0) rotate3d(0, 0, 1, 120deg);
}


.main__item--video {
    position: absolute;
    width: 100%;
    height: 100%;

    &:before {
        content: '';
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.4);
    }
}

.main__item--video__content {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-transition: all 0.5s;
    transition: all 0.5s;

    .main__item--video__links {
        position: absolute;
        width: 100%;
        left: 0;
        bottom: 100px;
        padding: 0 100px;
        z-index: 1;
        display: flex;
        justify-content: space-between;
        text-transform: uppercase;
        font-size: 20px;
        line-height: 20px;
        text-align: center;

        a {
            color: #fff;
            text-decoration: none;
            transition: all 0.3s;

            &:hover {
                transform: scale(1.2);
            }
        }

        i {
            display: block;
            margin: 0 auto 45px auto;
            height: 55px;
            &:before {
                font-weight: bold;
                font-size: 55px;
            }
        }
    }

    .main__item--title {
        position: absolute;
        z-index: 0;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        text-align: right;
        color: #fff;
        text-transform: uppercase;
        padding: 0 100px;
        font-size: 44px;
        line-height: 44px;
        display: -webkit-flex;
        display: flex;
        -webkit-align-items: center;
        align-items: center;
        -webkit-justify-content: flex-end;
        justify-content: flex-end;
    }


}


.main__item--news {
    position: absolute;
    z-index: 0;
    height: 100%;
    width: 52%;
    left: 0;
    top: 0;
    text-align: left;
    color: #fff;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    flex-direction: column;
    -webkit-justify-content: center;
    justify-content: center;
    background-color: rgba(68, 36, 99, 0.75);
    padding: 0 0 0 10.7%;
    -webkit-transition: all 0.5s;
    transition: all 0.5s;

    .main__item--title {
        font-size: 80px;
        line-height: 80px;
        text-transform: uppercase;
        font-weight: 300;
        margin-bottom: 55px;
    }

}

.main__item--map {
    width: 38%;
    max-width: 485px;
    min-width: 300px;
    position: absolute;
    left: 4.6%;
    top: 20.3%;
    font-family: 'Acrom';
    color: #fff;
    font-size: 35px;
    line-height: 40px;
    font-weight: 300;
    -webkit-transition: all 0.5s;
    transition: all 0.5s;
    z-index: 1;

    .main__item--map__in {
        position: relative;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        background-color: #442463;
        z-index: 1;
        padding: 60px;

        p {
            margin-bottom: 20px;
        }
    }

    .main__item--map__but {
        position: absolute;
        left: 0;
        bottom: 0;
        z-index: 0;
        -webkit-transition: all 0.5s 0.5s;
        transition: all 0.5s 0.5s;
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }

    .main__item--but {
        position: relative;
        margin: 0;
        padding: 0 45px;
        max-width: 325px;
        -webkit-transform-origin: top left;
        transform-origin: top left;

        i {
            width: 33px;
            margin-right: 41px;
            &:before {
                font-size: 33px;
            }
        }
        img {
            width: 33px;
            margin-right: 41px;
        }
    }


}
.animated .main__item--map__but {
    transform: translateY(100%);
}

.news__slider {

    padding-bottom: 100px;

    .news__slider--item--text--big {
        font-size: 16px;
        line-height: 1.2;
    }

    .news__slider--item {
        font-size: 14px;
        line-height: 16px;
        color: #fff;
        font-family: 'Acrom';
        padding-right: 41%;

        .news__slider--item--title {
            font-size: 25px;
            line-height: 27px;
            margin-bottom: 15px;
        }

        .news__slider--item--link {
            font-size: 16px;
            line-height: 18px;
            color: #fff;
            display: inline-block;
            margin-top: 15px;
        }
    }
}


.owl-carousel .owl-nav .owl-prev, .owl-carousel .owl-nav .owl-next {
    color: transparent;
    font-size: 0;
    line-height: 0;
    width: 25px;
    height: 47px;
    background: url('/img/i_arrow.svg') no-repeat 0 0;
    background-size: contain;
    display: inline-block;
    margin-top: 60px;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;

    &:hover {
        -webkit-transform: scale(1.2);
        transform: scale(1.2);
    }
}

.owl-carousel .owl-nav .owl-next {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
    margin-left: 55px;

    &:hover {
        -webkit-transform: rotate(180deg) scale(1.2);
        transform: rotate(180deg) scale(1.2);
    }

}

.slick-track {
    outline: none;

    * {
        outline: none;
    }
}

.slick-initialized .slick-slide {
    padding-left: 1px;
}
.modal-gallery .slick-initialized .slick-slide {
    padding-left: 0;
}

.main__item--news .slick-slider{
    .slick-arrow {
        outline: none;
        border: none;
        border-collapse: collapse;
        color: transparent;
        font-size: 0;
        line-height: 0;
        width: 25px;
        height: 47px;
        background: url('/img/i_arrow.svg') no-repeat 0 0;
        background-size: contain;
        display: inline-block;
        -webkit-transition: all 0.3s;
        transition: all 0.3s;
        cursor: pointer;
        position: absolute;
        bottom: -107px;
        left: 0;

        &:hover {
            -webkit-transform: scale(1.2);
            transform: scale(1.2);
        }

        &.slick-next {
            -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
            left: 80px;

            &:hover {
                -webkit-transform: rotate(180deg) scale(1.2);
                transform: rotate(180deg) scale(1.2);
            }
        }
    }
}


.map {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 0;
}

.home__item__bg{
    animation-timing-function: linear;
    animation-fill-mode: both;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    // animation-play-state: paused;

}
.animation-ready {
    .home__item__bg {
        transition: 2000ms;
        transition-delay: .8s
    }
    .animated.anim__zoom .home__item__bg {
        transform: scale(1.1);
    }
    .anim__left .home__item__bg {
        transition: 9000ms;
        width: 110%;
    }
    .animated.anim__left .home__item__bg {
        transform: translateX(-8%);
    }
    .anim__right .home__item__bg{
        left: -10%;
        width: 110%;
        animation-duration: 9s;
    }
    .animated.anim__right .home__item__bg {
        transform: translateX(8%);
    }

    .anim__top .home__item__bg {
        animation-duration: 13s;
        height: 120%
    }
    .animated.anim__top .home__item__bg {
        transform: translateY(-15%);
    }

    .main__item--photo2 {
        transition: 1s;
        opacity: 1;
        -webkit-transform: translate3d(-200%, 0, 0) rotate3d(0, 0, 1, -120deg);
        transform: translate3d(-200%, 0, 0) rotate3d(0, 0, 1, -120deg);
    }
    .animated .main__item--photo2{
        opacity: 1;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
    .main__item--photo3 {
        transition: 1s;
        opacity: 1;
        -webkit-transform: translate3d(200%, 0, 0) rotate3d(0, 0, 1, 120deg);
        transform: translate3d(200%, 0, 0) rotate3d(0, 0, 1, 120deg);
    }
    .animated .main__item--photo3 {
        opacity: 1;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}
video {
    object-fit: cover
}

.main__item--but {
    padding: 0 35px;
    overflow: hidden;
    position: relative;
    height: 80px;
    display: block;
    width: 100%;
    max-width: 335px;
    background-color: #442463;
    color: #fff;
    text-decoration: none;
    font-size: 13px;
    line-height: 15px;
    font-weight: bold;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
    text-transform: uppercase;
    margin-top: 50px;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    b {
        font-weight: 800;
    }
    img {
        width: 47px;
        position: relative;
        margin-right: 25px;
    }

    i {
        width: 47px;
        position: relative;
        margin-right: 25px;
        &:before {
            font-size: 40px;
        }
    }

    &:hover {
        transform: scale(1.05);
    }

    &.main__item--but__star {
        padding: 0 30px;
        background: none;
        border: solid 3px #e2068c;
        text-align: left;


        i {
            width: 20px;
            margin-right: 19px;
            &:before {
                font-size: 20px;
            }
        }
    }

    &.main__item--but__white {
        background: #fff;
        color: #442463;
        text-align: left;
        max-width: 300px;

        img {
        }
    }

    &.main__item--but__pink {
        background: #e2068c;
        color: #fff;
        text-align: left;
        max-width: 318px;

        i {
            width: 32px;
            &:before {
                font-size: 27px;
            }
        }
    }

    &.main__item--but__pink--tr {
        background: #fff;
        border: solid 3px #e2068c;
        color: #e2068c;
        text-align: center;
        max-width: 250px;
        padding: 0;
        margin: 0;

        p {
            text-align: center;
            width: 100%;
        }
    }

    &.main__item--but__brush {
        max-width: 318px;
        padding: 0 35px 0 60px;

        i {
            width: 30px;
            &:before {
                font-size: 33px;
            }
        }
    }

}

.news__slider {

    padding-bottom: 100px;

    .news__slider--item {
        font-size: 14px;
        line-height: 16px;
        color: #fff;
        font-family: 'Acrom';
        padding-right: 41%;

        .news__slider--item--title {
            font-size: 25px;
            line-height: 27px;
            margin-bottom: 15px;
        }

        .news__slider--item--link {
            font-size: 16px;
            line-height: 18px;
            color: #fff;
            display: inline-block;
            margin-top: 15px;
        }
    }
}


.owl-carousel .owl-nav .owl-prev, .owl-carousel .owl-nav .owl-next {
    color: transparent;
    font-size: 0;
    line-height: 0;
    width: 25px;
    height: 47px;
    background: url('/img/i_arrow.svg') no-repeat 0 0;
    background-size: contain;
    display: inline-block;
    margin-top: 60px;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;

    &:hover {
        -webkit-transform: scale(1.2);
        transform: scale(1.2);
    }
}

.owl-carousel .owl-nav .owl-next {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
    margin-left: 55px;

    &:hover {
        -webkit-transform: rotate(180deg) scale(1.2);
        transform: rotate(180deg) scale(1.2);
    }

}

.slick-track {
    outline: none;

    * {
        outline: none;
    }
}

.slick-initialized .slick-slide {
    padding-left: 1px;
}



.map {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 0;
}
.page-counter .content__block{
    padding-top: 260px
}
.content__block {
    // padding: 260px 0 60px 0;
    padding: 190px 0 60px 0;
    z-index: 0;
    opacity: 0;
    font-size: 16px;
    line-height: 18px;

    img {
        max-width: 100%;
    }

    .box {
        max-width: 1500px;
        padding: 0 100px;
    }

    .main__item--but {
        padding: 0 30px;
    }

    &.about {
        .about__in {
            display: -webkit-flex;
            display: flex;
            -webkit-justify-content: space-between;
            justify-content: space-between;
            padding: 60px 0 0 0;

            .main__item--but {
                margin-top: 74px;
            }

            .about__in--text {
                padding: 28px 0 0 13%;
                width: 72%;
            }

        }
    }
}

.content__block--slider {
    background-color: #f4f4f4;
    margin: 80px 0 0 0;
    padding: 75px 0 80px 0;

    .slick-slide {

        padding: 0;
    }

    .slick-list {
        -webkit-transform: none;
        transform: none;
    }

    .box {
        max-width: 1560px;
    }

    .content__block--slider__item {
        width: 100%;
        position: relative;
        overflow: hidden;
        padding: 0 25px;
        cursor: pointer;

        &.swiping {
            pointer-events: none;
        }

        .slider__item--img {
            width: 100%;
            /*position: absolute;*/
            left: 0;
            top: 0;
            max-width: 395px;
            display: block;
            margin: auto;
        }

        .react-fancybox {
            .box {
                img {
                    width: auto;
                    max-width: none;
                }

                /*
                opacity: 0;
                visibility: hidden;
                */
            }
        }
    }

    // .slick-slider .slick-arrow {
    //     width: 39px;
    //     height: 36px;
    //     background: url('/img/right-arrow.svg') no-repeat 0 center;
    //     background-size: 100% auto;
    //     bottom: auto;
    //     right: -40px;
    //     left: auto;
    //     top: 50%;
    //     margin-top: -23px;
    //     webkit-transform: none;
    //     transform: none;

    //     &:hover {
    //         -webkit-transform: scale(1.5);
    //         transform: scale(1.5);
    //     }

    //     &.slick-prev {
    //         left: -40px;
    //         right: auto;
    //         webkit-transform: rotate(180deg);
    //         transform: rotate(180deg);
    //     }

    //     &.slick-prev:hover {
    //         -webkit-transform: rotate(180deg) scale(1.5);
    //         transform: rotate(180deg) scale(1.5);
    //     }
    // }

}

.slick-track {
    &.with_fancybox {
        -webkit-transform: none !important;
        transform: none !important;
    }
}

.slider_fancybox_link {
    .thumbnail {
        display: none;
    }
}

h1 {
    color: #442463;
    font-size: 70px;
    line-height: 70px;
    font-weight: normal;
    margin: 0 0 25px 0;
}

.overflow {
    overflow: hidden;

    header {
        overflow: visible;
    }
}

.content__menu {
    .box {
        background-color: #f4f4f4;
        display: -webkit-flex;
        display: flex;
        -webkit-align-items: center;
        align-items: center;
        -webkit-justify-content: space-around;
        justify-content: space-around;
    }

    a {
        color: #303030;
        font-size: 15px;
        line-height: 18px;
        display: -webkit-flex;
        display: flex;
        -webkit-align-items: center;
        align-items: center;
        -webkit-justify-content: space-between;
        justify-content: space-between;
        text-decoration: none;
        padding-top: 25px;
        padding-bottom: 25px;
        padding-left: 20px;
        padding-right: 20px;
        i {
            margin-right: 10px;
            -webkit-transition: transform 0.5s;
            transition: transform 0.5s;
            display: block;
            width: 20px;
            &:before {
                text-decoration: none;
                font-size: 26px;
            }
            img {
                height: 26px;
                display: none;
            }
        }
        span {
            text-decoration: underline;
        }

        &:hover {
            i {
                -webkit-transform: rotate(180deg);
                transform: rotate(180deg);
            }
            span {
                text-decoration: none;
            }
        }
    }
}
.content__menu a.active{
    color: #fff;
    background: #442463;
    span{
        text-decoration: none;
    }
}
.breadcrumb {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
    -webkit-justify-content: space-between;
    justify-content: space-between;

    .breadcrumb__links {
        color: #919191;
        font-size: 14px;
        line-height: 16px;
        a {
            color: #919191;
        }
    }

}

.modalwin__box {
    background-color: #442463;
    max-width: 100%;
    padding: 60px 95px 60px 95px;
    color: #fff;

    &.modalwin__box--video {
        // display: none;
        padding: 60px 0 0 0;
        width: 854px;
        video {
            margin-bottom: -4px;
        }
    }
}

.modalwin {
    left: auto !important;
    top: auto !important;
    right: auto !important;
    bottom: auto !important;
    position: relative !important;
    margin: auto;
	padding:0;
	background:none;
	box-shadow:none;
    &.modalwin--thanks {
        font-size: 18px;
        line-height: 22px;
        text-align: center;
        .modal__title {
            margin-bottom: 40px;
        }
        .modalwin__box {
            background-color: #e2068c;
        }
    }
    &.modalwin--callback {
        .modalwin__box {
            width: 600px;
            margin-bottom: 80px;
        }
    }
	&.modalwin--installments {
        font-size: 16px;
        line-height: 22px;
        text-align: center;
		width: 700px;
        .modal__title {
            margin-bottom: 40px;
        }
    }
    .modal__error-text {
        position: absolute;
        top: 100%;
        text-align: center;
        width: 100%;
        left: 0;
        color: #e2068c;
        font-size: 14px;
        line-height: 16px;
        font-weight: 500;
        margin-top: 5px;
        opacity: 0;
        -webkit-transition: opacity 0.3s;
        transition: opacity 0.3s;
        &.show {
            opacity: 1;
        }
    }
    .modal__input {
        width: 100%;
        margin-top: 40px;
        position: relative;
        &:first-child {
        }
        &:before {
            content: '!';
            opacity: 0;
            -webkit-transition: opacity 0.3s;
            transition: opacity 0.3s;
            position: absolute;
            left: -17px;
            font-weight: 500;
            top: 0;
            color: #e2068c;
            font-size: 28px;
            line-height: 36px;
        }
        &.error {
            input {
                background-color: rgba(226,6,140, 0.52);
                /*color: rgba(255,255,255, 0.52);*/
                border-color: rgba(255,255,255, 0.52);
            }
            &:before {
                opacity: 1;
            }
        }

        input {
            font-family: 'Acrom';
            font-size: 14px;
            line-height: 16px;
            background: none;
            outline: none;
            border-collapse: collapse;
            border: none;
            color: #fff;
            border-bottom: solid 1px #fff;
            width: 100%;
            padding: 10px;
            -webkit-transition: all 0.3s;
            transition: all 0.3s;
			-webkit-appearance: none;
			
            &::-webkit-input-placeholder { /* Chrome */
                color: #fff;
            }
            &:-ms-input-placeholder { /* IE 10+ */
                color: #fff;
            }
            &::-moz-placeholder { /* Firefox 19+ */
                color: #fff;
                opacity: 1;
            }
            &:-moz-placeholder { /* Firefox 4 - 18 */
                color: #fff;
                opacity: 1;
            }
        }
        &.modal__input--checkbox {
            padding-top: 15px;
            &:before {
                top:5px;
            }
            label {
                font-weight: normal;
                font-size: 14px;
                line-height: 16px;
                padding: 0 0 0 40px;
                position: relative;
                cursor: pointer;
                display: block;
                input {
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 15px;
                    height: 15px;
                    opacity: 0;
                }
                span {
                    position: absolute;
                    display: block;
                    width: 15px;
                    height: 15px;
                    left: 9px;
                    top: 0;
                    background-color: #fff;
                    &:before {
                        position: absolute;
                        left: 2px;
                        bottom: 5px;
                        width: 20px;
                        height: 15px;
                        font-size: 20px;
                        opacity: 0;
                        -webkit-transition: opacity 0.3s;
                        transition: opacity 0.3s;
                    }
                }
                input:checked+span {
                    &:before {
                        opacity: 1;
                    }
                }
            }
        }
        &.modal__input--but {
            position: absolute;
            right: 0;
            bottom: 0;
            width: 250px;
            height: 80px;
            button {
                outline: none;
                cursor: pointer;
                position: absolute;
                display: block;
                width: 100%;
                height: 100%;
                left: 0;
                top: 0;
                border-collapse: collapse;
                border: none;
                background: none;
                background-color: #e2068c;
                color: #fff;
                text-align: center;
                font-family: 'Acrom';
                font-size: 13px;
                line-height: 16px;
                font-weight: bold;
                text-transform: uppercase;
                display: -webkit-flex;
                display: flex;
                -webkit-align-items: center;
                align-items: center;
                padding: 0 10px 0 29px;
                -webkit-transition: all 0.3s;
                transition: all 0.3s;
                -webkit-transform-origin: top right;
                transform-origin: top right;
                &:hover {
                    -webkit-transform: scale(1.1);
                    transform: scale(1.1);
                }
                i {
                    position: relative;
                    display: block;
                    height: 100%;
                    margin-right: 24px;
                    &:before {
                        line-height: 80px;
                        font-size: 32px;
                        font-weight: normal;
                    }
                }
            }
        }
        &.modal__input--select {
            .Dropdown-control {
                background: none;
                background-color: inherit;
                border: none;
                border-radius: 0;
                color: #e2068c;
                padding: 10px;
                border-bottom: solid 1px #fff;
                font-size: 14px;
                line-height: 16px;
                cursor: pointer;
            }
            .Dropdown-arrow {
                border: none;
                content: '';
                width: 25px;
                height: 100%;
                right: 10px;
                top: 0;
                font-family: 'icons' !important;
                speak: none;
                font-style: normal;
                font-weight: normal;
                font-variant: normal;
                text-transform: none;
                line-height: 1;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;
                -webkit-transition: all 0.3s;
                transition: all 0.3s;
                &:before {
                    content: "\e919";
                    color: #e2068c;
                    font-size: 25px;
                    line-height: 37px;
                }
            }
            .is-open .Dropdown-arrow {
                -webkit-transform: rotate(-180deg);
                transform: rotate(-180deg);
            }
            .Dropdown-menu {
                opacity: 0;
                -webkit-transition: all 0.3s 0.5s;
                transition: all 0.3s 0.5s;
            }
            .is-open .Dropdown-menu {
                opacity: 1;
            }
            .Dropdown-menu {
                background-color: #e5e5e5;
                border: none;
                color: #e2068c;
                font-size: 14px;
                line-height: 16px;
                padding: 13px;
            }
            .Dropdown-option {
                color: #e2068c;
                padding: 16px;
            }
        }


    }
}
.request-modal .modal__input--checkbox {
    margin-bottom: 30px;
}
.modal__title {
    text-align: center;
    font-size: 36px;
    line-height: 38px;
    position: relative;

}

.ReactModal__Body--open {
	overflow:hidden;
}
.ReactModal__Overlay {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
    z-index: 10;
    outline: none;
    background-color: rgba(255, 255, 255, 0.87) !important;
    overflow: auto;
    opacity: 0;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    .ReactModal__Content {
        opacity: 0;
        -webkit-transform: scale(0.5);
        transform: scale(0.5);
    }
    &.ReactModal__Overlay--after-open {
        opacity: 1;
        .ReactModal__Content {
            -webkit-animation-duration: 0.3s;
            animation-duration: 0.3s;
            -webkit-animation-name: anim_zoom_modal;
            animation-name: anim_zoom_modal;
            -webkit-animation-fill-mode: both;
            animation-fill-mode: both;
        }
    }
    &.ReactModal__Overlay--before-close {
        opacity: 0;
        .ReactModal__Content {
            -webkit-animation-duration: 0.3s;
            animation-duration: 0.3s;
            -webkit-animation-name: anim_zoom_modal_close;
            animation-name: anim_zoom_modal_close;
            -webkit-animation-fill-mode: both;
            animation-fill-mode: both;
        }
    }
    * {
        outline: none;
    }

    .ReactModal__Content {
        left: auto !important;
        top: auto !important;
        right: auto !important;
        bottom: auto !important;
        position: relative !important;
        margin: auto;
    }

}
.overlay__modal{
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100vw;
    height: 100vh;
    transform: translate(-50%, -50%);
    cursor: pointer;
    z-index: -1;
}
// .ReactModal__Overlay.ReactModal__Overlay--after-open{
//     pointer-events: none;
//     *{pointer-events: initial !important;}
// }
.close__modal {
    position: absolute;
    top: 23px;
    right: 23px;
    width: 36px;
    height: 36px;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    cursor: pointer;
    &:before {
        content: '';
        display: block;
        width: 100%;
        height: 1px;
        background-color: #fff;
        position: absolute;
        left: 0;
        top: 50%;
        -webkit-transform: rotate(-45deg);
        transform: rotate(-45deg);
    }

    &:after {
        content: '';
        display: block;
        width: 100%;
        height: 1px;
        background-color: #fff;
        position: absolute;
        left: 0;
        top: 50%;
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg);
    }

    &:hover {
        -webkit-transform: scale(1.3);
        transform: scale(1.3);
    }


}
.about-video .close__modal{
    top: 12px;
    right: 12px;
}
.video__type {
    position: relative;
    display: table;
    margin: auto;

    i {
        position: absolute;
        width: 87px;
        height: 87px;
        top: 50%;
        left: 50%;
        margin-left: -43px;
        margin-top: -43px;
        -webkit-transition: all 0.3s;
        transition: all 0.3s;
        &:before {
            font-size: 87px;
        }
        img {
            width: 100%;
        }
    }

    &:hover {
        i {
            -webkit-transform: scale(1.3);
            transform: scale(1.3);
        }
    }

}

.flat-choose {
    background: url('/img/content/choose_flat.jpg') no-repeat center center;
    background-size: cover;
    position: relative;

    &:before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.63);
        z-index: 0;
    }

    .box {
        padding: 50px 0;
        z-index: 1;
        position: relative;
        min-height: 505px;
        /*
        display: -webkit-flex;
        display: flex;
        -webkit-align-items: center;
        align-items: center;
        -webkit-flex-direction: column;
        flex-direction: column;
        -webkit-justify-content: space-around;
        justify-content: space-around;
        */
    }

    .box__title {
        color: #fff;
        text-align: center;
        font-size: 60px;
        line-height: 60px;
        margin-top: 4%;
        margin-bottom: 7%;
    }

    .flat-choose__links {
        display: -webkit-flex;
        display: flex;
        -webkit-justify-content: space-around;
        justify-content: space-around;
        text-align: center;
        font-size: 16px;
        line-height: 18px;
        width: 100%;

        i {
            height: 79px;
            display: block;
            margin: 0 auto 30px auto;
            transition: 1000ms;
            &:before {
                font-size: 79px;
            }
        }

        a {
            color: #fff;
            text-decoration: none;
            min-width: 33%;
            &:hover {
                i {
                    -webkit-transform: scale(1.2);
                    transform: scale(1.2);
                }
                span{
                    border-bottom-color: transparent;
                }
            }

        }
        span{
            border-bottom: 1px solid #fff;
            transition: 300ms;
        }
    }

}


.advants {
    padding: 90px 0 90px 0;
}
.advants__links {
    display: flex;
    justify-content: space-between;
    text-align: center;
    font-size: 16px;
    line-height: 18px;
    width: 100%;
}

.advants__links[data-len="1"],
.advants__links[data-len="2"]{
    justify-content: center;
}

.advants__item {
    color: #fff;
    background-color: #442463;
    position: relative;
    display: block;
    overflow: hidden;
    text-decoration: none;
    max-width: 415px;
    width: 33%;
	margin: 0 10px;
	cursor:pointer;
    &:hover {
        .advants__item__img span{
            transform: scale(1.1);
        }
    }
}

.advants__links[data-len="1"] .advants__item,
.advants__links[data-len="2"] .advants__item{
    margin-left: 1%;
    margin-right: 1%;
}

.advants__item__img {
    position: relative;
    overflow: hidden;
    display: block;
    padding-top: 46%;
    span {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        transition: 1000ms;
        background-size: cover;
    }
}
.advants__item__title {
    color: #fff;
    text-transform: uppercase;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-weight: bold;
    font-size: 18px;
    line-height: 20px;
    text-decoration: none;
    padding: 18px 30px 18px 40px;

    span {
        text-align: left;

    }

    i {
        width: 90px;
        text-align: left;
        &.icon-i_advants1 {
            &:before {
                font-size: 37px;
            }
        }
        &.icon-i_advants2 {
            &:before {
                font-size: 40px;
            }
        }
        &.icon-i_advants3 {
            &:before {
                font-size: 44px;
            }
        }
        &.icon-i_advants4 {
            &:before {
                font-size: 44px;
            }
        }

    }

}



.btn{
    padding: 20px 50px;
    display: inline-block;
    border:2px solid #fff;
    font-size: 14px;
    color: #fff;
    text-decoration: none;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;

    span {
        border-bottom: 1px solid rgba(255,255,255,.9);
        transition: 600ms;
    }

    &:hover span{
        border-bottom-color: transparent;
    }
}


.additional{
    &:after{
        content: '';
        display: block;
        clear: both;
    }
}
.additional_left, .additional_right{
    width: 49%;
    box-sizing: border-box;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;
    padding-top: 34%;
    position: relative;
}
.additional_left{
    float: left;

    .additional_body{
        background-color: rgba(181,51,130,.71)
    }
}
.additional_right{
    float: right;

    .additional_body{
        background-color: rgba(68,39,99,.77)
    }
}
.additional_body{
    position: absolute;
    top: 0;
    left: 0;
    width: 65%;
    height: 100%;
    box-sizing: border-box;
    padding: 55px 70px 140px 94px;
    color: #fff;
    font-size: 15px;

    .btn{
        position: absolute;
        bottom: 75px;
        left: 94px;
    }
}
.additional__title{
    font-size: 26px;
    font-weight: 500;
    margin-bottom: 30px;
    text-transform: uppercase;
    line-height: 1.2
}


.content {
    max-width: 1500px;
    margin: 0 auto;
    position: relative;
    box-sizing: border-box;
}




.loading2 .page{
    opacity: 0;
}
.loaded .page{
    opacity: 1;
    transition: 600ms;
}
.preloader{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: none;
}
.loading2 .preloader{
    display: block;
}
.loaded .preloader{
    display: none;
}
.ready .preloader .loader {
    position: relative;
    margin: 0 auto;
    .logo {
        background: #fff;
        .logo_in {
            img {
                opacity: 1;

                & + img {
                    opacity: 0;
                }
            }
        }

        .logo_item {
            opacity: 1;
            -webkit-animation-duration: 0.75s;
            animation-duration: 0.75s;
            -webkit-animation-name: logo_item1_anim;
            animation-name: logo_item1_anim;
        }

        .logo_item2 {
            -webkit-animation-delay: 0.37s;
            animation-delay: 0.37s;
        }

        .logo_item3 {
            -webkit-animation-duration: 0.85s;
            animation-duration: 0.85s;
        }

        .logo_item5 {
            -webkit-animation-delay: 0.37s;
            animation-delay: 0.37s;
        }

        .logo_item6 {
            -webkit-animation-delay: 0.25s;
            animation-delay: 0.25s;
        }

        .logo_item7 {
            -webkit-animation-duration: 0.85s;
            animation-duration: 0.85s;
            -webkit-animation-delay: 0.15s;
            animation-delay: 0.15s;
        }

        .logo_item8 {
            -webkit-animation-delay: 0.37s;
            animation-delay: 0.37s;
        }
    }
}




.news__panel{
    border-bottom: 4px solid #f4f4f4;
    display: flex;
    justify-content: space-between;
}
.news__panel a, .news__panel > div{
    display: block;
    padding: 30px 0;
    transition: 400ms;
    text-decoration: none;
    width: 100%;
    text-align: center;
    font-size: 24px;
    color: #442463;
}
.news__panel span{
    border-bottom: 1px solid #442463;
    transition: 400ms;
}
.news__panel a.active, .news__panel > div.active{
    color: #e2068c;
    background: #f4f4f4;
}
.news__panel .active span{
    border-bottom-color: transparent;
}
.news__paginator{
    display: flex;
    justify-content: center;
    margin-top: -20px;
}
.news__paginator a{
    width: 44px;
    height: 44px;
    text-align: center;
    align-items: center;
    justify-content: center;
    display: inline-flex;
    text-decoration: none;
    font-size: 18px;
    transition: 300ms;

    span{
        border-bottom: 1px solid #000;
        transition: 300ms;
        color: #000;
    }

    &.active{
        background-color: #e2068c;
        margin-right: 10px;
        margin-left: 10px;
        span{
            border-bottom-color: transparent;
            color: #fff !important;
        }
    }
}
.news{
    padding-bottom: 120px;

}
.news__body{
    padding-top: 80px;
    font-size: 0;
    line-height: 0;
    margin-left: -8%;
}
.news__item{
    padding-bottom: 120px;
    display: inline-block;
    vertical-align: top;
    width: 42%;
    margin-left: 8%;
    font-size: 15px;
    color: #000;
    line-height: 1.2;
    a{
        text-decoration: none;
        color: #000;
    }
}
.news__item:nth-child(2n) {
    padding-top: 120px;
}
.news__item__image{
    padding-top: 51%;
    width: 100%;
    position: relative;
    display: block;
    overflow: hidden;
    margin-bottom: 30px;
}
.news__item__image span{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: 1000ms;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 50%;
}
.news__item:hover .news__item__image span{
    transform: scale(1.2);
}
.news__item__noimage{
    padding-top: 50px !important;
}
.news__item__noimage .news__item__body > div{ max-width: 100%;  }
.news__date{
    font-weight: 500;
    padding-bottom: 16px;
    display: block;
}
.news__name{
    display: block;
    max-width: 70%;
}
.news__name a{
    color: #e2068c;
    font-size: 24px;
    text-decoration: none;
    span{
        border-bottom: 1px solid #e2068c;
        transition: 300ms;
    }
}
.news__description h2{
    color: #e2068c;
    font-size: 24px;
    text-decoration: none;
    margin: 0 0 20px;
    font-weight: normal;
    // text-decoration: underline;
    span{
        border-bottom: 1px solid #e2068c;
        transition: 300ms;
    }
}
.news__description{
    display: block;
    padding: 30px 0 35px;
}

.news__description a{
  text-decoration: underline;
}

.news__description a:hover{
  text-decoration: none;
}

.news__description p{
    margin: 1em 0
}
.news__btn {
    display: block;
    a{
        display: inline-block;
        text-decoration:none;
        font-size: 18px;
        color: #e2068c;
        padding: 12px 60px;
        border: 2px solid #442463;
        font-weight: 600;
        transition: 300ms;
    }
}
.new .news__item{
    width: 100%;
    padding-top: 0;
    display: flex;
    margin-left: 0;
    align-items: center;
}
.new .news__item__image{
    width: 43%;
    min-width: 43%;
    padding-top: 40%;
    margin-right: 5%;
}
.new .news__description{
    padding-top: 0;
}
.news__item__body{
    width: 100%;
}
.news__item__body > div{
    max-width: 505px;
    margin: 0 auto;
}
.news__arrows{
    padding-top: 60px;
    a{
        color: #e2068c;
        transition: 600ms;
        margin-right: 45px;
        display: inline-block;
        font-size: 30px;
        &:hover{
            color: #442463;
        }
    }
}
.new h1{
    font-size: 48px;
    line-height: 1;
    margin-bottom: 10px;
}
.new .breadcrumb{
    display: block;
    margin-bottom: 45px;
}
.new .breadcrumb__links{
    box-sizing: border-box;
}
.preloader-line{
    position: fixed;
    top: 0px;
    height: 3px;
    width: 100%;
    left: 0px;
    right: 0px;
    z-index: 200;
}
.preloader-line__loader{
    height: 3px;
    background-color: #e2068c;
    position: absolute;
    width: 100%;
    opacity: 0;
    transition: 300ms;
}
.loading3 .preloader-line__loader{
    width: 0;
    animation: line-animation 3s cubic-bezier(0.65, 0.815, 0.735, 0.395);
    opacity: 1;
}

@keyframes line-animation {
    form {
        width: 0
    }
    to {
        width:70%;
    }
}
.page{
    transition: 300ms;
}
.loading3 .page{
    opacity: 0;
}

.infra-map-container{
    margin: 34px auto 75px;
    position: relative;
}
.infra-filter__title{
    position: relative;
    padding: 40px 145px 40px 50px;
    background: #f4f4f4;
    cursor: pointer;
    font-size: 30px;
    font-weight: 300;
    color: #442463;
    z-index: 1
}
.infra-filter__title__arrow{
    position: absolute;
    top: 4px;
    right: 4px;
    bottom: 4px;
    background-color: #fff;
    width: 110px;
}
.infra-filter__title__arrow:before{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 20px;
    color: #e2068c;

}
.cluster-count{
    color: #fff;
    font-size: 18px;
}
.icon-content{
    color: #fff;
    font-family: 'Acrom' !important;
    width: 160px;
    font-size: 14px;
    text-align: left;
}

.main-icon-tooltip{
    position: absolute;
    left: 4px;
    top: -242px;
    width: 289px;
    height: 242px;
    opacity: 1;
    background-position: 0px 0px;
    background-size: 289px 242px;
    background-image: url('/img/about/main-marker.png');
}

.main-icon-close{
    width: 20px;
    height: 20px;
    position: absolute;
    right: 10px;
    top: 10px;
    cursor: pointer;
    z-index: 2;
    transition: 300ms transform;
}

.main-icon-close:hover{
    transform: scale(1.1);
}

.main-icon-close:after,
.main-icon-close:before{
    content:'';
    display: block;
    width: 15px;
    height: 2px;
    position: absolute;
    left: 7px;
    top: 9px;
    background: #fff;
}

.main-icon-close:after{
    transform: rotate(45deg);
}

.main-icon-close:before{
    transform: rotate(-45deg);
}

.main-icon-content{
    font-family: 'Acrom' !important;
    width: 274px;
    font-size: 14px;
    text-align: left;
    margin: 0 auto;
    position: relative;
}
.main-icon-content__title{
    text-align: center;
    padding: 17px 0;
    color: #fff;
    font-size: 18px;
}
.main-icon-content__body{
    background: #fff;
    padding: 0 48px;
    height: 110px;
    display: flex;
    align-items: center;
    justify-content: center;
}
#infra-map{
    height: 860px;
    max-height: 90vh;
}

.infra__balloon{
    font-size: 12px;
    color: #000;
    font-family: 'Acrom' !important;
    position: relative;
    width: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fff;
    border: 3px solid #e2068c;
    height: 120px;
}
.popover__arrow{
    content: '';
    background: url(/img/marker.png);
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: -4px 100%;
    width: 70px;
    height: 50px;
}
.infra__balloon__content{
    position: relative;
    padding: 0 15px;
    box-sizing: border-box;
}
.infra-filter{
    position: absolute;
    top: 55px;
    left: 55px;
    // background: #fff;
    width: 480px;
}
.infra-filter__body{
    padding: 50px 40px 40px 100px;
    transition: 600ms;
    background: #fff;
}
.infra-filter.close{
    pointer-events: none;
}
.infra-filter.close .infra-filter__title{
    pointer-events: initial !important;
}
.infra-filter.close .infra-filter__body{
    transform: translateY(-30px);
    opacity: 0;
}
.infra-filter__item{
    display: block;
    margin-bottom: 10px;
    position: relative;
    cursor: pointer;
    display: flex;
    align-items: center;
}
.infra-filter__item span{
    border-bottom: 1px solid transparent;
    transition: 300ms;
}
.infra-filter__item.active span{
    border-bottom-color: #000;
}
.infra-filter__item:hover:before {
    transform: scale(1.15);
}

.infra-filter__item:before {
    font-family: 'ico' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 29px;
    border: 2px solid #442463;
    color: #442463;
    margin-right: 30px;
    transition: 300ms;
    width: 33px;
    height: 33px;
    box-sizing: border-box;
    text-align: center;
    line-height: 29px;
}
.infra-filter__item.active:before {
    border-color: #e2068c;
    color: #e2068c;

}
.map-ico-1:before {
    content: "\e90b";
}
.map-ico-2:before {
    content: "\e90c";
}
.map-ico-3:before {
    content: "\e90d";
}
.map-ico-4:before {
    content: "\e90e";
}
.map-ico-5:before {
    content: "\e90f";
}
.map-ico-6:before {
    content: "\e910";
}
.map-ico-7:before {
    content: "\e911";
}
.map-ico-8:before {
    content: "\e912";
}
.map-ico-9:before {
    content: "\e913";
}
.map-ico-10:before {
    content: "\e914";
}
.map-ico-11:before {
    content: "\e93e";
    font-size: 21px;
}

.developer__body{
    padding-top: 80px !important;
    padding-bottom: 80px !important;
    display: flex;
    align-items: center;
}
.developer__img{
    position: relative;
    width: 29%;
    min-width: 29%;
    padding-top: 29%;
    background-position: 50% 50%;
    background-size: contain;
    margin-right: 7%;
}
.developer__title{
    font-size: 36px;
    color: #e2068c;
    margin-bottom: 25px;
}
.developer__body__text{
    width: 100%;
    padding-right: 5%;
    box-sizing: border-box;
    font-size: 15px;
}

.developer__body__text p {
    margin: 1.5em 0
}


/*slider*/
.slider__item-bg{
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: cover;
    background-color: #fff;
}

.slider__item-contain .slider__item-bg{
    background-size: cover;
    height: 85%;
}
.modal-gallery .slider__item-bg{
    background-size: cover;
    height: calc(100% - 95px);
}
.modal-progress-gallery .modal__body{
    max-width: 980px;
}
.modal-progress-gallery .modal__body .slider__item-bg{
    background-size: contain;
}
.slider__item > div {
    position: relative;
    overflow: hidden;
    padding-top: 49%;
}
.progress .gallery-slider .slider__item>div{
    padding-top: 53%;
}


.slider__item > div > div{
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    transition: 3000ms;
}

.modal .slider__item,
.gallery-slider .slider__item{
    background-size: 50px;
    /*background-image: url(/img/gallery-loader.svg);*/
    background-position: 50% 50%;
    background-repeat: no-repeat;
}

.modal .slick-slider,
.gallery-slider .slick-slider{
    background-color: #fff;
    background-size: 50px;
    background-image: url(/img/loader.gif);
    background-position: 50% 50%;
    background-repeat: no-repeat;
}


.slider{
    // padding-bottom: 70px;
}
.slick-dots{
    text-align: center;
    list-style: none;
    padding: 20px 0;
    margin: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    font-size: 0;
    line-height: 0;
}
.slick-dots li{
    display: inline-block;
}

.slick-dots li{
    transition: 300ms transform;
    transform: scale(0);
    display: none;
}

.slick-dots li.slick-active{
    transform: scale(1);
    display: inline-block;
}

.slick-dots li.slick-active-l1{
    transform: scale(0.8);
    display: inline-block;
}

.slick-dots li.slick-active-l2{
    transform: scale(0.4);
    display: inline-block;
}

.slider__item-contain{
    background-size: contain !important;
    background-color: #fff;
}

.slick-dots button{
    padding: 0;
    margin: 0;
    border:none;
    background: none;
    cursor: pointer;
    width: 20px;
    height: 20px;
    position: relative;
    text-decoration: none;
    font-size: 0;
    line-height: 0;
}
.slick-dots button:before{
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 8px;
    width: 8px;
    border-radius: 50%;
    background: #d1d2d4;
    transition: 600ms;
}
.slick-dots .slick-active button:before{
    background-color: #e2068c;
}
.slider__counter{
    position: relative;
    .slick-slider{
        padding-bottom: 100px;
        .slick-arrow{
            bottom: 0;
            top: auto;
            left: auto !important;
            transform: none;
            bottom: 0;
            &:before{
                // transform: none;
                font-size: 29px;
                color: #e2068c;
            }
        }
        .slick-arrow.slick-prev{
            right: 325px !important;
        }
        .slick-arrow.slick-next{
            right: 168px !important;
        }
    }
    .slider__iterator{
        position: absolute;
        right: 210px;
        bottom: 25px;
        width: 120px;
        text-align: center;
        color: #442463;
        font-size: 24px;
    }
}


.awards{
    background: #f4f4f4;
}
.awards__item{
    font-size: 15px;
    width: 243px !important;
    margin:0 auto;
    display: block !important;
    text-align: center;
}
.awards__image{
    max-width: 243px;
    margin: 0 auto 24px;
    background-color: #fff;
}
.awards__image__bg{
    width: 100%;
    padding-top: 100%;
    background-position: 50% 50%;
    background-size: contain;
    background-repeat: no-repeat;
}
.awards__title{
    font-size: 36px;
    color: #e2068c;
    padding: 70px 0 50px;
    text-align: center;
    line-height: 1;
}
.awards .slider{
    padding-bottom: 90px;
}


.residental__block{
    background-size: cover;
    background-position: 50% 0;
    background-repeat: no-repeat;
    height: 700px;
    margin-top: 35px;
    margin-bottom: 110px;
    position: relative;
}
.residental__text{
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(68,36,99,.7);
    color: #fff;
    line-height: 1.5;
    font-size: 16px;
}
.residental__title{
    margin-bottom: 20px;
    font-size: 48px;
    margin-bottom: 40px;
    line-height: 1;
}
.residental__text > div{
    max-width: 75%;
}

.residental__icons{
    max-width: 1235px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding-bottom: 20px !important;
}
.residental__icon{
    width: 25%;
    text-align: center;
    margin-bottom: 90px;
    position: relative;
}
.residental__icon a, .residental__icon > span{
    color: #303030;
    position: relative;
    text-decoration: none;
    padding-top: 120px;
    display: block;
    max-width: 210px;
    margin: 0 auto;
}
.residental__icon a:before, .residental__icon > span:before{
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    font-size: 90px;
}
.residental__icon a > span, .residental__icon span > span{
    // border-bottom: 1px solid #303030;
    transition: 300ms;
}
.ico-26:before{
    color: #ea5fb4;
}
.ico-27:before{
    color: #5abc68;
}
.ico-28:before{
    color: #fb7d7d;
}
.ico-29:before{
    color: #83d7cc;
}
.ico-30:before{
    color: #65b3e3;
}
.ico-31:before{
    color: #af69b4;
}
.ico-32:before{
    color: #aba8f3;
}
.ico-33:before, .residental__icon .ico-1:before{
    color: #fe9161;
}

.gallery-slider{
    padding-top: 35px;
    max-width: 1500px;
    margin: 0 auto;
    .slick-dots{
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        button {
            width: 28px;
            height: 28px;
            &:before{
                width: 12px;
                height: 12px;
                background: #f4f4f4
            }
            
        }
    }
}
.gallery-slider .slick-dots li.slick-active button:before{background: #e2068c;}
.slider .slick-arrow{
    position: absolute;
    background: transparent;
    width: 72px;
    height: 72px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 0;
    line-height: 0;
    border:none;
    color: #ccc;
    z-index: 1;
    cursor: pointer;
    transition: 600ms;
}
// .gallery-slider .slick-arrow{
//     background: rgba(255,255,255,.5);

// }
.slider .slick-arrow:before{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 25px;
    transition: 600ms;
}
.slider .slick-arrow:hover:before{
        color: #442463;
}
.slider .slick-prev{
    left: -90px;
    &:before{
        content: "\e909";
    }
    &:hover{
        left: -100px;
    }
}
.slider .slick-next {
    right: -90px;
    &:before{
        content: "\e907";
    }
    &:hover{
        right: -100px;
    }
}
.albums{
    margin-left: -29px;
}
.albums:after{
    content: '';
    clear: both;
    display: block;
}
.album{
    width: calc(33.3% - 29px);
    float: left;
    margin-left: 29px;
    margin-bottom: 84px;
    text-decoration: none;
    cursor: pointer;
}
.album__cover{
    padding-top: 70.8%;
    position: relative;
    overflow: hidden;
    display: block;
    span{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        transition: 1200ms;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: 50% 50%;
        &:before{
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            transition: 1200ms;
        }
    }
    i{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        color: #fff;
        transition: 1200ms;
        opacity: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        font-style: normal;
        font-size: 18px;
        &:before{
            font-size: 55px;
            text-align: center;
            margin-bottom: 28px;
        }
    }
}
.album__name{
    background: #f4f4f4;
    font-size: 18px;
    color: #e2068c;
    display: block;
    text-align: center;
    padding: 30px 10px;
    text-transform: uppercase;
    line-height: 1;
    font-weight: 600;
    transition: 300ms;
    span{
        border-bottom: 1px solid #e2068c;
        transition: 300ms;
    }
}
.album:hover .album__cover span{
    transform: scale(1.1);
    &:before{
        background: rgba(0,0,0,.45)
    }
}
.album:hover .album__cover i{
        opacity: 1
}
.album.active .album__name{
    background: #442463;
}
.album.active .album__name span{
    border-bottom-color: transparent;
    color: #fff;

}
.slider__item{
    position: relative;
}
.slider__item__text{
    position: absolute;
    top: 50%;
    left: 69px;
    transform: translateY(-50%);
    background: #442463;
    color: #fff;
    font-size: 18px;
    padding: 6% 4% 6% 5%;
    max-width: 370px;
    line-height: 1.2
}
.modal-gallery{
    // max-width: 1300px;
}
.modal-gallery-win .close__modal{
        top: -40px;
        right: 0;
        z-index: 1
    }
.modal-gallery-win .close__modal:after, .modal-gallery-win .close__modal:before{
    background: #442463;
}
.modal{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255,255,255,.87);
    z-index: -1;
    display: none;
    opacity: 0;
    transition: 600ms;
    .slider{
        padding-bottom: 0;
        .slider__item > div{
            // padding-top: 55%;
            height: 55vw;
            max-height: 80vh;
            padding: 0;
        }

        .slick-slide{
            height: 55vw;
            max-height: 80vh;
        }
    }
    .close__modal{
        top: -40px;
        right: 0;
        z-index: 1;
        display: none;
    }
    .close__modal:after, .close__modal:before{
        background: #442463;
    }
}
.modal-overlay{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
.modal.active{
    opacity: 1;
    z-index: 999;
    display: block;
    .modal__inner{
        animation-duration: .6s;
        animation-name: FadeIn;
        animation-fill-mode: both;
    }
    .close__modal{
        display: block;
    }
}
.modal__body{
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    max-width: 1300px;
    min-height: 50%;
    transform: translate(-50%, -50%);

}
.modal__inner{
    position: relative;
}
.gallery .slider{
    padding-bottom: 75px;
}
.slider__item__gallery-text{
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 35px 75px;
    background: #442463;
    display: flex;
    justify-content: space-between;
    color: #fff;
    font-size: 24px;
    br{
        display: none;
    }
    span{
        color: #e2068c;
    }
}
.docs{
    padding-bottom: 90px;
}
.docs__group{
    margin-bottom: 45px;
}
.docs__group__name{
    color: #e2068c;
    font-size: 36px;
    text-align: center;
    margin-bottom: 64px;
}
.docs__group__body{
    margin-left: -10%;
    font-size: 0;
}
.docs__group__body:after{
    content: '';
    clear: both;
    display: block;
}
.docs__item{
    width: 40%;
    margin-left: 10%;
    // float: left;
    display: inline-block;
    vertical-align: top;
    margin-bottom: 75px;
    padding-left: 218px;
    position: relative;
}
.docs__item:before{
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    background: #f4f4f4;
    color: #442463;
    width: 185px;
    height: 185px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 80px;
}
.docs__date{
    font-weight: 500;
    padding-bottom: 16px;
    font-size: 16px;
}
.docs__name{
    max-width: 70%;
    color: #e2068c;
    font-size: 24px;
    text-decoration: none;
    span{
        border-bottom: 1px solid #e2068c;
        transition: 300ms;
    }
}
.docs__description{
    padding: 30px 0 35px;
    font-size: 14px;
}
.docs__btn a{
    display: inline-block;
    text-decoration:none;
    font-size: 18px;
    color: #e2068c;
    padding: 12px 60px;
    border: 2px solid #442463;
    font-weight: 600;
    transition: 300ms;
    &:hover {
        color: #442463
    }
}


.paginator{
    display: flex;
    justify-content: center;
    margin-top: -20px;
    clear: both;
}
.paginator > span{
    width: 44px;
    height: 44px;
    text-align: center;
    align-items: center;
    justify-content: center;
    display: inline-flex;
    text-decoration: none;
    font-size: 18px;
    transition: 300ms;
    // margin-left: -20px;
    // margin: 0 2px;
    cursor: pointer;

    & > span{
        border-bottom: 1px solid #000;
        transition: 300ms;
        color: #000;
    }

    &.active{
        background-color: #e2068c;
        // margin-right: 30px;
        // margin-left: 10px;
        & > span{
            border-bottom-color: transparent;
            color: #fff !important;
        }
    }
}

.docs-filter{
    margin-top: 15px;
    margin-bottom: 77px;
    background: #f4f4f4;
    height: 155px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}


.select{
    cursor: pointer;
    margin-top: -8px;
    width: 100%;
}
.select .select__control{
    border:none;
    border-bottom: 1px solid #000;
    border-radius: 0;
    box-shadow: none;
    transition: 300ms;
    background-color: inherit;
    background: none;
}
.select .select__value-container{
    padding-left: 0;
    padding-right: 0;
}
.select .select__single-value{
    color: #e2068c;
    font-size: 24px;
    line-height: 1.1;
}
.select .select__control:hover{
    border-bottom-color: #474c55
}
.select .select__indicator{
    padding: 0;
    svg{
        display: none;
    }
    &:before{
        font-size: 11px;
        color: #e2068c;
    }
}
.select__indicator-separator{
    display: none;
}
.select .select__placeholder{
    color: #e2068c;
    font-size: 24px;
    margin-left: 0;
}
.select .select__clear-indicator{
    display: none;
}


.select-mobile{
    position: relative;
    border-bottom: 1px solid #474c55;
    height: 36px;
}
.select-mobile select{
    padding: 0;
    background: none;
    border:none;
    box-shadow: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    color: #e2068c;
    font-size: 24px;
    margin-left: 0;
    font-family: inherit;
}
.select__indicator--mobile{
    background: #fff;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 30px;
    &:before{
        font-size: 11px;
        color: #e2068c;
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
    }
}
.parking-select{
    background: #e8e8e8;
    padding: 20px 0 15px;
    width: 305px;
    max-width: 305px;
    cursor: pointer;
    .select .select__value-container{
        padding-left: 30px;
    }
    .select__indicators{
        margin-right: 35px;
        padding-top: 3px;
    }
    .select .select__single-value{
        font-size: 30px;
        color: #442463;
    }
    .select .select__control{
        border-bottom: none;
    }
} 

.docs-filter .select{
    width: 23%;
}
.video{
    position: absolute;
    width: 100%;
    height: 100%;   
}
.video-overlay{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
    transition: 1000ms;
    // background-color: rgba(0,0,0,.2);

    &.hidden{
        opacity: 0;
    }
    &:before{
        position: absolute;
        top: 50%;
        left: 50%;
        margin-left: -120px;
        margin-top: -120px;
        color: #fff;
        font-size: 220px;
        width: 220px;
        height: 220px;
        text-align: center;
        line-height: 220px !important;
        transition: 1000ms;
    }
    &:hover:before{
        transform: scale(1.1);
    }
}
.video-overlay__title{
    color: #fff;
    position: absolute;
    right: 45px;
    top: 50%;
    transform: translateY(-50%);
    background: #e2068c;
    font-size: 24px;
    padding: 80px 60px;
    max-width: 400px;
    line-height: 1.2;
}

.progress .albums{
    margin-left: -70px;
}
.progress .album{
    width: calc(50% - 70px);
    margin-left: 70px;
    margin-bottom: 65px;
}
.progress .album__name{
    background: #442463;
    color: #fff;
    text-transform: none;
    font-weight: 400;
    font-size: 18px;
    padding: 18px 10px 25px;
}
.progress .album__cover{
    padding-top: 51%;
}
.album__name__date{
    font-size: 12px;
    color: #8d5fba;
    margin-bottom: 6px;
}
.progress .ico-34{
    text-align: center;
}
.progress .docs-filter{
    margin-bottom: 60px;
}
.progress-title{
    font-size: 42px;
    font-weight: 500;
    color: #e2068c;
    text-align: center;
    margin-bottom: 45px;
    span{
        color: #442463
    }
}
.select__option{
    transition: 300ms;
}
.select__option--is-focused{
    background: none !important;
}
.select__option--is-focused:hover{
    background: #e2068c !important;
    color: #fff !important;
}
.select__option--is-focused.select__option--is-selected, .select__option--is-selected{
    background: #442463 !important;
    color: #fff !important;
}

.purchase__block{
    display: flex;
    justify-content: space-between;
    padding-top: 30px;
    margin-bottom: 100px;
}
.purchase__item{
    width:48%;
    padding-top: 40%;
    position: relative;
    overflow: hidden;
}
.purchase__item__image{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: cover;
    transition: 1000ms;
}
.purchase__item:hover .purchase__item__image{
    transform: scale(1.1);
}
.purchase__item__body{
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 64%;
    background: rgba(80,50,136,.78);
    color: #fff;
    padding: 30px 40px 30px 60px;
    font-size: 15px;
    line-height: 1.3;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
}
.purchase__item__title{
    font-size: 48px;
    margin-bottom: 45px;
    text-transform: uppercase;
	max-width:100%;
}
.purchase__item__text {
	max-width:100%;
}
.purchase__item_payment .purchase__item__body{
    background: rgba(180,40,125,.78);

}
.purchase__item .btn{
    margin-top: 50px;
    // padding-left: 25%;
    // padding-right: 25%;
}

.title{
    text-transform: uppercase;
    font-size: 48px;
    font-weight: 500;
    color: #e2068c;
    text-align: center;
    margin-bottom: 50px;
    line-height: 1;
}
.purchase__promotions{
    margin-left: -32px;
    font-size: 0;
    line-height: 0;
}
.promotions__item{
    margin-left: 32px;
    width: calc(100% / 3 - 32px);
    position: relative;
    display: inline-block;
    vertical-align: top;
    font-size: 14px;
    line-height: 1.3;
    background: #f4f4f4;
    text-decoration: none;
    color: #000;
    margin-bottom: 55px;
}
.promotions__item__image {
    padding-top: 70%;
    position: relative;
    overflow: hidden;
}
.promotions__item__image span{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: cover;
    transition: 1000ms;
}
.promotions__item:hover .promotions__item__image span{
    transform: scale(1.1);
}
.promotions__item__body{
    padding: 25px 40px 30px;
}
.promotions__item__title{
    min-height: 65px;
    padding-bottom: 10px;
    box-sizing: border-box;
    font-size: 18px;
    color: #e2068c;
    text-transform: uppercase;
    font-weight: 600;
    span{
        border-bottom: 1px solid #e2068c;
    }
}
.promotions__btn{
    text-align: center;
    padding-bottom: 40px;
}
.promotions__btn .btn{
    color: #e2068c;
    border-color: #442463;
    padding-left: 70px;
    padding-right: 70px;
    font-weight: 600;
    span{
        border:none;
    }
    &:hover{
        background: #442463;
        color: #fff;
    }
}
.mortgage, .payment {
    .purchase__item__body{
        width: 415px;
    }
    .purchase__item {
        padding-top: 27%;
    }
    .purchase__item{
        width: 100%;
    }
    .purchase__item__title{
        margin-bottom: 20px;
    }
    .purchase__item .btn{
        margin-top: 30px;
    }
    .flat-list__item td{
        width: 20%;
        cursor: default;
        img{
            min-width: 150px;
        }
    }
    .btn{
        padding-left: 50px;
        padding-right: 50px;
    }
    p {
        margin-bottom: 1em;
        &:last-child{
            margin-bottom: 0;
        }
    }
}
.mortgage .flat-list td:first-child{
    height: 192px;
}
.mortgage__block{
	margin-bottom: 14px;
	padding-top: 30px;
}
.mortgage {
	.realty-panel {
		margin-bottom: 38px;
	}
	/*.filter {
		margin-bottom: 20px;
		padding: 57px 123px 85px;
	}
	.filter__field-slider .filter__field__title {
		margin-bottom: 42px;
		font-size: 18px;
	}
	.filter__field {
		width: 23%;
	}*/
}
.flat, .mortgage {
	.filter {
		margin-bottom: 20px;
		padding: 55px 60px 77px;
	}
	.filter__field {
		width: 19%;
	}
	.filter__number {
		font-size:48px;
		color:#e2068c;
		white-space:nowrap;
		line-height: 0.7;
	}
	.filter__field__title {
		text-align:left;
	} 
	.flat-list {
	    margin-bottom: 64px;
		&:last-child {
			margin-bottom:0;
		}
	}
}

.payment__block{
    margin-bottom: 80px;
    padding-top: 30px;
}


.payment-block__title{
    font-size: 48px;
    font-weight: 500;
    color: #e2068c;
    text-align: center;
    margin-bottom: 45px;
    line-height: 1;
}
.payment-block__body{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.payment-block__image{
    padding-top: 43%;
    width: 43%;
    min-width: 43%;
    position: relative;
    display: block;
    overflow: hidden;
    margin-bottom: 30px;
}
.payment-block__image span{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: 1000ms;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 50%;
}
.payment-block__image:hover span{
    transform: scale(1.1);
}
.payment-block__text{
    width: 40%;
    min-width: 40%;
    margin-right: 5%;
    font-size: 15px;
    line-height: 1.2;
    h2{
        font-size: 24px;
        color: #e2068c;
        margin-bottom: 30px;
        font-weight: 400;
    }
}

.promotions .purchase__promotions{
    padding-top: 30px;
    margin-bottom: 40px;
}

.promotion {
    .breadcrumb{
      display: block;
    }
    h1{
        margin-bottom: 10px;
        line-height: 1.1;
        font-size: 48px;
    }
    .payment__block {
        padding-top: 55px;
        margin-bottom: 150px;
        h2{
            margin-top: 20px;
        }
        p{
            margin-bottom: 20px;
            &:last-child{
                margin-bottom: 0;
            }
        }
    }
    .payment-block__image{
        margin-bottom: 0;
    }
    .promotions__btn{
        text-align: left;
        padding-top: 50px;
        padding-bottom: 0;
    }
    .payment-block__body{
      align-items: flex-start;
    }
}

.decoration .breadcrumb{
    padding-bottom: 60px;
}
.decoration__descr{
    padding-bottom: 40px;
    text-align: center;
    max-width: 1200px;
    margin: 0 auto;
    font-size: 16px;
    color: #030303;
    line-height: 1.5;
    span {
        color: #442463;
        font-weight: 600;
    }
    b{
        color: #e2068c;
        font-weight: 600;
    }
}
.styles{
    margin-left: -40px;
    margin-bottom: 60px;
    display: flex;
}
.styles:after{
    clear: both;
    content: '';
    display: block;
}
.styles__item{
    margin-left: 40px;
    width: calc(100% / 3 - 40px);
    display: block;
    float: left;
    text-decoration: none;
    color: #fff;
    font-size: 14px;
    line-height: 1.7;
    margin-bottom: 40px;
    position: relative;
}
.styles__item__image{
    padding-top: 156%;
    width: 100%;
    position: relative;
    display: block;
    overflow: hidden;
}
.styles__item--middle{
    width: calc(100% / 2 - 40px);
    .styles__item__image{
        padding-top: 0;
        position: absolute;
        top: 0;
        height: 100%;
        width: 100%;
    }
    .styles__item__body{
        width: 65%;
        height: 100%;
        position: relative;
        z-index: 1;
        padding: 90px 40px 90px 70px;
    }
    .styles__item__text{
        margin-bottom: 40px;
    }
}
.styles__item:hover .styles__item__title span{
    border-bottom-color: transparent;
 }
.styles__item--large{
    width: calc(100% - 40px);
    .styles__item__image{
        padding-top: 0;
        position: absolute;
        top: 0;
        height: 100%;
        width: 100%;
    }
    .styles__item__body{
        width: 42%;
        height: 100%;
        position: relative;
        z-index: 1;
        padding: 132px 95px;
    }
    .styles__item__title{
        margin-bottom: 50px;
    }
    .styles__item__title span{
        border:none;
    }

}
.styles__item__image span{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: 1800ms;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 50%;
}
.styles__item:hover .styles__item__image span{
    transform: scale(1.1);
}
.styles__item__body{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    padding: 53px 65px 40px 55px;
    box-sizing: border-box;
    min-height: 57%;
    background: rgba(68,36,99,.7);
}
.styles__item.style--2 .styles__item__body{
    background: rgba(182,33,124,.7);
}
.styles__item.style--3 .styles__item__body{
    background: rgba(135,75,140,.7);
}
.styles__item__title{
    font-size: 36px;
    text-transform: uppercase;
    line-height: 1.3;
    margin-bottom: 25px;
    span{
        border-bottom: 2px solid #fff;
        transition: 300ms;
    }
}



.decoration .block{
    display: flex;
    justify-content: space-between;
}
.block__item{
    width: 41%;
    position: relative;
    &:first-child{
        margin-top: 216px;
    }
}
.block__item__image{
    padding-top: 66%;
    width: 100%;
    position: relative;
    display: block;
    overflow: hidden;
}
.block__item__image span{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: 1800ms;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 50%;
}
.block__item:hover .block__item__image span{
    transform: scale(1.1);
}
.block__item__text{
    padding: 45px 85px;
    line-height: 1.5;
    position: relative;
}
.block__item__text i{
    position: absolute;
    top: -168px;
    right: -114px;
    width: 210px;
    height: 210px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #b37db7;
    font-size: 210px;
    line-height: 1;
}
.block__item + .block__item .block__item__text i{
    right: -125px;
    top: -195px;
    color: #adc5e6;
}
.icons__title{
    padding-top: 65px;
    margin-bottom: 75px;
    color: #442463;
    font-size: 48px;
    text-align: center;
    span{
        color: #e2068c;
        text-transform: uppercase;
    }
}
.icons{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-left: -52px;
    margin-bottom: 50px;
}
.icons__item{
    width: 173px;
    text-align: center;
    margin-bottom: 65px;
    margin-left: 52px;
    // margin: 0 10px;
}
.icons__item__icon{
    width: 173px;
    background: #f4f4f4;
    height: 173px;
    color: #442463;
    display: block;
    margin: 0 auto 40px;
    font-size: 173px;
}
.icons__item__text{
    margin: 0 -24px;
    font-size: 15px;
}
.decoration-slider__item{
    position: relative;
    display: flex !important;
    align-items: center;
    justify-content: space-between;
    padding-left: 30px;
    padding-right: 30px;
}
.decoration-slider__item__body{
    // padding-right: 100px;
    line-height: 1.4;
    width: 100%;
    box-sizing: border-box;
    p{
        margin-bottom: 1.2em;
        &:last-child{
            margin-bottom: 0;
        }
    }
    table{
        padding: 0;
        margin: 0;
        border:none;
        // margin-right: -100px;
        min-width: 100%;
        td{
            vertical-align: top;
            width: 50%;
            &:first-child{
                padding-right: 50px;
            }
        }
        tr{
            display: flex;
            justify-content: center;
        }
    }
}
.list, .decoration-slider__item__body, .privacy, .contacts-tab{
    ul{
        padding: 0;
        margin: 0;
        list-style: none; 
        li{
            margin-bottom: 30px;
            padding-left: 30px;
            position: relative;
            color: #030303;
            &:before{
                content: '';
                position: absolute;
                top: 4px;
                left: 0;
                width: 7px;
                height: 7px;
                background: #e2068c;
            }
            b{
                display: block;
                font-weight: 500;
            }
        }
    }
}
.decoration-slider__item__img{
    position: relative;
    display: block;
    overflow: hidden;
    width: 43%;
    min-width: 43%;
    padding-top: 40%;
}
.decoration-slider__item__img span{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: 1800ms;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 50%;
}
.slider__counter{
    margin: 0 -30px 90px;
}
.decoration .title{
    text-transform: none;
}
.colorpink{
    color: #442463;
}
.decoration__3d{
    margin-bottom: 120px;
    text-align: center;
    img{
        max-width: 100%;
    }
}

.privacy {
    line-height: 1.3;
    padding-bottom: 150px;
    .breadcrumb{
        align-items: flex-end;
        padding-bottom: 25px;
    }
    .breadcrumb .breadcrumb__links{
        max-width: 47%
    }
    h1 {
        font-size: 48px;
        max-width: 47%;
        line-height: 1;
        margin-bottom: 0;
    }
    ul{
        margin: 50px 0;
        line-height: initial;
    }
    p {
        margin: 1.5em 0;
    }
    h2{
        text-transform: uppercase;
        font-size: 24px;
        color: #e2068c;
        margin: 80px 0 22px;
        font-weight: 400;
    }
}

.error-page{
    padding-top: 120px;
}
.error-block{
    height: calc(100vh - 120px);
    background-image: url(/img/error.jpg);
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
}
.error-block__inner{
    color: #fff;
    text-align: center;
    font-size: 24px;
    i{
        font-size: 178px;
        line-height: 1;
    }
    span{
        display: block;
        font-size: 86px;
        margin-bottom: 10px;
    }
    a{
        display: block;
        background: #b37db7;
        width: 280px;
        height: 65px;
        line-height: 65px;
        color: #fff;
        text-align: center;
        text-decoration: none;
        margin: 50px auto 0;
        transition: 600ms;
        font-size: 18px;
        &:hover{
            background-color: #442463;
        }
    }
}

.contacts-map{
    height: 727px;
    margin-bottom: 70px;
    margin-top: 30px;
    position: relative;
}
#contacts-map{
    height: 727px;
    position: relative;
}
.contacts-map__block {
    position: absolute;
    top: 44px;
    left: 58px;
    bottom: 113px;
    background: #fff;
    width: 405px;
    padding: 46px 65px 40px;
    box-sizing: border-box;
    font-size: 14px;
    z-index: 1
}
.contacts-map__block__in{ 
    max-height: 100%;
    // overflow: auto; 
}
.contacts-map__block__in > div{
    margin-bottom: 35px;
}
.contacts-map__block h2{
    text-transform: uppercase;
    font-weight: 400;
    color: #e2068c;
    font-size: 24px;
    margin-bottom: 18px;
}
.contacts-map__social{
    display: flex;
    margin-bottom: 0 !important;
}
.contacts-map__soc{
    border-radius: 50%;
    border:2px solid #e2068c;
    height: 40px;
    width: 40px;
    min-height: 40px;
    font-size: 0;
    text-decoration: none;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
    transition: 600ms;
    color: #442463;
    &:before{
        font-size: 16px;
    }
    &+a:before{
        font-size: 20px;
    }
    &:hover{
        color: #fff;
        background-color: #e2068c;
    }
}
.contacts-market-title{
    width: 289px;
    height: 89px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    color: #fff;
    span{
        text-align: left;
    }
}
.realty{overflow: hidden;}
.contacts {
    .realty-panel{
        padding-top: 0;
    }
    .realty-panel__item{
        width: auto;
        white-space: nowrap;
        div{
            padding-left: 100px;
            padding-right: 100px;
        }
        // min-width: 28%;
    }
    .title{
        text-transform: none;
    }
}
.contacts-tab{
    padding-top: 60px;
    padding-bottom: 80px;
    table{
        width: 100%;
        td{
            width: 50%;
            &:first-child{
                padding-right: 50px;
            }
            &:last-child{
                padding-left: 50px;
                padding-right: 90px;
            }
            li:last-child{
                margin-bottom: 0;
            }
        }
    }
    img{
        max-width: 100%;
    }
}
.modalwin.modalwin--callback.contacts-modal .modalwin__box{
    margin-bottom: 0;
    .modal__title{
        line-height: 1;
        text-align: left;
        font-size: 30px;
    }
}
.contacts-inputs{
    display: flex;
    .modal__input{
        margin-top: 50px;
    }
    .modal__input--but{
        width: 50px;
        min-width: 50px;
        margin-top: 40px;
        height: 50px;
        background: #e2068c;
        margin-left: 20px;
        font-size: 26px;
        cursor: pointer;
        transition: 600ms;
        position: relative;
        i{
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            height: 26px;
        }
        &:hover{
            background: #fff;
            color: #e2068c;
        }
    }
}
.contacts-lnk{
    width: 320px;
    height: 71px;
    background: #442463;
    position: absolute;
    bottom: -71px;
    left: 0;
    color: #fff;
    text-transform: uppercase;
    display: flex;
    padding-left: 34px;
    align-items: center;
    cursor: pointer;
    transition: 600ms;
    i{
        font-size: 38px;
        color: #e2068c;
        margin-right: 15px;
        transition: 600ms;
    }
    span{
        border-bottom: 1px solid #fff;
    }
    &:hover{
        background: #e2068c;
        i{color: #fff;}
    }
}
.pano-wrap{
    position: relative;
    padding-top: 50%;
    margin-bottom: 90px;
    margin-top: 30px;
}
.panorama{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border:none;
}


.ico-58{
    cursor: pointer;
    font-size: 25px;
    color: #321349;
}
.mobile-menu{
    position: fixed;
    top: 0;
    right: 0;
    height: 100%;
    transform: translateX(100%);
    width: 310px;
    transition: 600ms;
    background: #442463;
    z-index: 999;
    &.open{
        transform: translateX(0);
    }
}
.menu__out{
    overflow: auto;
    max-height: 100%;
    -webkit-overflow-scrolling: touch;
}
.gallery .gallery-slider .slider__item>div>div{
    background-size: contain;
}

.header-block{
    position: fixed;
    background: #fff;
    top: 120px;
    left: 0;
    z-index: 100;
    font-weight: 500;
    padding: 22px 5.4%;
    display: flex;
}
.main-loading .header-block{
    // display: none;
}
.header-block__right{
    border: 2px solid #442463;
    padding: 0 22px 16px;
    font-size: 25px;
    color: #e2068c;
    position: relative;
    text-align: center;
    margin-left: 75px;
    margin-right: 75px;
    min-width: 265px;
}
.header-counter{
    display: flex;
    align-items: center;
    justify-content: center;
}
.header-block__right span{
    font-size: 20px;
    padding-left: 5px;
    padding-right: 5px;

}
.header-block__right__title{
    left: 50%;
    transform: translateX(-50%);
}
.header-block__left__title{
    margin-bottom: 5px;
}
.header-block__left__title, .header-block__right__title{
    position: relative;
    top: -10px;
    background: #fff;
    padding-left: 12px;
    padding-right: 12px;
    font-size: 15px;
    color: #442463;
}
.header-block__left{
    text-decoration: none;
    display: block;
}
.header-block__left.active{
    cursor: default;
}
.header-block .header-block__left:hover .header-block__left__body{
    transform: scale(1.1);
}
.header-block__left__body{
    text-align: center;
    font-size: 34px;
    color: #442463;
    transition: 600ms;
}
.header-block__left__body span{
    position: relative;
}
.header-block__left__body i{
    content: '';
    position: absolute;
    top: -8px;
    right: -5px;
    height: 23px;
    min-width: 23px;
    box-sizing: border-box;
    background: #e2068c;
    color: #fff;
    border-radius: 50%;
    color: #fff;
    font-size: 12px;
    font-style: normal;
    padding: 5px;
}
.header-block{
    transition: 300ms;
}
.actions-hidden{
    opacity: 0;
    pointer-events: none;
}
.mobile-actions{
    // display: flex;
}
.purchase {
    .purchase__item{
        width: 100%;
        padding-top: 0;
        height: 350px;
    }
    .purchase__item__title{
        font-size: 43px;
        margin-bottom: 25px;
    }
   .purchase__item .btn{
        margin-top: 30px;
    }
    .purchase__item__body{
        font-size: 14px;
    }
}

.progress .gallery-slider{
    padding-left: 200px;
    padding-right: 200px;
}
.fixed-banks-out {
    overflow: auto;
}
.fixed-banks {
    &.fixed {
        .fixed-tr {
            position: fixed;
            top: 0;
            left: 0;
            z-index: 99;
			
			th, td {
				width:20%;
			}
        }
    }
}
@supports (-ms-accelerator:true) {
	.fixed-banks.fixed .fixed-tr {
		display:table;
		table-layout:fixed;
	}
}
@media all and (-ms-high-contrast:none) {
	.fixed-banks.fixed .fixed-tr {
		display:table;
		table-layout:fixed;
	}
}
.cursor-pointer{
    cursor: pointer;
}


.benefits {
	text-align:center;
	padding: 0 20px;
	margin-top: 92px;
	.benefits__item {
		display:inline-block;
		vertical-align:top;
		width:33.33%;
		margin-bottom:67px;
		i {
			display:inline-block;
			font-size:72px;
			margin-bottom: 24px;
			line-height: 80px;
		}

		.clr-pink {
			color: #ea5fb4;
		}
		.clr-green {
			color: #69c276;
		}
		.clr-peach {
			color: #fb7d7d;
			font-size:75px;
		}
		.clr-mint {
			color: #83d7cc;
			font-size:86px;
		}
		.clr-blue {
			color: #65b3e3;
			font-size:86px;
		}
		.clr-violet {
			color: #af69b4;
			font-size:86px;
		}
		.clr-lilac {
			color: #aba8f3;
		}
		.clr-orange {
			color: #fe9161;
			font-size: 69px;
		}
	}

	.benefits__text {
		font-size:16px;
		color:#303030;
		min-height: 76px;
		line-height: 1.2;
	}
}

.routeLink {
	color:#e2068c;
	text-transform:uppercase;
	font-size:13px;
	text-align:center;
	font-weight: bold;
	cursor:pointer;
	i {
		font-size:25px;
		color:#442463;
		vertical-align:middle;
		margin-right: 22px;
	}
	span {
		border-bottom: solid 1px #e968ad;
		transition: border 0.3s;
	}
}

.modalwin--scheme {
	width: 1500px;
	
	.modal__title {
		line-height: 1.2;
		margin-bottom: 64px;
	}
	.modalwin__box {
		padding: 53px 66px 60px;
		text-align:center;
		display: table;
		height: 100vh;
		p {
			font-size: 14px;
			line-height: 1.25;
			text-align:left;
			margin-bottom: 49px;
		}
		
		img {
			left: 50%;
			max-width: 100%;
			max-height: 100%;
			display: block;
			position: absolute;
			transform: translateX(-50%);
		}
	}
	.modalwin__row {
		display: table-row;
	}
	.modalwin__top {
		display: table-cell;	
	}
	.modalwin__bottom {
		display: table-cell;
		height: 100%;
		position: relative;
		& > div {
			position:relative;
			width:100%;
			height:100%;
		}
	}
}
.modalwin .modal__input.modal__input--but button.btn-disabled{
    opacity: .6;
    cursor: default;
    pointer-events: none;
}

.m-filter{
    .filter__field__title{
        font-size: 15px;
        white-space: normal;
        min-height: 54px;
    }
    .filter__field-slider .filter__field__title{
        margin-bottom: 20px;
    }
    @media (max-width: 1000px) {
        .filter__field__title{
            min-height: 0;
        }
    }
}

.ny-title{
    position: absolute;
    top: 5.9vw;
    left: 5.9vw;
    font-family: 'Acrom';
    font-weight: 300;
    font-size: 100px;
    line-height:.9;
    text-transform: uppercase;
    color: #fff;
    @media (min-width: 1800px) {
        font-size: 111px;
    }
    @media (max-width: 1600px) {
        font-size: 80px;
        top: 4vw;
        left: 4vw;
    }
    @media (max-width: 1250px) {
        font-size: 60px;
    }
    @media (max-width: 1000px) {
        font-size: 52px;
    }
    @media (max-width: 647px) {
        width: 260px;
        left: 50%;
        transform: translateX(-50%);
    }
}
.ny-counter{
    font-weight: 500;
    font-size: 51px;
    padding-top: 10px;
    @media (min-width: 1800px) {
        font-size: 58px;
    }
    @media (max-width: 1600px) {
        font-size: 41px;
    }
    @media (max-width: 1250px) {
        font-size: 28px;
    }
    @media (max-width: 1000px) {
        font-size: 28px;
        text-transform: uppercase;
        font-weight: 400;
    }
}

.main__item--ny{
    padding: 5.9vw;
    justify-content: space-between;
    .main__item--text{
        margin-top: auto;
        text-align: left;
        line-height: 1.1;
        position: relative;
        bottom: -5px;
        @media (min-width: 1800px) {
            font-size: 26px;
        }
        @media (max-width: 1600px) {
            font-size: 18px;
        }
        @media (max-width: 1250px) {
            font-size: 16px;
        }
        @media (max-width: 1000px) {
            font-weight: 500;
        }
        @media (max-width: 647px) {
            width: 260px;
            margin: auto auto 0;
        }
    }
    .main__item--but{
        margin-top: auto;
        @media (max-width: 647px) {
            margin-top: 20px;
        }
    }
    @media (max-width: 1600px) {
        padding: 4vw;
    }
    @media (max-width: 647px) {
        padding-top: 170px;
    }
}
.home-mobile .main__item--mortgage{
        padding: 170px 4vw 40px;
}

.ny-title2{
    font-family: 'Acrom';
    font-weight: 300;
    font-size: 40px;
    line-height:.9;
    text-transform: uppercase;
    color: #fff;
    font-weight: 300;
    span{
        font-size: 210px;
        line-height: 155px;
        letter-spacing: -20px;
        @media (max-width: 1700px) {
            font-size: 180px;
            line-height: 125px;
            letter-spacing: -15px;
        }
        @media (max-width: 1440px) {
            font-size: 150px;
            line-height: 85px;
            letter-spacing: -10px;
        }
        @media (max-width: 1280px) {
            font-size: 130px;
            line-height: 75px;
            letter-spacing: -10px;
        }
        @media (max-width: 1200px) {
            font-size: 110px;
            line-height: 65px;
            letter-spacing: -5px;
        }
        @media (max-width: 1000px) {
            font-size: 120px;
            line-height: 90px;
            letter-spacing: -5px;
            font-weight: 300;
        }
    }
    @media (max-width: 1700px) {
        font-size: 35px;
    }
    @media (max-width: 1440px) {
        font-size: 30px;
    }
    @media (max-width: 1280px) {
        font-size: 25px;
    }
    @media (max-width: 1200px) {
        font-size: 20px;
    }
    @media (max-width: 1000px) {
        font-size: 17px;
        font-weight: 500;
    }
}
.main__item--ny .main__item--text2 {
    .ny-counter{
        font-weight: 300 !important;
        font-size: 40px;
        text-transform: uppercase;
        @media (max-width: 1700px) {
            font-size: 35px;
        }
        @media (max-width: 1440px) {
            font-size: 30px;
        }
        @media (max-width: 1280px) {
            font-size: 25px;
        }
        @media (max-width: 1200px) {
            font-size: 20px;
        }
        @media (max-width: 1000px) {
            font-size: 17px;
            font-weight: 500 !important;
        }
    }
    @media (max-width: 1000px) {
        width: 265px
    }
}
.main__item--ny2 .main__item--but{
    @media (max-width: 1000px) {
        margin-top: 20px;
    }
}


.question-btn{
    border: 2px solid #fff;
    padding: 20px;
    display: inline-block;
    cursor: pointer;
    font-weight: 500;
    color: #fff;
    text-transform: uppercase;
    text-align: center;
    margin: 45px 0;
    width: 100%;
    max-width: 200px;
    box-sizing: border-box;
    transition: 600ms;
    font-size: 15px;
    &:hover{
        background-color: #fff;
        color: #442463;
    }

}

.faq {
	min-height:100.1vh;

	&__body {
		margin: 24px auto 104px;
		max-width: 951px;
		padding-left: 5.5%;
	}
	&__item {
		&_active {
			.faq__title {
				color:#442463;
				&:before {
					background:#442463;
				}
				&:after {
					opacity:0;
					background:#442463;
					transform: rotate(90deg);
				}
			}
		}		
	}
	&__title {
		position: relative;
		font-size: 24px;
		line-height: 1.2;
		color: #e2068c;
		padding: 15px 50px 15px 0;
		cursor:pointer;
		transition:color 0.3s;
		&:before {
			content: '';
			position: absolute;
			top: 27px;
			right: 0;
			width: 19px;
			height: 3px;
			background: #e2068c;
			transition:background 0.3s;
		}
		&:after {
			content: '';
			position: absolute;
			top: 19px;
			right: 8px;
			width: 3px;
			height: 19px;
			background: #e2068c;
			transition:background 0.3s, opacity 0.3s, transform 0.3s;
		}
		span {
			border-bottom:solid 1px;
			transition:border 0.3s;
			&:hover {
				border-bottom-color:transparent;
			}
		}
	}
	&__content {
		display:none;
		padding: 19px 60px 61px;
		font-size: 15px;
		p {
			margin-bottom:19px;
			&:last-child {
				margin-bottom:0;
			}
		}
	}
}

.news__wrap {
    position: relative;
}
.btn_subscribe {
    position: absolute;
    right:0;
    top:78px;
    border-width: 3px;
    border-color: #e2068c;
    color: #442463;
    cursor: pointer;
    transition: 600ms;
    padding: 18px 26px;
    font-size: 13px;
    font-weight: bold;

    &:hover {
        background: #e2068c;
        color: #fff;
    }
}


.unsubscribe-page{
    padding-top: 120px;
}
.unsubscribe-block{
    height: calc(100vh - 120px);
    background-image: url(/img/unsubscribe.jpg);
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
}
.unsubscribe-block__inner{
    color: #fff;
    text-align: center;
    font-size: 70px;	
    line-height: 1;
    span{
        display: inline-block;
        position:relative;
		&:before {
			content:'';
			position:absolute;
			left: -15px;
			right: -21px;
			top: 56%;
			height:5px;
			background:#e2068c;
		}
    }
    a{
        display: block;
        background: #e2068c;
        width: 280px;
        height: 65px;
        line-height: 65px;
        color: #fff;
        text-align: center;
        text-decoration: none;
        margin: 94px auto 0;
        transition: 600ms;
        font-size: 18px;
        &:hover{
            background-color: #442463;
        }
    }
}