
@media (max-width: 1580px) {
    .commerce__corpus-left .corpus-scheme{
        margin-left: 15%;
    }
    .commerce__corpus-left .tabs__nav{
        margin-left: 30%;
    }
}
@media (max-width: 1500px) {
	.realty .flat h1{
		font-size: 42px;
	}

	.flat__left{
		float: left;
		width: 60%;
	}
	.flat__right{
		width: 35%;
	}
} //1500px

@media screen and (max-width: 1450px){
	.section-view__cell .section-view__num span.section-view__cell__promotion {
		top: 5px;
		left: 5px;
		width: 6px;
		height: 6px;
	}
}

@media (max-width: 1300px) {
	.parking__body{
		padding-left: 70px;
		padding-right: 70px;
	}
	.parking-next, .parking-prev{
		font-size: 16px;
		padding-top: 40px;
	}
	.parking-next{
		right: -20px;
	}
	.parking-prev{
		left: -20px;
	}
	.layout__item{
		margin-left: 20px;
		width: calc(100% / 3 - 20px);
		padding: 8px;
	}
	.layout__flats{
		font-size: 14px;
	}
	.layout__flats div{
		font-size: 38px;
	}
	.filter-layout .filter__field{
		width: 22%;
	}
	.filter-layout .filter__field:last-child{
		width: 15%;
	}
	.layout__descr{
		font-size: 13px;
	}
	.realty .layout-similar.box{
		padding-left: 0;
		padding-right: 0;
	}
	.filter__radio__box{
		padding-left: 2%;
		padding-right: 2%;
	}
	.filter__radio__box .check{
		margin: 0 10px;
	}
	.params .btn-wrap{
		padding-bottom: 40px;
	}
	.flats-result-info{
		font-size: 38px;
	}
    .filter{
        padding-left: 25px;
        padding-right: 25px;
    }
    .filter__field{
        width: 14%;
    }
    .filter__field__title,.check{
        font-size: 14px;
    }
    .filter__field-slider{
        width: 18%;
    }
	/*.mortgage .filter {
		padding-left: 40px;
        padding-right: 40px;
	}
	.mortgage .filter__field {
		width: 28%;
	}*/
	.mortgage .filter__number,
	.flat .filter__number {
		font-size: 34px;
	}
	.realty-panel__item a {
		font-size: 18px;
		padding: 25px 0 21px;
	}
	.realty-panel__item a span:before{
		height: 35px;
		font-size: 35px;
	}
	.flat-list td, .flat-list th{
		font-size: 13px;
	}

	.flat__left{
		float: left;
		width: 55%;
	}
	.flat__right{
		width: 40%;
		float: right;
	}
	.flat__price, .table-title{
		font-size: 40px;
	}
	.flat__info__item{
		font-size: 16px;
	}
	.table-title_small{
		font-size: 22px;
	}
	.flat__bottom__plan{
		// width: 150px;
		// height: 180px;
	}
	.compass{
		right: 0;
		width: 100px;
		height: 100px;
	}
	.realty .breadcrumb{
		display: block;
	}
	.realty .box{
		padding-left: 30px;
		padding-right: 30px;
	}
	.realty h1{
		font-size: 60px;
		margin-bottom: 15px;
	}
	.corpus-list__title span, .section-list__title span{
		font-size: 40px;
	}
	.corpus-list__title, .section-list__title{
		font-size: 18px;
		padding-left: 5px;
		padding-right: 10px;
	}
	.section__filter__title{
		font-size: 16px;
	}
	.section__middle{
		width: 42%;
		min-width: 42%;
	}
	.section__left, .section__right{
		width: 25%;
		min-width: 25%;
	}
	.section-view__cell, .section-view__cell__floor{
		margin-right: 8px;
		font-size: 15px;
	}
	.section__body{
		padding-left: 4px;
		padding-top: 8px;
	}
	.section-view__row{
		padding-bottom: 9px;
	}
	.corpus-plan{
	    width: 60px;
	    min-width: 60px;
	    height: 70px;
	}
	.corpus-list__body__item span{
		font-size: 12px;
	}
	.section-list .building-scheme{
		/*height: 130px;*/
		height: 50vmin;
		max-height: 300px;
  }
  
  .flat__links .flat__link{
    width: 35%;
    line-height: 22px;
  }
} //1300px

@media (max-width: 1230px) {
    .flat__bottom{
        padding-right: 60px;
    }
    .flat__bottom__plan{
        margin-right: 5px;
    }
    .flat__bottom__plan{
        width: 200px;
        // height: 180px;
    }
    .compass{
        width: 60px;
        height: 60px;
    }
}
@media (max-width: 1000px) {
    .rltc-filter{
        margin-bottom: 0 !important;
        padding-bottom: 10px !important;
    }
    .rltc-filter2{
        padding-top: 0 !important;
        border-top: none;
    }
	.section-view__cell .section-view__num span.section-view__cell__promotion {
		top: 7px;
		left: 7px;
	}
    .section-view__flat__body .promotion-icon{
        top: 0;
        left: 0;
    }
    .section-view__cell .section-view__num span.section-view__cell__promotion{
        height: 7px;
        width: 7px;
        font-size: 10px;
    }
    .genplan .select-mobile--realty{margin-bottom: 0 !important;}
    .genplan-scheme{
        width:  1000px;
        // width: 885px;
        overflow: hidden;
        height: 617px;
    }
    .genplan-scheme img, .genplan-scheme svg{
        position: absolute;
        top: 20px;
        left: -298px;
        width: 1500px;
        min-width: 1500px;
        height: 597px;
    }
    .realty h1{
        font-size: 28px;
        margin-bottom: 20px;
    }
    .realty-panel{
    	padding-top: 0;
    }
    .select-mobile--realty{
    	margin-bottom: 3px;
    	height: 60px;
    }
    .genplan .select-mobile--realty{
    	margin-bottom: 20px;
    }
    .filter{
    	flex-direction: column;
    	align-items: center;
    	justify-content: center;
    	padding-top: 0;
    	padding-left: 35px;
    	padding-right: 35px;
    }
	.mortgage .filter__field,
	.flat .filter__field{
    	width: 100%;
	}
	.mortgage .filter__field-slider .filter__field__title {
		font-size:14px;
	}
    .filter__field{
    	width: 100%;
    	padding-top: 35px;
    }
    .filter__field-slider{
    	padding-top: 50px;
    }
    .filter__field__title{
    	margin-bottom: 15px;
    }
    .filter__field-slider .filter__field__title,
    .filter-layout .filter__field-slider .filter__field__title{
    	margin-bottom: 18px;
    }
	.mortgage .filter,
	.flat .filter {
		padding: 0 35px 50px;
	}
    .rc-slider-tooltip-inner{
    	font-size: 11px !important;
    }
    .filter-bottom{
    	flex-direction: column;
    	border-top: none;
    	padding: 0 30px 0;
    }
    .params-decoration-field{
    	display: block;
    	width: 100%;
    }
    .check{
		width: 100%;
		min-width: 100%;
		justify-content: flex-start;
		margin-bottom: 18px;
		i{
			order: -1;
			margin-right: 12px;
		}
    }
    .clear-filter{
    	margin-right: auto;
    	padding: 25px 0 50px;
    	&:after{order: -1; margin-left: 0; margin-right: 10px;}
    }
	.realty-commerce .filter {
		padding-bottom: 60px;
	}
	.realty-commerce .clear-filter {
		padding: 60px 0 0;
	}
    .flats-result-info{
    	font-size: 20px;
    	padding: 50px 0 20px;
    }
    .params .additional{
    	padding-top: 0;
    	margin-top: 10px;
    }
    .params .btn-wrap .btn{
    	padding-left: 40px;
    	padding-right: 40px;
    }
    .additional__btn .btn{
    	font-size: 10px;
    }
    .params .row-mobile__body{
    	text-align: center;
    }
    .filter-layout .filter__field{
    	width: 100%;
    }
    .filter__radio__box:before, .filter__radio__box:after{
    	display: none;
    }
    .filter__radio__box{
    	padding: 70px 0 0;
    	flex-direction: column;
    	justify-content: flex-start;
    	width: 100%;
    	min-width: 100%;
    }
    .filter__radio__box .check{
    	flex-direction: row;
		width: 100%;
		min-width: 100%;
		justify-content: flex-start;
		margin: 0 0 18px;
		i{
			order: -1;
			margin-right: 12px;
		}
    }
    .filter__radio__box .filter__field__title{
    	margin-bottom: 0;
    }
    .filter-layout .filter__field:last-child{
    	padding-top: 0;
    	.filter__field__title{
    		display: none;
    	}
    }
    .filter-layout .filter{
    	padding-bottom: 0;
    }
	.flat__present {
		padding:0;
		text-align:center;
		font-size:12px;
		margin:0 0 30px;
	}
	.flat__present i {
		position: relative;
		display: block;
		margin-bottom: 15px;
		font-size: 26px;
	}
	.realty-commerce .routeLink {
		margin-top: 30px;
	}
	.routeLink {
	    font-size: 11px;
		text-align: left;
		padding-left: 40px;
		position: relative;
		display: inline-block;
	}
	.routeLink i {
	    position: absolute;
		left: 0;
		top: 0;
		font-size: 20px;
	}
    .layout__body{
    	margin-left: 0;
    }
    .layout__item{
    	margin-top: 40px;
    	margin-left: 0;
    	width: 100%;
    }
    .realty .flat h1{
    	font-size: 28px;
    	line-height: 1.2
    }
    .flat .content .content{
    	margin-left: 0;
    	margin-right: 0;
    }
    .back{
    	margin: 20px 0 0;
    }
    .back a{
    	font-size: 12px;
    	padding-left: 18px;
    }
    .back a:before{
    	font-size: 14px;
    }
    .flat__left, .flat__right{
    	width: 100%;
    	float: none;
    }
    .realty-commerce .filter{
    	/*padding-bottom: 0;*/
    }
    .parking-panel__item{
    	display: none;
    }
    .parking-panel{
    	padding-top: 0;
    }
    .parking-select{
    	width: 100%;
    	max-width: 100%;
    }
    .parking-select{
    	background: none;
    }
    .section__content{
    	display: flex;
    	flex-direction: column;
    }
    .section__middle{
    	order: -1;
    	width: 100%;
    	margin-bottom: 55px;
    }
    .section__title{
    	position: static;
    	transform: none;
    	left: 0;
    	top: 0;
    	margin-bottom: 20px;
    	text-align: center;
    	font-size: 18px;
    }
    .section__left, .section__right{
    	width: 100%;
    }
    .section-view__cell .section-view__num, .section-view__cell__floor .section-view__num{
    	padding-top: 30px;
    }
    .section-view__row .section-view__cell__floor{
	    left: -35px;
	    width: 30px;
	    height: 30px;
	    min-width: 30px;
	    font-size: 12px;
    }
    .section-view__row{
    	border-left-width: 40px
    }
    .section-view__cell, .section-view__cell__floor{
    	font-size: 12px;
    }
    .section-view__row:first-child .section-view__cell__floor:first-child:before{
    	top: -7px;
    	font-size: 11px;
    }
    .section__content{
    	padding-bottom: 15px;
    }
    .section__body:after{
    	height: 10px;
    	bottom: -10px;
    }
    .section__filter__title, .corpus-list__title, .section-list__title{
    	font-size: 13px;
    	padding-top: 15px;
    	padding-bottom: 15px;
    	line-height: 22px;
    	padding-left: 15px;
    	padding-right: 15px;
    }
    .corpus-list__title__text{
    	display: flex;
    	align-items: center;
    }
    .corpus-list__title span, .section-list__title span{
    	font-size: 22px;
    	margin-right: 10px;
    }
    .section__arrow{
	    height: 33px;
	    position: relative;
	    font-size: 33px;
	    width: 16px
    }
    .corpus-list__body__item15 .corpus-plan{
    	margin:0 auto;
    	min-width: 75px;
    	padding-top: 7px;
    }
    .corpus-list__body__item span{
    	left: 50%
    }
    .section__filter__item{
    	padding-top: 15px;
    	padding-bottom: 15px;
    }
    .section__filter{
    	padding-bottom: 20px;
    	margin-bottom: 25px;
   	}

    .section__filter__title{
    	margin-bottom: 20px;
    }
    .corpus-list{
    	margin-bottom: 0;
    }
    .additional_right{
    	margin-bottom: 0;
    }
    .section-modal-btn{
    	text-align: center;
    	padding-top: 20px;
    }

    .plan-image{
        margin-bottom: 0;
    }
    .flat__left{
        padding-top: 20px;
    }
    .flat__right{
        padding-top: 0;
    }
    .flat__description, .flat__info__item{
        font-size: 13px;
    }
    .flat__bottom{
        height: 75px;
        margin-bottom: 10px;
    }
    .compass{
        height: 50px;
        width: 50px;
        top: 10px;
    }
    .flat__price, .table-title{
        font-size: 32px;
    }
    .flat__price{
        margin-bottom: 20px;
    }
    .flat__info__item{
        margin-bottom: 10px;
        font-size: 12px;
    }
    .flat__right{
        display: flex;
        flex-direction: column;
    }
    .flat__buttons--wrap{
        order: -1;
    }
    .flat__buttons{
        padding-top: 0;
        order: -1;
        margin: 0 -10px 27px;
    }
    .flat__links{
        order: -1;
    }
    .flat__btn{
        padding: 13px 0;
        font-size: 11px;
    }
    .flat__body{
        margin-bottom: 35px;
    }
    .flat__bottom2{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .flat__bottom__plan{
        float: none;
        margin-right: 0;
        margin-bottom: 20px;
        width: 100%;
        max-width: 266px;
    }
    .flat__bottom__plan__bg{
        display: flex;
        align-items: center;
        justify-content: center;
        background-size: 95px;
    }
    .request-flat{
        display: none;
    }
    .request-modal .modalwin__box > div:first-child{
        padding-right: 0;
    }
    .modalwin .modal__input.modal__input--but{
        position: relative;
    }
    .request-modal .close__modal:before, .request-modal .close__modal:after{
        background: #fff;
    }
    .request-modal .close__modal{
        top: 15px;
        right: 15px;
        z-index: 100;
    }
    .request-modal .modal__title{
        line-height: 1.2;
    }
    .modalwin .modal__input{
        margin-top: 30px;
    }
    .modalwin .modal__input.modal__input--checkbox label{
        font-size: 11px;
        line-height: 1.2;
    }
    .modalwin .modal__input.modal__input--but,.modalwin .modal__input.modal__input--but button{
        width: 100%;
        height: 60px;
        justify-content: center;
        padding-left: 0;
        padding-right: 0;
    }
    .modalwin .modal__input.modal__input--but button i:before{
        line-height: 1;
    }
    .table-title_small{
        position: static;
        display: block;
        text-align: center;
        padding-top: 10px;
    }
    .table-title_small span{
        display: inline-block;
        border-bottom: 1px solid #442463 !important;
    }
    .flat .row-mobile__body{
        text-align: center;
    }
    .layout-detail .flats-result-info{
        padding-top: 0;
    }
    .commerce__corpus-left{
        margin-top: 20px;
        width: 100%;
        padding-top: 0;
    }
    
    .commerce__corpus-body{
        display: flex;
        flex-direction: column;
    }
    .realty-commerce .flat__bottom{
        display: flex;
        padding-top: 30px;
        justify-content: center;
        // height: auto;
    }
    .commerce__corpus-left .corpus-scheme{
        margin-left: 0;
        // margin-bottom: 40px;
    }
    .commerce__corpus-left .tabs__nav{
        margin-left: 0;
        top: 0;
        margin-bottom:40px;
    }
    .commerce__corpus-left .corpus-scheme img{
        min-height: 0;
        max-height: 60vh;
    }
    .commerce__corpus-left > div{
        text-align: center;
        width: 100%;
    }
    .commerce__corpus-right{
        width: 100%;
    }
    .commerce__corpus-body{
        flex-direction: column;
    }
    .commerce__corpus-right{
        padding-top: 0;
    }
    .commerce__corpus-image{
        margin-bottom: 40px;
    }
    .commerce__corpus-body{
        margin-bottom: 0;
    }
    .commerce__corpus-name{
        font-size: 32px;
    }
    .commerce__corpus-address{
        margin-bottom: 30px;
    }
    .commerce__left{
        flex-direction: column;
    }
    .commerce__left .plan-image{
        margin-bottom: 40px;
    }
    .commerce-gallery__thumbs{
        width: 100%;
        padding-left: 0;
    }
    .commerce-gallery__thumbs .slider__item > div{
        padding-top: 0;
        height: 120px;
    }
    .commerce-gallery__thumbs .slider__item > div > div{
        left: 2px;
        width: calc(100% - 4px)
    }
    .commerce-gallery__thumbs .slider .slick-prev,  .commerce-gallery__thumbs .slider .slick-next{
        top: 50%;
        width: 30px;
        height: auto;
        transform: translate(0, -50%) !important;
        position: absolute;
    }
    .commerce-gallery__thumbs .slider .slick-prev{
        right: auto !important;
        left: -30px !important;
    }
    .commerce-gallery__thumbs .slider .slick-next{
        left: auto !important;
        right: -30px !important;
    }
    .commerce-gallery__thumbs .slider .slick-next:before{
        transform: rotate(-90deg);
        top: -5px;
        left: 0;
    }
    .commerce-gallery__thumbs .slider .slick-prev:before{
        transform: rotate(-90deg);
        top: -5px;
        left: 0;
    }
    .realty-commerce .flat .flat__bottom{
        height: 0;
    }
    .realty-commerce .flat .compass{
        top: -150px;
    }
    // .flat__bottom__plan__bg, .realty-commerce .flat__bottom__plan__bg.flat__bottom__plan__bg--large{
    //     background-size: auto 80% !important
    // }

    // .flat__bottom__plan__bg--complex{
    //     background-size: 120px !important;
    // }
    .realty-commerce .flat .flat__bottom{
        display: none;
    }
    .slick-slider .slick-arrow.slick-prev:hover{
        left: 0;
    }
    .slick-slider .slick-arrow.slick-prev:hover{
        left: 0;
    }
    .modal{
        top: 80px;
        height: calc(100% - 80px);
    }
    .modal .slider .slider__item > div{
        height: calc(100vh - 80px);
    }
    .modal .close__modal{
        top: 0;
        right: 27px;
    }
    // .modal.active .close__modal{
    //     background-color: #fff;
    // }
    .commerce-gallery__thumbs .slider .slick-next{
        bottom: auto;
        right: 0 
    }
    .commerce-gallery__thumbs .slick-slider .slick-arrow.slick-next:hover,
    .progress .slick-slider .slick-arrow.slick-next:hover{
        right: 0;
    }
    .commerce-gallery__thumbs{
        margin-bottom: 50px;
    }
    .paginator>span{
        height: 40px;
        width: 40px;
        font-size: 12px;
        line-height: 1;
    }
    .parking .advants .box{
        padding-left: 0;
        padding-right: 0;
    }
    .menu__content .mobile-submenu a.active{
        color: #fff !important;
        p{
            border-bottom-color: #fff;
        }
    }
    .slider__item > div > div{
        background-size: contain;
    }
    .flat-list th, .flat-list td{
        padding: 10px 20px;
    }
    .mortgage .flat-list td:first-child{
        height: 100px;
    }
    .flat-list th span{
        display: block;
        padding-left: 10px;
        line-height: 1;
    }
    .soter-header > span:before, .soter-header > span:after{
        z-index: 1
    }
    .table-title_small{
        margin-top: 30px;
    }
    .flat-list__item__icon{
        height: 27px;
        display: inline-block;
    }
    .params .additional{
        margin-top: 40px;
    }
    .layout-detail .plan-image{
        margin-bottom: 40px;
    }
    .slick-slider .slick-arrow.slick-prev:hover {
        left: -25px;
    }
    .modal{
        .slick-slider .slick-arrow.slick-next:hover{
            right: 0;

        }
        .slick-slider .slick-arrow.slick-prev:hover{

            left: 0;
        }
    }
    .developer .content__block{
        padding-bottom: 0;
    }
    .progress .gallery-slider{
        padding-top: 0;
    }
	
	.realty .box {
		height:auto;
	}
	
	.flat .flat-list {
		margin-bottom: 40px;
	}
	
	.flat__links .flat__link {
		width: 50%;
	}
	
} //1000px
@media (max-width: 500px) {

    .commerce-gallery__thumbs .slider__item > div{
        padding-top: 0;
        height: 80px;
    }
}
@media (max-width: 600px) {
    .genplan-scheme img, .genplan-scheme svg{

        left: -176px;
        width: 885px;
        min-width: 885px;
        height: 350px;
    }
    .genplan-scheme{
        width: 600px;
        min-width: 522px;
        height: 370px;
    }
    body .loader{
        left: 15px !important;
        transition: none;
        transform: none;
        transform-origin: left;
        width: 120px !important;
        height: 45px !important;
        top: 17px !important;
        transform: scale(0.9) !important;
        .logo{
            -webkit-transform: translate(-50%, -50%) scale(1);
            transform: translate(-50%, -50%) scale(1);
            .logo_item{
                display: none !important;
            }
        }
        .logo_in img{
            opacity: 1 !important;
        }
        *{
            animation:none !important;
        }
    }
}
@media (max-width: 375px) {
    .content__block{
        font-size: 15px;
    }
    .modal .close__modal{
        right: 15px;
    }
    .flat__link{
        font-size: 12px;
        padding-left: 30px;
        line-height: 1.2;
        white-space: nowrap;
    }
    .flat__link:before{
        font-size: 24px;
    }
    .flat__link.ico-4:before{
        font-size: 19px;
    }
    .residental__block{
        // margin-top: 0;
    }
    .gallery-slider .slider__item>div{
        height: 200px;
    }
    .progress .gallery-slider .slider__item>div{
        padding-top: 57%;
        height: 0;
    }

}
@media (max-width: 370px) {
    .residental__title{
        font-size: 24px;
    }
    .gallery-slider .slider__item>div{
        height: 160px;
    }
    .progress .gallery-slider .slider__item>div{
        padding-top: 57%;
        height: 0;
    }
}