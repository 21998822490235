@charset "UTF-8";
@font-face {
  font-family: 'ico';
  src: url("/fonts/icons.eot?nq75be");
  src: url("/fonts/icons.eot?nq75be#iefix") format("embedded-opentype"), url("/fonts/icons.ttf?nq75be") format("truetype"), url("/fonts/icons.woff?nq75be") format("woff"), url("/fonts/icons.svg?nq75be#icons") format("svg");
  font-weight: normal;
  font-style: normal; }

[class^="ico-"]:before, [class*=" ico-"]:before, .slider .slick-arrow:before, .select__indicator:before, .clear-filter:after {
  font-family: 'ico' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.ico-1:before {
  content: "\e900"; }

.ico-2:before {
  content: "\e901"; }

.ico-3:before {
  content: "\e902"; }

.ico-4:before {
  content: "\e903"; }

.ico-5:before {
  content: "\e904"; }

.ico-6:before {
  content: "\e905"; }

.ico-7:before {
  content: "\e906"; }

.ico-8:before {
  content: "\e907"; }

.ico-9:before {
  content: "\e908"; }

.ico-10:before {
  content: "\e909"; }

.ico-11:before {
  content: "\e90a"; }

.ico-12:before {
  content: "\e90b"; }

.ico-13:before {
  content: "\e90c"; }

.ico-14:before {
  content: "\e90d"; }

.ico-15:before {
  content: "\e90e"; }

.ico-16:before {
  content: "\e90f"; }

.ico-17:before {
  content: "\e910"; }

.ico-18:before {
  content: "\e911"; }

.ico-19:before {
  content: "\e912"; }

.ico-20:before {
  content: "\e913"; }

.ico-21:before {
  content: "\e914"; }

.ico-22:before, .select__control--menu-is-open .select__indicator:before {
  content: "\e915"; }

.ico-23:before, .select__indicator:before {
  content: "\e916"; }

.ico-24:before {
  content: "\e917"; }

.ico-25:before {
  content: "\e918"; }

.ico-26:before {
  content: "\e919"; }

.ico-27:before {
  content: "\e91a"; }

.ico-28:before {
  content: "\e91b"; }

.ico-29:before {
  content: "\e91c"; }

.ico-30:before {
  content: "\e91d"; }

.ico-31:before {
  content: "\e91e"; }

.ico-32:before {
  content: "\e91f"; }

.ico-33:before {
  content: "\e920"; }

.ico-34:before {
  content: "\e921"; }

.ico-35:before {
  content: "\e922"; }

.ico-36:before {
  content: "\e923"; }

.ico-37:before {
  content: "\e924"; }

.ico-38:before {
  content: "\e925"; }

.ico-39:before {
  content: "\e926"; }

.ico-40:before {
  content: "\e927"; }

.ico-41:before {
  content: "\e928"; }

.ico-42:before {
  content: "\e929"; }

.ico-43:before {
  content: "\e92a"; }

.ico-44:before {
  content: "\e92b"; }

.ico-45:before {
  content: "\e92c"; }

.ico-46:before {
  content: "\e92d"; }

.ico-47:before {
  content: "\e92e"; }

.ico-48:before {
  content: "\e92f"; }

.ico-49:before {
  content: "\e930"; }

.ico-50:before {
  content: "\e931"; }

.ico-51:before {
  content: "\e932"; }

.ico-52:before {
  content: "\e933"; }

.ico-53:before {
  content: "\e934"; }

.ico-54:before {
  content: "\e935"; }

.ico-55:before {
  content: "\e936"; }

.ico-56:before {
  content: "\e937"; }

.ico-57:before {
  content: "\e938"; }

.ico-58:before {
  content: "\e939"; }

.ico-59:before {
  content: "\e93a"; }

.ico-60:before {
  content: "\e93b"; }

.ico-61:before {
  content: "\e93c"; }

.ico-62:before {
  content: "\e93d"; }

.ico-63:before {
  content: "\e93e"; }

.ico-64:before {
  content: "\e93f"; }

.genplan-scheme {
  position: relative;
  margin-bottom: 90px; }
  .genplan-scheme svg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: visible !important; }
    .genplan-scheme svg polygon {
      transition: 200ms; }
      .genplan-scheme svg polygon[fill] {
        cursor: pointer; }
    .genplan-scheme svg rect {
      transition: 200ms; }
    .genplan-scheme svg circle {
      transition: 200ms; }
    .genplan-scheme svg .rect-fast {
      transition: 200ms; }
    .genplan-scheme svg text {
      transition: 200ms;
      pointer-events: none;
      transition-delay: .2s; }
    .genplan-scheme svg text.hidden {
      transition-delay: 0; }
    .genplan-scheme svg image {
      transition: 200ms;
      pointer-events: none; }
    .genplan-scheme svg .text-decoration {
      text-decoration: underline;
      pointer-events: initial;
      cursor: pointer; }
    .genplan-scheme svg .clickable {
      pointer-events: initial;
      cursor: pointer; }
    .genplan-scheme svg .pointer-none {
      pointer-events: none; }
    .genplan-scheme svg image.compass {
      opacity: 1 !important; }
  .genplan-scheme img {
    width: 100%;
    height: auto; }
  .genplan-scheme .hidden3 {
    opacity: 0;
    transition-delay: 0s;
    pointer-events: none !important; }
  .genplan-scheme .hidden {
    opacity: 0;
    transition-delay: 0s;
    pointer-events: none !important; }
  .genplan-scheme .hidden2 {
    opacity: 0;
    transition-delay: 0s;
    pointer-events: none !important; }

.genplan-scheme > img {
  opacity: 1 !important; }

.realty-panel {
  font-size: 0;
  line-height: 0;
  border-bottom: 4px solid #f4f4f4;
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
  padding-top: 50px; }

.realty-panel__item {
  display: inline-block;
  width: 33%;
  min-height: 100%;
  display: inline-flex; }

.realty-panel__item a, .realty-panel__item > div {
  display: inline-block;
  width: 100%;
  font-size: 24px;
  line-height: 1;
  color: #442463;
  text-decoration: none;
  padding: 30px 0 26px;
  text-align: center;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center; }
  .realty-panel__item a span, .realty-panel__item > div span {
    border-bottom: 2px solid #442463;
    margin-left: 60px;
    position: relative;
    transition: 600ms;
    display: inline; }
    .realty-panel__item a span:before, .realty-panel__item > div span:before {
      position: absolute;
      top: 50%;
      left: -60px;
      transform: translateY(-50%);
      width: 60px;
      height: 42px;
      font-size: 42px;
      text-align: left; }
  .realty-panel__item a.active, .realty-panel__item > div.active {
    color: #e2068c;
    background-color: #f4f4f4; }
    .realty-panel__item a.active span, .realty-panel__item > div.active span {
      color: #e2068c;
      border-bottom-color: transparent; }
  .realty-panel__item a:hover span, .realty-panel__item > div:hover span {
    border-bottom-color: transparent; }

.realty-panel__item > div {
  box-sizing: border-box;
  padding-left: 20px;
  padding-right: 20px;
  font-size: 22px; }

.realty .breadcrumb span {
  white-space: nowrap; }

.realty h1 {
  margin-bottom: 0; }

.realty .flat h1 {
  font-size: 48px; }

.flat__body {
  margin-bottom: 100px; }

.flat__body:after {
  content: '';
  display: block;
  clear: both; }

.flat__left {
  float: left;
  width: 65%;
  padding-top: 53px; }

.flat__right {
  width: 28%;
  float: left;
  margin-top: -16px; }

.flat__price {
  font-size: 48px;
  line-height: 1;
  color: #e2068c;
  margin-bottom: 30px; }
  .flat__price span {
    font-size: 24px; }
  .flat__price p {
    line-height: 1; }

.flat__price__title {
  font-size: 18px;
  margin-bottom: 8px; }

.flat__price__top {
  padding-bottom: 25px;
  border-bottom: 1px solid #f4f4f4;
  margin-bottom: 25px;
  color: #442463;
  font-size: 30px; }

.flat__info {
  margin-bottom: 20px; }

.flat__info__item {
  margin-bottom: 30px;
  font-size: 18px; }
  .flat__info__item:after {
    content: '';
    display: block;
    clear: both; }

.flat__info__item--border {
  border-top: 1px solid #cfcfcf;
  padding-top: 18px;
  margin-top: 18px; }

.flat__info__title {
  float: left;
  padding-right: 10px;
  color: #000; }

.flat__info__body {
  overflow: hidden;
  color: #e2068c; }

.flat__info__item--price div {
  color: #000; }

.flat__info__item--promotion div {
  color: #e2068c; }

.flat__info__item--promotion .flat__info__title {
  font-weight: 500; }

.flat__info__body p {
  margin-bottom: 12px; }

.flat__info__body p:last-child {
  margin-bottom: 0; }

.h1--promotion {
  padding-right: 150px;
  position: relative;
  display: inline-block; }

.h1--promotion .promotion-icon {
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translateY(-50%); }

.promotion-icon {
  width: 117px;
  height: 60px;
  background: #65b3e3;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
  line-height: 1;
  color: #fff; }

.promotion-icon:before {
  font-size: 17px;
  height: 17px;
  margin-right: 5px; }

.section-view__flat__body .promotion-icon {
  position: absolute;
  top: -20px;
  left: -54px; }

.section-view__cell .section-view__num span.section-view__cell__promotion {
  /*
	position: absolute;
	top: -6px;
	left: -6px;
	transform: none;
	width: 25px;
	height: 25px;
	display: flex;
	align-items: center;
	justify-content: center;
	line-height: 1;
	font-size: 14px;
	color: #fff;
	background: #65b3e3;

	 */
  position: absolute;
  top: 6px;
  left: 6px;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: #e2068c; }

sup {
  position: relative;
  transform: scale(0.8) translateY(-30%); }

table sup {
  position: relative; }

.plan-image {
  margin-bottom: 80px; }

.plan-image img {
  max-width: 95%;
  margin: 0 auto;
  display: block;
  max-height: 50vh;
  min-height: 200px; }

[data-simplebar] {
  overflow: auto; }

.simplebar-track.simplebar-horizontal {
  height: 25px !important;
  background: #fff;
  border-bottom: 5px solid #f4f4f4; }

.simplebar-track.simplebar-horizontal .simplebar-scrollbar {
  height: 5px !important;
  top: 20px !important;
  opacity: 1 !important;
  background: #b37db7; }

.simplebar-scrollbar:before {
  display: none; }

.flat-list table {
  width: 100%;
  border: 0;
  border-spacing: 0;
  margin-bottom: 25px; }

.flat-list th, .flat-list td {
  text-align: center;
  padding: 24px 20px; }

.flat-list td img {
  min-width: 150px; }

.flat-list td.td-promotion {
  color: #e2068c; }

.flat-list td {
  white-space: nowrap; }

.flat-list th {
  font-size: 14px;
  color: #fff;
  background: #b37db7;
  font-weight: 500;
  padding: 30px 20px; }

.flat-list td {
  font-size: 14px;
  color: #000;
  border-bottom: 2px solid #fff;
  transition: border-color 300ms;
  background: #f4f4f4;
  transition: 300ms;
  cursor: pointer; }

.flat-list td:first-child {
  border-left: 2px solid transparent; }

.flat-list td:last-child {
  border-right: 2px solid transparent; }

.flat-list tr:hover td {
  background: #fafafa;
  transition: background-color 300ms; }

.flat-list__item__icon {
  color: #e2068c;
  font-size: 0;
  transition: 300ms;
  cursor: pointer;
  text-decoration: none; }
  .flat-list__item__icon:before {
    font-size: 27px; }

.flat-list__item__icon:hover {
  color: #442463; }

.flat-list__item_choosen td {
  border-top: solid 2px #b37db7;
  border-bottom: solid 2px #b37db7;
  cursor: default; }

.flat-list__item_choosen td:first-child {
  border-left: solid 2px #b37db7; }

.flat-list__item_choosen td:last-child {
  border-right: solid 2px #b37db7; }

.soter-header {
  position: relative;
  cursor: pointer; }

.soter-header > span {
  position: relative;
  display: inline-block; }

.soter-header > span:before {
  content: '';
  position: absolute;
  top: 50%;
  right: -15px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 3px 4px 3px;
  border-color: transparent transparent rgba(255, 255, 255, 0.8) transparent;
  margin-top: -5px;
  transition: 600ms; }

.soter-header > span:after {
  content: '';
  position: absolute;
  top: 50%;
  right: -15px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 4px 3px 0 3px;
  border-color: rgba(255, 255, 255, 0.8) transparent transparent transparent;
  margin-top: 1px;
  transition: 600ms; }

.soter-header:hover > span:before, .soter-header:hover > span:after {
  opacity: 1; }

.soter-header.sort-asc > span:before {
  border-color: transparent transparent rgba(255, 255, 255, 0.5) transparent; }

.soter-header.sort-desc > span:after {
  border-color: rgba(255, 255, 255, 0.5) transparent transparent transparent; }

.table-title {
  font-size: 48px;
  font-weight: 500;
  color: #e2068c;
  line-height: 1;
  margin-bottom: 34px;
  position: relative;
  text-align: center; }

.table-title_small {
  position: absolute;
  right: 6%;
  bottom: 0;
  font-size: 30px;
  color: #442463;
  cursor: pointer; }

.table-title_small span {
  border-bottom: 1px solid #442463;
  transition: 300ms;
  line-height: 1;
  display: block; }

.table-title_small:hover span {
  border-bottom-color: transparent; }

.back {
  margin: 40px 25px 0;
  position: relative; }

.back a {
  position: relative;
  padding-left: 23px;
  text-decoration: none;
  color: #442463;
  transition: 300ms;
  font-size: 14px; }
  .back a span {
    border-bottom: 1px solid #442463;
    transition: 300ms; }
  .back a:before {
    color: #e2068c;
    font-size: 18px;
    top: 50%;
    position: absolute;
    left: 0;
    transform: translateY(-50%); }
  .back a:hover {
    color: #e2068c; }
    .back a:hover span {
      border-bottom-color: transparent; }

.flat__present {
  position: relative;
  padding-left: 108px;
  font-size: 14px;
  color: #442463;
  font-weight: 600;
  text-transform: uppercase;
  margin: 52px 0 38px; }
  .flat__present i {
    position: absolute;
    left: 0;
    top: 0;
    font-size: 32px;
    color: #e2068c; }

.flat__buttons {
  margin-bottom: 40px;
  display: flex;
  justify-content: space-between;
  padding-top: 25px; }

.flat__links {
  margin-bottom: 50px;
  display: flex;
  justify-content: space-between; }

.flat__description {
  font-size: 14px; }

.flat__description p {
  margin: 1em 0; }

.flat__btn {
  padding: 30px 0;
  text-align: center;
  width: 49%;
  box-sizing: border-box;
  text-transform: uppercase;
  font-size: 13px;
  font-weight: 600;
  border: 3px solid transparent;
  transition: 300ms;
  cursor: pointer; }
  .flat__btn:first-child {
    background: #e2068c;
    color: #fff; }
    .flat__btn:first-child:hover {
      border-color: #e2068c;
      background: #fff;
      color: #442463; }
  .flat__btn:last-child {
    border-color: #442463;
    color: #e2068c; }
    .flat__btn:last-child:hover {
      background: #442463;
      color: #fff; }

.flat__link {
  width: 49%;
  position: relative;
  box-sizing: border-box;
  padding-left: 34px;
  text-decoration: none;
  color: #442463;
  display: block;
  line-height: 1;
  cursor: pointer; }
  .flat__link span {
    border-bottom: 1px solid #442463;
    transition: 300ms; }
    .flat__link span:hover {
      border-bottom-color: transparent; }
  .flat__link:before {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    font-size: 27px;
    color: #e2068c; }
  .flat__link.ico-4:before {
    font-size: 23px; }

.flat__bottom {
  position: relative;
  display: flex;
  padding-right: 180px; }
  .flat__bottom:after {
    content: '';
    display: block;
    clear: both; }

.flat__bottom__plan {
  float: left;
  margin-right: 18px;
  width: 180px;
  height: 208px;
  background: #f4f4f4;
  position: relative; }

.flat__bottom__title {
  height: 40px;
  line-height: 40px;
  text-align: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background: #442463;
  color: #fff;
  font-size: 14px; }

.flat__bottom__plan__bg {
  position: absolute;
  top: 60px;
  left: 20px;
  right: 20px;
  bottom: 20px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: contain !important; }

.compass {
  position: absolute;
  bottom: 0;
  right: 6%;
  background-image: url(/img/realty/compass2.svg);
  background-position: 0 0;
  background-repeat: no-repeat;
  background-size: contain;
  width: 125px;
  height: 125px; }

.ReactModal__Overlay {
  z-index: 999;
  left: 0 !important;
  top: 0 !important;
  right: auto !important;
  bottom: auto !important;
  width: 100vw;
  height: 100vh;
  overflow-x: hidden !important; }

.ReactModal__Overlay .ReactModal__Content {
  max-width: 100%; }

.request-modal .modal__input.modal__input--but,
.mail-modal .modal__input.modal__input--but {
  right: auto;
  left: 0; }
  .request-modal .modal__input.modal__input--but button,
  .mail-modal .modal__input.modal__input--but button {
    transform-origin: top left; }

.overlay__modal {
  width: 90vw;
  height: 50vh; }

.request-modal .modalwin__box {
  display: flex;
  padding-top: 18px;
  padding-bottom: 18px;
  padding-right: 18px;
  width: 1000px !important;
  max-width: 100%; }
  .request-modal .modalwin__box > div:first-child {
    padding-right: 78px; }

.request-modal form {
  padding-top: 12px; }

.request-modal .modal__title {
  padding-top: 35px; }

.request-modal .close__modal {
  top: -48px;
  right: 12px; }

.request-modal .close__modal:before, .request-modal .close__modal:after {
  background: #442463; }

.request-flat {
  background: #fff;
  width: 45.5%;
  min-width: 45.5%;
  text-align: center;
  padding: 42px 55px 30px; }
  .request-flat .flat__info {
    margin-bottom: 35px; }
  .request-flat .flat__info__body {
    text-align: right; }
  .request-flat .flat__info__item {
    margin-bottom: 10px; }
  .request-flat .plan-image {
    margin-bottom: 0; }
    .request-flat .plan-image img {
      min-height: 0;
      max-height: initial;
      max-width: 175px; }

.request-layout .plan-image img {
  max-width: 90%; }

.plan-image .parking-scheme img {
  max-width: 100%; }

.request-flat__title {
  color: #442463;
  font-size: 24px;
  font-weight: 600;
  text-transform: uppercase;
  margin: 0 -45px 30px; }
  .request-flat__title span {
    display: block;
    font-size: 37px;
    color: #e2068c; }

.parking-form .request-flat__title span {
  display: inline-block;
  font-size: inherit; }

.section__content {
  display: flex;
  justify-content: space-between;
  padding-bottom: 88px;
  padding-top: 25px; }

.section__left {
  width: 18.5%;
  min-width: 18.5%; }

.section__right {
  width: 27.5%;
  min-width: 27.5%; }

.section__middle {
  width: 39%;
  min-width: 39%;
  position: relative;
  z-index: 1; }

.section__title {
  position: absolute;
  top: -42px;
  left: 50%;
  transform: translateX(-50%); }
  .section__title span {
    color: #e2068c; }

.section__filter {
  background: #f4f4f4;
  padding: 0 0 34px; }

.section__filter__item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 25px 16px 25px 40px;
  transition: 500ms;
  margin-bottom: 1px;
  cursor: pointer; }
  .section__filter__item span {
    text-decoration: underline;
    color: #e2068c;
    transition: 300ms;
    text-align: left;
    width: 30px;
    min-width: 30px; }
  .section__filter__item:hover {
    background: #fff; }
  .section__filter__item.active {
    background: #e2068c;
    color: #fff; }
    .section__filter__item.active span {
      color: #fff; }

.section__filter__title {
  padding: 22px 30px;
  text-align: center;
  background: #442463;
  font-size: 18px;
  color: #fff;
  margin-bottom: 34px; }

.corpus-list {
  margin-bottom: 35px; }

.corpus-list__title, .section-list__title {
  background: #442463;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 35px 22px;
  font-size: 24px; }
  .corpus-list__title span, .section-list__title span {
    display: block;
    text-align: center;
    font-size: 60px;
    line-height: 1; }

.section__arrow {
  width: 23px;
  height: 43px;
  position: relative;
  font-size: 43px;
  color: #5a3480; }
  .section__arrow a {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    color: #e2068c;
    text-decoration: none;
    transition: 600ms; }
    .section__arrow a:hover {
      transform: scale(1.3); }

.corpus-list__body, .section-list__body {
  background: #f4f4f4;
  padding: 30px;
  text-align: center; }

.corpus-list__body {
  padding: 55px 0;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap; }

.section__left--desktop .corpus-plan,
.section__left--desktop .corpus-plan .building-scheme {
  height: auto !important; }

.corpus-list__body__item {
  max-width: 70% !important;
  height: auto !important;
  text-align: center;
  position: relative;
  cursor: pointer;
  display: none; }
  .corpus-list__body__item.active {
    display: block; }
  .corpus-list__body__item span {
    position: absolute; }
  .corpus-list__body__item.active {
    color: #e2068c; }

.corpus-list__body__item14 span {
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%); }

.corpus-list__body__item15 span {
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%); }

.corpus-list__body__item16 span {
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%); }

.section__body {
  background: #f4f4f4;
  padding-top: 12px;
  padding-left: 8px;
  position: relative; }
  .section__body:hover {
    z-index: 1; }
  .section__body:after {
    content: '';
    background: #f4f4f4;
    width: 116%;
    position: absolute;
    left: 50%;
    bottom: -20px;
    height: 20px;
    transform: translateX(-50%); }

.section-view {
  position: relative; }

.section-view__row {
  display: flex;
  padding-bottom: 13px;
  border-left: 70px solid #f4f4f4;
  position: relative; }
  .section-view__row:last-child {
    padding-bottom: 7px; }
  .section-view__row:first-child .section-view__flat {
    top: 45px; }
  .section-view__row:first-child .section-view__cell__floor:first-child:before {
    content: 'этаж';
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    font-size: 14px;
    font-weight: 500; }
  .section-view__row .section-view__cell__floor {
    position: absolute;
    left: -75px;
    width: 70px;
    height: 70px;
    min-width: 70px; }

.section-view__cell, .section-view__cell__floor {
  margin-right: 13px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  color: #442463;
  font-size: 18px;
  width: 100%;
  font-weight: 500;
  transition: 600ms;
  position: relative; }
  .section-view__cell .section-view__num, .section-view__cell__floor .section-view__num {
    display: block;
    padding-top: 70px;
    width: 100%;
    height: 100%;
    position: relative;
    text-decoration: none;
    color: inherit; }
    .section-view__cell .section-view__num span, .section-view__cell__floor .section-view__num span {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%); }

.section-plan-min {
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); }
  .section-plan-min .section-view {
    display: inline-block; }
  .section-plan-min .section-view__row {
    border-left: 0;
    padding-bottom: 1px; }
  .section-plan-min .section-view__cell {
    height: 8px;
    min-width: 10px;
    border: 1px solid #ccc;
    margin-right: 3px; }
    .section-plan-min .section-view__cell.active {
      background: #e2068c;
      border-color: #e2068c; }
  .section-plan-min .section__body:after {
    display: none; }
  .section-plan-min .section-view__cell .section-view__num {
    display: none; }

.section-view__cell.active.disable, .section-view__cell.disable {
  pointer-events: none;
  background: #fff;
  color: #bcbcbc; }

.section-view__num {
  cursor: default; }

a.section-view__num {
  cursor: pointer; }

.section-view__cell {
  cursor: pointer; }
  .section-view__cell:hover {
    background: rgba(68, 36, 99, 0.05);
    position: relative;
    z-index: 1; }
    .section-view__cell:hover .section-view__flat {
      display: block;
      animation-duration: 0.4s;
      animation-name: fadeFlat;
      animation-fill-mode: both; }
  .section-view__cell.active {
    background: #e2068c;
    color: #fff; }
    .section-view__cell.active:hover {
      background: #c00577; }

@keyframes fadeFlat {
  to {
    -webkit-transform: translateY(calc(-100% - 50px)) scale(1);
    transform: translateY(calc(-100% - 50px)) scale(1);
    opacity: 1; } }

.section-view__cell__floor {
  background: transparent;
  color: #bcbcbc;
  font-weight: 600; }

.section-view__flat {
  position: absolute;
  background: #e2068c;
  color: #fff;
  top: 0;
  left: 25%;
  transform: translateY(calc(-100% - 50px));
  width: 300px;
  padding: 0 9px 9px;
  margin-bottom: 50px;
  font-weight: 400;
  display: none;
  opacity: 0;
  -webkit-transform: translateY(calc(-100% - 50px)) scale(0.5);
  transform: translateY(calc(-100% - 50px)) scale(0.5);
  transform-origin: left bottom; }
  .section-view__flat:before {
    content: '';
    position: absolute;
    bottom: -50px;
    left: 0;
    background: url(/img/realty/arrow1.svg);
    width: 24px;
    height: 50px;
    background-size: contain;
    background-repeat: no-repeat; }

.section-view__flat__title {
  padding: 20px 15px;
  text-align: center; }
  .section-view__flat__title span {
    font-size: 26px; }

.section-view__flat__body {
  background: #fff;
  padding: 35px 24px 20px; }
  .section-view__flat__body .flat__info__item {
    font-size: 14px;
    margin-bottom: 8px; }
    .section-view__flat__body .flat__info__item .flat__info__body {
      text-align: right; }

.corpus-plan {
  width: 80px;
  max-width: 100%;
  max-height: 100%;
  background-size: contain;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  margin: 0 auto; }
  .corpus-plan .building-scheme {
    height: 90px; }

.building-scheme {
  position: relative; }
  .building-scheme img {
    opacity: 0; }
  .building-scheme svg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    animation-duration: .6s;
    animation-name: fade;
    animation-fill-mode: both;
    overflow: visible; }
  .building-scheme polygon {
    stroke: #CECECE; }
    .building-scheme polygon.active {
      fill: #e2068c;
      stroke: #e2068c; }
  .building-scheme text {
    opacity: 0; }

.section-list .building-scheme {
  height: 130px; }

.section-list .building-scheme text {
  opacity: 1; }

.corpus-list__body__item.active .building-scheme polygon {
  fill: #e2068c;
  stroke: #e2068c; }

.section-list .building-scheme polygon.init-hover {
  cursor: pointer; }

.section-list .building-scheme polygon.init-hover:hover {
  fill: #fff; }

.section-list .building-scheme text {
  pointer-events: none; }

.section-list .building-scheme polygon.init-hover.active {
  fill: #e2068c;
  cursor: default; }

.corpus-plan .building-scheme:hover polygon {
  fill: #fff; }

.corpus-plan .building-scheme:hover polygon {
  fill: #fff; }

.corpus-list__body__item.active .corpus-plan .building-scheme polygon {
  fill: #e2068c !important; }

@keyframes fade {
  to {
    opacity: 1; } }

.filter {
  display: flex;
  width: 100%;
  justify-content: space-between;
  background: #f4f4f4;
  box-sizing: border-box;
  padding: 50px 75px 70px; }

.filter__field__title {
  text-align: center;
  font-size: 18px;
  color: #442463;
  margin-bottom: 35px;
  white-space: nowrap; }
  .filter__field__title sup {
    position: absolute; }

th sup, .flat__info__body sup {
  position: absolute; }

.filter__field-slider .filter__field__title {
  margin-bottom: 40px; }

.filter__field {
  width: 16%; }

.checkbox-list {
  display: flex;
  justify-content: center; }

.check-field {
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  color: #442463;
  line-height: 1;
  background: #fff;
  transition: 600ms;
  margin: 0 3px;
  cursor: pointer; }

.check-field.active {
  background-color: #e2068c;
  color: #fff; }

.check-field:hover {
  transform: scale(1.3); }

.params-decoration-field {
  display: flex;
  align-items: center; }

.rc-slider .rc-slider-rail {
  background-color: #fff;
  height: 2px;
  border-radius: 0; }

.rc-slider .rc-slider-rail:before, .rc-slider .rc-slider-rail:after {
  content: '';
  position: absolute;
  height: 2px;
  background-color: #fff;
  width: 8px;
  /*left: -7px;*/ }

.mortgage .rc-slider .rc-slider-rail:before,
.mortgage .rc-slider .rc-slider-rail:after {
  display: none; }

.rc-slider .rc-slider-rail:after {
  left: auto;
  right: -7px; }

.rc-slider .rc-slider-track {
  background-color: #e2068c;
  height: 2px;
  border-radius: 0; }

.rc-slider .rc-slider-handle {
  position: absolute;
  margin-top: -6px;
  width: 14px;
  height: 14px;
  border-radius: 0;
  border: none;
  background-color: #442463;
  transition: transform 300ms; }

.rc-slider .rc-slider-handle:active {
  border-color: transparent;
  box-shadow: none;
  transform: scale(1.2); }

.rc-slider-tooltip-inner {
  background-color: #f4f4f4 !important;
  background: #f4f4f4 !important;
  box-shadow: none !important;
  font-size: 12px !important;
  color: #333333 !important;
  padding: 4px 2px !important; }

.filter-bottom {
  display: flex;
  width: 100%;
  background: #f4f4f4;
  box-sizing: border-box;
  padding: 40px 75px 40px;
  border-top: 3px solid #fff; }

.clear-filter {
  margin-left: auto;
  color: #e2068c;
  display: flex;
  align-items: center;
  cursor: pointer;
  min-width: 16%;
  justify-content: center; }
  .clear-filter span {
    border-bottom: 1px solid; }
  .clear-filter:after {
    content: '\e924';
    color: #442463;
    font-size: 22px;
    margin-left: 10px;
    transition: 300ms; }
  .clear-filter:hover:after {
    transform: scale(1.2); }

.realty-commerce .clear-filter {
  margin-left: 0;
  padding-top: 40px; }

.realty-commerce .params-decoration-field {
  justify-content: center;
  margin: 0 -8px; }
  .realty-commerce .params-decoration-field .check {
    margin-right: 0; }

.check {
  display: flex;
  align-items: center;
  color: #442463;
  font-size: 18px;
  cursor: pointer;
  margin-right: 80px;
  transition: 600ms;
  min-width: 16%;
  justify-content: center; }

.check span {
  margin-right: 10px; }

.check i {
  position: relative;
  display: inline-block;
  width: 23px;
  min-width: 23px;
  height: 23px;
  background: #fff; }

.check:hover {
  color: #e2068c; }

.check i:before {
  content: "";
  position: absolute;
  border: 2px solid;
  transition: all .2s ease;
  z-index: 1;
  width: 10px;
  height: 18px;
  left: 7px;
  bottom: 5px;
  border-top: transparent;
  border-left: transparent;
  transform: rotate(40deg);
  border-radius: 1px;
  border-color: #e2068c;
  opacity: 0; }

.check.active i:before {
  opacity: 1; }

.params .realty-panel {
  margin-bottom: 3px; }

.params .btn-wrap {
  text-align: center;
  padding: 20px 0 30px; }

.params .btn-wrap .btn {
  border-color: #442463;
  color: #e2068c;
  cursor: pointer;
  transition: 600ms;
  padding-left: 80px;
  padding-right: 80px; }
  .params .btn-wrap .btn:hover {
    border-color: #e2068c; }

.flats-result-info {
  padding: 55px 0 40px;
  text-align: center;
  color: #482a66;
  font-size: 48px;
  font-weight: 500; }
  .flats-result-info span {
    color: #e2068c; }

.params .additional {
  margin-top: 60px; }

.filter-layout--1 {
  background: #f4f4f4; }
  .filter-layout--1 .filter {
    padding-bottom: 50px;
    max-width: 1300px;
    margin: 0 auto;
    padding-left: 30px;
    padding-right: 30px; }
  .filter-layout--1 .filter__field {
    width: 35%;
    display: flex;
    align-items: center; }
    .filter-layout--1 .filter__field:last-child {
      width: 20%; }
  .filter-layout--1 .filter__field__title {
    margin-bottom: 0;
    margin-right: 25px; }

.layout__body {
  margin-left: -30px;
  font-size: 0;
  line-height: 0;
  padding-top: 15px;
  padding-bottom: 50px; }

.layout__item {
  margin-top: 75px;
  margin-left: 30px;
  width: calc(100% / 3 - 30px);
  display: inline-block;
  vertical-align: top;
  background: #f4f4f4;
  padding: 20px;
  position: relative;
  cursor: pointer;
  text-decoration: none; }

.layout__descr {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(68, 36, 99, 0.7);
  color: #fff;
  font-size: 14px;
  line-height: 1.2;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: 1000ms; }
  .layout__descr > div {
    text-align: left; }
  .layout__descr > div > div {
    margin: 7px 0; }
  .layout__descr span {
    font-weight: 600; }

.layout__item:hover .layout__descr {
  opacity: 1; }

.layout__plan {
  padding-top: 67%;
  width: 100%;
  background: #fff;
  margin-bottom: 20px;
  position: relative; }
  .layout__plan > span {
    position: absolute;
    top: 15%;
    left: 15%;
    width: 70%;
    height: 70%;
    background-position: 50% 50%;
    background-size: contain;
    background-repeat: no-repeat; }

.layout__flats {
  font-size: 18px;
  line-height: 1;
  color: #e2068c;
  font-weight: 600;
  text-transform: uppercase;
  text-align: center;
  padding-bottom: 15px; }
  .layout__flats div {
    display: block;
    font-size: 48px;
    margin-bottom: 5px; }
  .layout__flats span {
    border-bottom: 1px solid #e2068c;
    display: inline-block;
    margin-bottom: 3px; }

.filter-layout .filter__field {
  width: 20%; }
  .filter-layout .filter__field:last-child {
    width: 10%; }

.filter-layout .filter__field-slider .filter__field__title {
  margin-bottom: 45px; }

.filter-layout .filter__field-slider:last-child .filter__field__title {
  margin-bottom: 28px; }

.filter__radio__box {
  display: flex;
  justify-content: space-between;
  padding-left: 3%;
  padding-right: 3%;
  position: relative; }
  .filter__radio__box:before, .filter__radio__box:after {
    content: '';
    position: absolute;
    top: 0;
    bottom: -20px;
    left: 0;
    width: 1px;
    background: #dcdcdc; }
  .filter__radio__box:after {
    left: auto;
    right: 0; }
  .filter__radio__box .check {
    margin: 0 20px;
    flex-direction: column; }
  .filter__radio__box .filter__field__title {
    margin-bottom: 35px; }

.layout-detail .flat__body {
  margin-bottom: 0; }

.layout-similar .layout__body {
  padding-bottom: 0; }

.layout-similar .layout__item {
  margin-top: 0;
  margin-bottom: 30px; }

.realty-commerce h1 {
  font-size: 60px; }

.realty-commerce .genplan-scheme {
  margin-bottom: 57px; }

.realty-commerce .genplan .advants {
  padding-top: 0; }

.corpus-scheme {
  position: relative;
  display: inline-block;
  z-index: 1; }
  .corpus-scheme img {
    max-width: 100%;
    max-height: 75vh;
    min-height: 450px;
    opacity: 1; }
  .corpus-scheme svg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: visible !important; }
    .corpus-scheme svg .active {
      opacity: 1;
      visibility: visible; }
  .corpus-scheme polygon {
    transition: fill-opacity 300ms;
    cursor: pointer; }
    .corpus-scheme polygon.cursor-default {
      cursor: default; }
  .corpus-scheme image {
    pointer-events: none; }
    .corpus-scheme image.arrow {
      opacity: 0; }
      .corpus-scheme image.arrow.active {
        opacity: 1;
        visibility: visible; }
  .corpus-scheme rect, .corpus-scheme text, .corpus-scheme line {
    transition: opacity 600ms;
    opacity: 0;
    visibility: hidden;
    pointer-events: none; }
  .corpus-scheme .hidden {
    opacity: 0;
    transition-delay: 0s; }
  .corpus-scheme .hidden2 {
    opacity: 0;
    transition-delay: 0s; }

.commerce__corpus-body {
  margin-bottom: 50px; }

.commerce__corpus-body:after {
  content: '';
  display: block;
  clear: both; }

.commerce__corpus-left {
  float: left;
  width: 65%;
  min-width: 65%;
  padding-top: 85px;
  max-width: 700px; }

.commerce__corpus-left > div {
  width: 90%;
  margin: 0 auto;
  text-align: right;
  max-width: 750px;
  position: relative; }

.commerce__corpus-left .corpus-scheme {
  max-height: 650px;
  margin-left: 10%; }

.commerce__corpus-left .corpus-scheme img {
  max-height: 650px; }

.corpus-scheme__11 img {
  min-height: 600px; }

.commerce__corpus-right {
  width: 35%;
  float: left;
  padding-top: 85px; }
  .commerce__corpus-right p {
    margin: 1em 0; }

.commerce__corpus-image {
  position: relative;
  width: 100%;
  padding-top: 68%;
  overflow: hidden;
  margin-bottom: 75px; }
  .commerce__corpus-image span {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 50%;
    transition: 1200ms; }
  .commerce__corpus-image:hover span {
    transform: scale(1.1); }

.commerce__corpus-name {
  color: #e2068c;
  font-size: 48px;
  margin-bottom: 12px;
  line-height: 1; }
  .commerce__corpus-name span {
    color: #442463; }

.commerce__corpus-address {
  color: #442463;
  font-weight: 500;
  font-size: 16px;
  margin-bottom: 60px; }
  .commerce__corpus-address span {
    font-weight: 400; }

.commerce__left {
  display: flex;
  justify-content: space-between;
  padding-right: 10%; }
  .commerce__left .plan-image {
    margin: 0 auto 46px;
    width: 100%; }
  .commerce__left .routeLink {
    margin-top: 42px; }

.commerce-genplan .flat__bottom {
  padding-top: 50px; }
  .commerce-genplan .flat__bottom .compass {
    bottom: 34px; }

.commerce-gallery__thumbs {
  padding-left: 6%;
  width: 120px; }
  .commerce-gallery__thumbs .slider__item > div {
    padding-top: 100%;
    cursor: pointer; }
  .commerce-gallery__thumbs .slider__item > div > div:hover {
    transform: scale(1.3); }
  .commerce-gallery__thumbs .slider .slick-prev {
    top: -60px; }
  .commerce-gallery__thumbs .slider .slick-next {
    top: auto;
    bottom: -60px; }
  .commerce-gallery__thumbs .slick-slider .slick-arrow {
    left: 0 !important;
    color: #e2068c;
    width: 100%;
    transform: none;
    transition: 200ms; }
    .commerce-gallery__thumbs .slick-slider .slick-arrow:hover {
      color: #442463; }
  .commerce-gallery__thumbs .slider .slick-prev:before {
    content: "\e915";
    font-size: 15px; }
  .commerce-gallery__thumbs .slider .slick-next:before {
    content: "\e916";
    font-size: 15px; }

.parking {
  position: relative; }

.modalwin__box .parking-scheme {
  max-width: 295px; }

.parking-scheme {
  position: relative;
  display: inline-block; }
  .parking-scheme img {
    max-width: 100%;
    max-height: 75vh;
    min-height: 450px; }
  .parking-scheme svg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: visible !important; }
    .parking-scheme svg .active {
      opacity: 1;
      visibility: visible; }
  .parking-scheme polygon {
    transition: fill-opacity 300ms;
    cursor: pointer; }
    .parking-scheme polygon.cursor-default {
      cursor: default; }
  .parking-scheme image {
    pointer-events: none; }
    .parking-scheme image.arrow {
      opacity: 0; }
      .parking-scheme image.arrow.active {
        opacity: 1;
        visibility: visible; }
  .parking-scheme rect, .parking-scheme text, .parking-scheme line {
    transition: opacity 600ms;
    opacity: 0;
    visibility: hidden;
    pointer-events: none; }
  .parking-scheme .text-decoration {
    text-decoration: underline; }
  .parking-scheme .event-none {
    pointer-events: none; }

.parking__body {
  padding-top: 100px;
  padding-bottom: 30px;
  position: relative;
  padding-left: 150px;
  padding-right: 150px; }

.parking-next, .parking-prev {
  position: absolute;
  padding-top: 50px;
  top: 50%;
  transform: translateY(-50%);
  text-transform: uppercase;
  color: #e2068c;
  font-size: 26px;
  text-decoration: none;
  transition: 600ms;
  color: #919191; }
  .parking-next:hover, .parking-prev:hover {
    color: #e2068c; }
    .parking-next:hover i.ico-10, .parking-prev:hover i.ico-10 {
      transform: translateX(-100%);
      color: #e2068c; }
    .parking-next:hover i.ico-8, .parking-prev:hover i.ico-8 {
      transform: translateX(0);
      color: #e2068c; }
  .parking-next i, .parking-prev i {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    transition: 600ms;
    color: #919191; }

.parking-prev {
  left: 0; }

.parking-next {
  right: 0; }

.parking-panel {
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding-top: 50px; }

.parking-panel__item {
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 50px;
  color: #442463; }
  .parking-panel__item:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    width: 28px;
    height: 28px;
    border: 2px solid transparent; }
  .parking-panel__item.parking-panel__item--1:before {
    background: #e2068c; }
  .parking-panel__item.parking-panel__item--2:before {
    background: #b37db7; }
  .parking-panel__item.parking-panel__item--3:before {
    background: #fff;
    border-color: #919191; }

.parking-floors-wrap {
  margin: 0 0 40px; }

.parking-floors {
  display: flex;
  flex-wrap: wrap;
  margin-left: -8px; }

.parking-floors > a {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 56px;
  height: 56px;
  background: #e8e8e8;
  text-decoration: none;
  color: #000;
  font-weight: 500;
  font-size: 18px;
  margin-left: 8px; }
  .parking-floors > a span {
    font-size: 10px;
    text-transform: uppercase; }
  .parking-floors > a.active {
    background: #e2068c;
    color: #fff; }

.parking__floor-title {
  text-transform: uppercase;
  margin-bottom: 20px;
  font-size: 14px;
  font-weight: 500; }

.modalwin.modalwin--thanks.layout-thx .modal__title {
  margin-bottom: 0; }

.tabs {
  width: 100%;
  position: relative;
  z-index: 1; }

.tabs__nav {
  text-align: center;
  display: flex;
  justify-content: center;
  top: -100px;
  position: relative; }

.tabs-inner .tabs__nav {
  top: 0;
  margin-bottom: 60px; }

.tabs__nav__item, .tabs__nav__item__active {
  font-size: 20px;
  color: #e2068c;
  border-bottom: 1px solid #e2068c;
  margin-left: 20px;
  cursor: pointer;
  transition: 300ms; }
  .tabs__nav__item:hover, .tabs__nav__item__active:hover {
    border-color: transparent; }

.tabs__nav__item__active {
  color: #442463;
  border-color: transparent;
  cursor: default; }

.commerce__corpus-left .tabs__nav {
  margin-left: 30%; }

.commerce__corpus-left .floor-2 .corpus-scheme {
  margin-bottom: -300px; }

.line-through {
  text-decoration: line-through; }

.rltc-filter {
  margin-bottom: 0 !important;
  padding-bottom: 50px !important; }

.rltc-filter2 {
  padding-top: 40px !important;
  border-top: 2px solid #fff; }

.commerce-hidden {
  opacity: 0 !important; }
