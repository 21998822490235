@media (prefers-reduced-motion) {
    .logo_item, .logo_in {
        -webkit-animation: unset !important;
        animation: unset !important;
        -webkit-transition: none !important;
        transition: none !important;
    }
}

@media screen and (max-width: 1850px) {
    .main__item--photo2 {
        left: 14%;
        top: 13%;
    }
    .main__item--photo1 {
        top: -28.5%;
    }
    .main__item--photo3 {
        top: -22%;
    }
} //1850

@media screen and (max-width: 1700px) {
    .main__item--photo .main__item--title {
        width: 50%;
    }
} //1700

@media screen and (max-width: 1665px) {
    .header .box {
        margin-left: 30px;
    }
    .gallery-slider{
        padding-left: 50px;
        padding-right: 50px;
        max-width: 1590px;
    }
    .progress .gallery-slider{
        padding-left: 200px;
        padding-right: 200px;
    }
    .about .slider{
        margin-left: 25px;
        margin-right: 25px;
    }
  
    .slick-slider .slick-arrow {
        width: 50px;
        &:before{
            font-size: 20px;
        }
    }
    .slick-slider .slick-arrow.slick-next {
        right: -50px;
        &:hover{
            right: -55px;
        }
    }  
    .slick-slider .slick-arrow.slick-prev {
        left: -50px;
        &:hover{
            left: -55px;
        }
    }  
    .gallery-slider .slick-dots button{
        width: 20px;
        height: 20px;
        &:before{
            width: 8px;
            height: 8px;
        }
        
    }
    .news__slider { 
        .slick-slider .slick-arrow.slick-prev {
            left: 0;
            &:hover{
                left: 0;
            }
        }
    }

} //1665


@media screen and (max-width: 1500px) {
    .main__item--tc .main__item--title {
        font-size: 36px !important;
    }
    .main__item--tc .main__item--title div {
        font-size: 65px !important;
    }

    .contacts .realty-panel__item div{
            padding-left: 50px;
            padding-right: 50px;
    }
    .main__item--photo .main__item--title {
        font-size: 40px;
        line-height: 40px;
    }
    .main__item--photo .main__item--title p {
        font-size: 30px;
        line-height: 35px;
    }
    .content__block .box {
        padding-left: 50px;
        padding-right: 50px;
    }
    .content__block {
        padding-top: 150px;
    }
    .content__block.about .about__in .about__in--text {
        padding: 28px 0 0 8%;
    } 
    .content {
        margin-left: 30px;
        margin-right: 30px;
    }
    .additional_body .btn{
        left: 25px;
        bottom: 20px;
    }
    .additional__title{
        margin-bottom: 15px;
        font-size: 23px;
    }
    .btn{
        padding: 15px 30px;
        font-size: 12px;
    }

    .main__item--comfort .main__item--title {
        font-size: 60px;
        line-height: 50px;
        margin-bottom: 35px;
    }
    .main__item--comfort {
        font-size: 18px;
        line-height: 18px;
        padding-right: 5%;
    }
    .main__item--but {
        height: 70px;
        max-width: 315px;
        font-size: 12px;
        margin-top: 40px;
    }
    .main__item--ecology .main__item--title {
        font-size: 60px;
        line-height: 55px;
    }
    .main__item--mortgage .main__item--title {
        font-size: 60px;
        line-height: 55px;
        span {
            font-size: 55px;
        }
    }
    .main__item--news .main__item--title {
        font-size: 60px;
        line-height: 50px;
        margin-bottom: 30px;
    }
    .main__item--news {
        padding: 0 0 0 6.7%;
    }
    .news__slider .news__slider--item {
        padding-right: 13%;
    }
    .news__slider .news__slider--item .news__slider--item--title {
        font-size: 22px;
        line-height: 24px;
    }
    .news__slider .slick-slider .slick-arrow {
        // bottom: -80px;
    }
    .main__item--map {
        top: 7%;
        font-size: 24px;
        line-height: 26px;
    }
    .main__item--map .main__item--map__in {
        padding: 30px;
    }
    .main__item--map .main__item--but {
        padding: 0 30px;
        max-width: 269px;
    }
    .main__item--findflat .main__item--title {
        font-size: 47px;
        line-height: 45px;
    }
    .main__item--findflat .main__item--title p small{
        font-size: 30px;
        line-height: 30px;
    }
    .main__item--findflat .main__item--but {
        margin: 3% auto 0 auto;
    }
    .main__item--life {
        padding: 30px 7% 30px 5%;
        font-size: 16px;
        line-height: 18px;
    }
    .main__item--life .main__item--title {
        font-size: 40px;
        line-height: 40px;
    }
    .main__item--life .main__item--text {
        margin-top: 25px;
    }
    .main__item--but.main__item--but__pink {
        max-width: 260px;
        margin-top: 30px;
    }

    .main__item--but.main__item--but__brush {
        max-width: 264px;
        padding: 0 35px 0 38px;
    }
    .main__item--photo .main__item--title {
        bottom: 75px;
    }
    .main__item--video__content .main__item--title {
        padding: 0 10% 20% 10%;
    }
    .main__item--flatprice .main__item--title {
        font-size: 50px;
        line-height: 60px;
    }
    .main__item--flatprice .main__item--title span {
        font-size: 90px;
    }
    .decoration .block{
        max-width: 80%;
        margin: 0 auto;
    }
    .head__buts a{
        margin-right: 15px;
    }
} //1500

@media screen and (max-width: 1400px) {
    .advants .advants__links .advants__item {
        width: 32%;
    }
    .advants .advants__links .advants__item .advants__item__title {
        font-size: 14px;
        line-height: 16px;
        padding: 15px 20px 15px 40px;
    }
    .advants .advants__links .advants__item .advants__item__title i img {
        height: 30px !important;
    }
    .advants .advants__links .advants__item .advants__item__title i:before {
        font-size: 30px !important;
    }
    .advants .advants__links .advants__item .advants__item__title i {
        width: 60px;
    }
    .modal .slick-slider .slick-arrow.slick-next{
        right: 0;
        background: rgba(255,255,255,.4);
        width: 70px;
        &:before{
            color: #442463
        }
    }
    .modal .slick-slider .slick-arrow.slick-prev{
        left: 0;
        background: rgba(255,255,255,.4);
        width: 70px;
        &:before{
            color: #442463
        }
    }
    .video-overlay:before{
        font-size: 110px;
    }
    .video-overlay__title{
        font-size: 18px;
        padding: 40px;
    }
    .progress .album__name{
        font-size: 15px;
    }

    .icons__item{
        width: calc(25% - 55px);
    }
    .icons__item__text{
        margin: 0;
    }
    .styles{
        margin-left: 0;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;

    }
    .styles:after{
        display: none;
    }
    .styles__item{
        // max-width: 475px;
        // width: 49%;
        margin: 0 0 40px;
    }
    .styles__item__body{
        // min-height: 70%;
        padding: 40px 20px;
    }
    .styles__item__title{
        font-size: 22px;
    }
    .block__item__text{
        padding: 40px 20px;
    }

    .news__slider{
      padding-bottom: 0px;
    }

    .main__item--news .slick-slider .slick-arrow{
      bottom: 50%;
      margin-top: -20px;
    }

    .main__item--news .slick-slider .slick-arrow.slick-next{
      right: 10px !important;
      left: auto !important;
    }

    .main__item--news .slick-slider .slick-arrow.slick-prev{
      right: auto !important;
      left: -40px !important;
    }

    .block__item:first-child{
      margin-top: 100px;
    }
    // .head-links, .head__buts, .head__tel, .ready .loader  {
    //     transform: scale(.9) !important;
    // }
    .ready .loader {
        transform-origin: left;
        transform: scale(.9) !important;
    }

    .head__tel{
        font-size: 18px;
    }
    .head-links__item{

    }
} //1400

@media screen and (max-width: 1350px) {
    // .head-links a:last-child{
    //     display: none;
    // }
    // .video__lnk, .panoram__lnk{
    //     display: none;
    // }
    // .head__buts a{
    //     margin-right: 0;
    // }
    .icon-i_house:before {
        width: 44px;
    }
    .main__item--tc .main__item--title {
        font-size: 29px !important;
    }
    .main__item--tc .main__item--title div {
        font-size: 55px !important;
    }

    .home__arrow__thumbs{
        border-width: 8px;
    }
    .main__item--comfort .main__item--title {
        font-size: 50px;
        line-height: 45px;
        margin-bottom: 30px;
    }
    .main__item--comfort {
        font-size: 16px;
    }
    .main__item--but img {
        width: 35px;
    }
    .main__item--but i {
        width: 35px;
    }

    .main__item--but {
        height: 60px;
        max-width: 275px;
        font-size: 10px;
        margin-top: 30px;
    }
    .main__item--ecology .main__item--title {
        font-size: 45px;
        line-height: 50px;
    }
    .main__item--mortgage .main__item--title {
        font-size: 45px;
        line-height: 50px;
        span {
            font-size: 40px;
        }
    }
    .main__item--but.main__item--but__white {
        max-width: 275px;
    }
    .main__item--news .main__item--title {
        font-size: 50px;
        line-height: 45px;
    }
    .news__slider .news__slider--item {
        font-size: 12px;
        line-height: 14px;
    }
    .news__slider .news__slider--item .news__slider--item--link {
        font-size: 14px;
        line-height: 16px;
    }
    .news__slider .news__slider--item .news__slider--item--title {
        font-size: 18px;
        line-height: 20px;
    }
    .main__item--map {
        font-size: 22px;
        line-height: 24px;
    }
    .main__item--photo .main__item--title {
        font-size: 34px;
        line-height: 36px;
    }
    .main__item--photo .main__item--title p {
        font-size: 26px;
        line-height: 30px;
    }
    .main__item--photo .main__item--title {
        bottom: 60px;
    }
    .main__item--but.main__item--but__brush {
        max-width: 245px;
    }
    .main__item--video__content .main__item--title {
        font-size: 40px;
        line-height: 40px;
    }

    .main__item--video__content .main__item--video__links {
        font-size: 18px;
        line-height: 20px;
    }
    .main__item--video__content .main__item--video__links img {
        margin: 0 auto 35px auto;
    }
    .main__item--video__content .main__item--video__links a {
        width: 33.3333%;
    }
    .main__item--life .main__item--title {
        font-size: 36px;
        line-height: 36px;
    }
    .main__item--life {
        font-size: 14px;
        line-height: 16px;
    }
    .main__item--but.main__item--but__pink {
        max-width: 275px;
    }
    .main__item--findflat .main__item--title {
        font-size: 36px;
        line-height: 40px;
    }
    .main__item--findflat .main__item--title p small{
        font-size: 24px;
        line-height: 24px;
    }
    .main__item--findflat .main__item--but {
        max-width: 240px;
        overflow: visible;
    }
    .main__item--flatprice .main__item--title {
        font-size: 40px;
        line-height: 50px;
    }
    .main__item--flatprice .main__item--title span {
        font-size: 80px;
    }
    .additional_body{
        padding: 25px 25px 110px 25px;
        width: 70%;
        font-size: 13px;
    }
    .progress .albums{
        margin-left: -40px;
    }
    .progress .album {
        width: calc(50% - 40px);
        margin-left: 40px;
        margin-bottom: 35px;
    }
    .purchase__item__body{
        padding: 3vw;
        font-size: 14px;
        width: 65%;
    }
    .purchase__item__title{
        font-size: 40px;
        margin-bottom: 30px;
    }
    .purchase__item .btn{
        margin-top: 30px;
    }
    .mortgage__block .purchase__item {
        padding-top: 0;
        height: 300px;
    }
    .styles__item__body{
        min-height: 70%;
    }
} //1350


@media (max-width: 1300px) {
    .progress .gallery-slider{
        padding-left: 100px;
        padding-right: 100px;
    }

    .contacts .realty-panel__item div{
            padding-left: 20px;
            padding-right: 20px;
    }
    .privacy h1{
        font-size: 40px;
    }
    .privacy .breadcrumb .breadcrumb__links{
        text-align: right;
    }
    .btn_subscribe {
        top:37px;
    }
    .news__body{
        padding-top: 50px;
    }
    .news__item:nth-child(2n){
        padding-top: 80px;
    }
    .news__item{
        padding-bottom: 80px;
    }
    .new .news__item{
        padding-top: 0;
    }
    .new h1{
        font-size: 36px;
    }
    .commerce__left{
        padding-right: 0 !important;
    }

    .main__item--news .main__item--title{
      margin-bottom: 15px;
    }
} //1300
@media screen and (max-width: 1220px) {
    .header__area {
        padding-right: 80px;
    }
    menu {
        width: 320px;
        transform: translateX(260px);
    }
	.header__area .but {
		// right: 20.3%;
	}
    .styles__item__image{
        padding-top: 200%;
    }
    .video__lnk, .panoram__lnk{
        // display: none;
    }
} //1220

@media screen and (max-width: 1200px) {
    .head-links__item--hid{display: none;}
    .docs__group__body{
        margin-left: -5%;
    }
    .docs__item{
        padding-left: 150px;
        width: 45%;
        margin-left: 5%;
    }
    .select .select__single-value, .select .select__placeholder, .docs__name{
        font-size: 20px;
    }
    .docs__item:before{
        width: 120px;
        height: 120px;
        font-size: 40px;
    }
    .docs-filter .select{
        width: 30%;
    }
    .head__tel {
        left: 16%;
    }
    .head__buts {
        left: 38%;
    }
    .content__menu a {
        font-size: 13px;
        line-height: 15px;
    }
    .infra-filter{
        top: 30px;
        left: 30px;
        width: 350px;
    }
    .infra-filter__body{
        padding: 20px;
    }
    .infra-filter__title{
        padding: 30px;
        font-size: 22px;
    }
    .infra-filter__title__arrow{
        width: 80px;
    }
    .content__menu a{
        white-space: nowrap;
    }
    .content__block .box{
        padding-left: 30px;
        padding-right: 30px;
    }
    .content__menu a{
        padding-left: 15px;
        padding-right: 15px;
    }
    .styles__item__body{
        min-height: 80%;
    }
    .styles__item--middle .styles__item__body{
        width: 75%;
    }
} //1200

@media screen and (max-width: 1100px) {
    .home-mobile .main__item--comfort.main__item--tc{
        width: 100%;
        height: 50%;
        top: auto;
        bottom: 0;
        min-height: 250px;
    }
    .main__item--tc .main__item--title {
        font-size: 21px !important;
        font-weight: 300 !important;
        line-height: 1 !important;
    }
    .main__item--tc .main__item--title div {
        font-size: 43px !important;
        line-height: 0.9;
    }
    .advants {
        padding: 90px 0 30px 0;
    }
    .head__buts a {
        margin-right: 22px;
    }
    .head__buts {
        left: 40%;
    }
    .header__area .but {
        // right: 22%;
		padding: 0 15px;
    }
	.progress__lnk {
	    margin-right: 23px !important;
	}

    .main__item--comfort .main__item--title {
        font-size: 40px;
        line-height: 35px;
        margin-bottom: 20px;
    }
    .main__item--comfort {
        font-size: 14px;
        line-height: 16px;
    }
    .main__item--but {
        height: 55px;
    }
    .main__item--photo .main__item--title {
        font-size: 30px;
        line-height: 33px;
    }
    .main__item--photo .main__item--title p {
        font-size: 22px;
        line-height: 26px;
    }
    .main__item--but.main__item--but__brush {
        max-width: 225px;
        padding-left: 25px;
    }
    .main__item--video__content .main__item--title {
        font-size: 30px;
        line-height: 30px;
        padding-bottom: 30%;
    }
    .main__item--video__content .main__item--video__links {
        font-size: 14px;
        line-height: 18px;
        bottom: 15%;
    }
    .main__item--life .main__item--title {
        font-size: 30px;
        line-height: 32px;
    }
    .main__item--life {
        font-size: 12px;
        line-height: 14px;
    }
    .main__item--but.main__item--but__pink {
        max-width: 259px;
        padding-left: 23px;
        padding-right: 10px;
    }
    .main__item--findflat .main__item--title {
        font-size: 30px;
        line-height: 30px;
    }
    .main__item--findflat .main__item--title p small{
        font-size: 18px;
        line-height: 18px;
    }
    .main__item--ecology .main__item--title {
        font-size: 35px;
        line-height: 40px;
    }
    .main__item--mortgage .main__item--title {
        font-size: 35px;
        line-height: 40px;
        span {
            font-size: 46px;
        }
    }
    .main__item--but.main__item--but__white {
        max-width: 237px;
        padding-left: 20px;
    }
    .main__item--news .main__item--title {
        font-size: 40px;
        line-height: 40px;
        margin-bottom: 20px;
    }
    .news__slider .news__slider--item {
        font-size: 10px;
        line-height: 12px;
    }
    .news__slider .news__slider--item .news__slider--item--link {
        font-size: 12px;
        line-height: 14px;
    }
    .news__slider .news__slider--item .news__slider--item--title {
        font-size: 16px;
        line-height: 18px;
    }
    .news__slider .slick-slider .slick-arrow {
        height: 30px;
        background-position: center center;
        bottom: 50%;
    }
    .main__item--map {
        font-size: 20px;
        line-height: 22px;
    }

} //1100


@media (min-width: 1000px) {
    .fixed-banks {
        td {
            min-width: auto !important;
        }
        th {
            min-width: auto !important;
        }
    }
    .news__panel a:hover{
        color: #e2068c;
    }
    .news__panel a:hover span{
        border-bottom-color: transparent;
    }
    .news__paginator a:hover span{
        border-bottom-color: transparent;
        color: #e2068c;
    }
    .news__name a:hover span{
        border-bottom-color: transparent;
    }
    .news__btn a:hover {
        color: #442463;
    }
    .section__right {
        display: none;
    }

    .section__middle--desktop {
        width: 100%;
        min-width: auto;
        display: -webkit-flex;
        display: flex;
        -webkit-justify-content: flex-start;
        justify-content: flex-start;
        position: relative;

        .section__title {
            white-space: nowrap;
        }
        .section-view__row:first-child .section-view__cell__floor:first-child:before {
            display: none;
        }
        .section-view {
            height: 100%;
            padding: 0 12px;
        }
        .section-view__row {
            padding-bottom: 0;
            border-left: none;
            height: 45px;
            padding-top: 15px;
            &:first-child {
                height: 42px;
                padding-top: 12px;
            }
            &:last-child {
                padding-bottom: 0;
            }
            .section-view__cell__floor {
                position: relative;
                left: 0;
                width: 40px;
                height: 30px;
                min-width: auto;
                margin: 0;
                color: #c8c8c8;
                font-size: 10px;
            }
        }

        .section__title {
            position: relative;
            top: -36px;
            left: 0;
            -webkit-transform: none;
            transform: none;
            text-align: center;
            font-size: 14px;
            line-height: 14px;
            margin-top: -14px;
            color: #442463;
        }
        .section__body {
            padding: 0 0 37px 0;
            margin: 0 5px;
            &:last-child {
                margin-right: 0;
            }
            &.section__body--nums {
                background: transparent;
                margin: 0 -5px 0 0;
                .section-view {
                    padding: 0;
                }
            }
        }

        .section__body:after {
           display: none;
        }
        .section-view__cell {
            margin-right: 2px;
            width: 30px;
            font-size: 14px;
            &:last-child {
                margin-right: 0;
            }
        }
        .section-view__cell .section-view__num, .section-view__cell__floor .section-view__num {
            padding-top: 0;
        }
        .section-view__flat__title {
            font-size: 18px;
        }
        .section-view__flat {
            top: 10px;
            margin-left: 5px;
        }

    }

    .section__left--desktop {
        width: 265px;
        min-width: 265px;
        .corpus-list__title span {
            display: inline-block;
            font-size: 42px;
            line-height: 20px;
            vertical-align: middle;
        }
        .corpus-list__title {
            background: #442463;
            color: #fff;
            display: -webkit-flex;
            display: flex;
            -webkit-align-items: center;
            align-items: center;
            -webkit-justify-content: space-between;
            justify-content: space-between;
            padding: 13px 23px 13px;
            font-size: 24px;
        }
        .corpus-plan {
            width: 100%;
            max-width: 100%;
            max-height: 100%;
        }

        .section__arrow {
            width: 19px;
            height: 35px;
            font-size: 35px;
        }
        .corpus-plan .building-scheme {
            height: 139px;
        }
        .corpus-list__body__item {
            width: 124px;
            height: 139px;
            max-width: none;
        }
        .corpus-list__body {
            padding: 0 0;
            height: 197px;
            pointer-events: none;
        }
        // .corpus-list__body__item16 span {
        //     left: 60px;
        //     top: 60px;
        //     font-size: 20px;
        // }
        .section__filter__title {
            padding: 21px 15px;
            margin-bottom: 0;
        }
        .section__filter {
            padding: 0;
            margin-bottom: 20px;
        }
        .section__filter__item {
            display: flex;
            padding: 0 16px 0 40px;
            margin-bottom: 0;
            height: 43px;
            font-size: 14px;
        }
        /*
        .section__filter__item.active:hover {
            background: #fff;
            color: #000;
            span {
                color: #e2068c;
            }
        }
         */

    }
    .section__content--desktop.section__content {
        padding-top: 34px;
        padding-bottom: 100px;
    }

	.routeLink:hover {
		span {
			border-bottom-color: transparent;
		}
	}
} //min 1000

@media screen and (max-width: 1560px) and (min-width: 1000px) {
    .section__middle--desktop .section-view {
        padding: 0 5px;
    }
}
@media screen and (max-width: 1450px) and (min-width: 1000px) {
    .section__middle--desktop .section__body {
        padding: 0 0 20px 0;
        margin: 0 3px;
    }
    .section__middle--desktop .section-view__cell {
        width: 25px;
        font-size: 13px;
    }
    .section__middle--desktop .section-view__row {
        height: 35px;
        padding-top: 10px;
    }
    .section__left--desktop .corpus-list__title {
        font-size: 18px;
    }
    .section__left--desktop .corpus-list__title span {
        font-size: 32px;
        line-height: 18px;
    }
}
@media screen and (max-width: 1320px) and (min-width: 1000px) {
    .section__filter__title {
        font-size: 16px;
    }
    .section__left--desktop .corpus-list__title {
        font-size: 16px;
    }
    .section__left--desktop .corpus-list__title span {
        font-size: 30px;
        line-height: 16px;
    }
}
@media screen and (max-width: 1300px) and (min-width: 1000px) {
    .section__left.section__left--desktop {
        width: 215px;
        min-width: 215px;
    }
    .head-links__item--hid{
        max-width: 150px
    }
}

@media screen and (max-width: 1220px) and (min-width: 1000px) {
    .section__left--desktop .section__filter__title {
        font-size: 12px;
        line-height: 1.2;
        padding: 15px 10px;
    }
    .section__left--desktop .corpus-list__title {
        font-size: 12px;
        padding: 6px 10px 7px;
    }
    .section__left--desktop .corpus-list__title span {
        font-size: 20px;
        line-height: 12px;
    }
    .section__left--desktop .section__arrow {
        font-size: 28px;
        line-height: 40px;
    }
    .section__left.section__left--desktop {
        width: 180px;
        min-width: 180px;
    }
    .section__left--desktop .section__filter__item {
        padding: 0 10px 0 20px;
        font-size: 12px;
        height: 40px;
    }
    .back a {
        display: block;
        max-width: 200px;
    }

}

@media screen and (max-width: 1185px) and (min-width: 1000px) {
    .section__left.section__left--desktop {
        width: 140px;
        min-width: 140px;
    }
    .section__left--desktop .section__filter__item {
        padding: 0 0px 0 15px;
    }
    .section__left--desktop .corpus-list__body__item.active {
        -webkit-transform: scale(0.8);
        transform: scale(0.8);
    }
    .section__left--desktop .corpus-list__body {
        padding: 0 0;
        height: 160px;
    }
}

@media screen and (max-width: 1150px) and (min-width: 1000px) {
    .section__middle--desktop .section-view {
        padding: 0 4px;
    }
    .section__middle--desktop .section-view__cell {
        width: 21px;
    }
}

@media (max-width: 1000px) {
    .mail-modal.modalwin{
        .modalwin__box{
            width: 300px;
            padding-top: 45px;
            padding-bottom: 30px;
            margin-bottom: 60px !important;
        }
        .modal__title{line-height: 1;}
        .close__modal{
            top: 10px;
            right: 10px;
            width: 28px;
        }
        .modal__input.modal__input--but{
            position: absolute;
            left: auto;
            right: 0;
            margin: 0;
            bottom: 0;
            width: 165px;
            font-size: 12px;
        }
        .modal__input.modal__input--but button:hover{
            transform: none !important;
        }
        .modal__input{
            margin-top: 15px;
            input{font-size: 12px !important;}
        }
        .modal__input.modal__input--but button i{
            height: 17px;
            margin-right: 10px;
        }
        .modal__input.modal__input--but button i:before{
            font-size: 17px;
        }
    }

    .mobile-menu .head__tel{
        font-size: 22px;
        font-weight: normal;
    }
    .mobile-menu .head__tel__txt{
        color: #fff;
        font-size: 14px;
        margin-top: 8px;
    }
    .question-btn{max-width: 100%; margin: 30px 0;}
    .flat-list .fixed-banks th span {
        padding: 0;
    }
     .mobile-menu .head__buts{padding-left: 0;}
    .fixed-banks {
        td {
            width: auto !important;
        }
        th {
            width: auto !important;
        }
        .fixed-tr {
        }
        &.fixed {
            .fixed-tr {
                &:before {
                    content: '';
                    display: block;
                    width: 30px;
                    height: 100%;
                    right: 0;
                    left: auto;
                    background-color: #fff;
                    top: 0;
                    z-index: 99;
                    position: fixed;
                }
                &:after {
                    content: '';
                    display: block;
                    position: fixed;
                    width: 30px;
                    height: 100%;
                    right: auto;
                    left: 0;
                    background-color: #fff;
                    top: 0;
                    z-index: 99;
                }
            }
        }
    }
    .section__left .corpus-list {
        display: none;
    }
    .corpus-plan {
        width: auto;
        max-width: 100%;
    }
    .progress .gallery-slider{
        padding-left: 30px;
        padding-right: 30px;
    }
    .news__paginator a{
        width: 34px;
        height: 34px;
        font-size: 14px;
        line-height: 1;
    }
    .news__paginator a.active{
        margin-left: 5px;
        margin-right: 5px;
    }
    .modal .slider .slick-slide, .modal .slider .slider__item>div{
            height: auto;
        max-height: initial;

    }
    .modal .slick-slider > .slick-arrow.slick-prev, 
    .modal .slick-slider > .slick-arrow.slick-next{
        transform: translateY(-50%) !important;
    }
    .commerce-gallery__thumbs .slick-slider .slick-arrow.slick-next:hover, .progress .slick-slider .slick-arrow.slick-next:hover{
        right: -25px !important;
        transform: translateY(-50%) !important;
    }
    .commerce-gallery__thumbs .slick-slider .slick-arrow.slick-prev:hover, .progress .slick-slider .slick-arrow.slick-prev:hover{
        left: -25px;
        transform: translateY(-50%) !important;
    }
    .awards .slick-slider .slick-arrow.slick-next:hover,
    .awards .slick-slider .slick-arrow.slick-prev:hover,
    .gallery .slick-slider .slick-arrow.slick-next:hover,
    .gallery .slick-slider .slick-arrow.slick-prev:hover,
    .advants .slick-slider .slick-arrow.slick-next:hover,
    .advants .slick-slider .slick-arrow.slick-prev:hover,
    .residental .slick-slider .slick-arrow.slick-next:hover,
    .residental .slick-slider .slick-arrow.slick-prev:hover,
    .about .slick-slider .slick-arrow.slick-next:hover,
    .about .slick-slider .slick-arrow.slick-prev:hover{
        transform: translateY(-50%) !important;
    }
    .modal .slider__item-bg{
        background-size: contain;
    }
    .modal-gallery .slider__item-bg{
        height: calc(100% - 70px);
    }
    .modal-gallery .slick-dots button:before{
        box-shadow: 0 0 0 1px #442463;
    }
    .modal-gallery .slick-dots .slick-active button:before{
        box-shadow: 0 0 0 1px #e2068c
    }
    .video-overlay:before{
        line-height: 1;
        height: auto;
        width: auto;
        margin: 0;
        transform: translate(-50%, -50%);
        font-size: 70px;
    }
    .main__item--news .slick-slider .slick-arrow.slick-prev:hover{
        transform: translateY(0) scale(1);
    }
    .main__item--news .slick-slider .slick-arrow.slick-next:hover{
        transform: rotate(180deg) scale(1)
    }

    .additional_left, .additional_right{
        width: 100%;
        margin-bottom: 20px;
        padding-top: 0;
    }
    .additional_body{
        position: relative;
    }
    header{
        background: #fff;
        position: fixed;
        height: 80px !important;
    }
    menu .menu__out{
        // background: transparent;
        font-size: 0;
    }
    menu .menu__detect .burger{
        font-size: 40px;
        color: #000;
    }
    .loader .logo, .ready .loader{
        width: 120px;
    }
    .header .box{
        margin: 0;
        width: 100%;
        display: flex;
        justify-content: flex-end;
        height: 80px;
        align-items: center;
        padding-left: 25px;
        padding-right: 25px;
    }
    .head__tel{
        position: static;
        height: auto;
        font-size: 14px;
        color: #030303;
        margin-right: 25px;
        a{text-decoration: none; color: inherit;}
        .popup__lnk{
            font-size: 10px;
            margin-top: 5px;
        }
    }
    .ready .loader{
        top: 17px;
        left: 25px;
        position: fixed;
    }
    .loader .logo, .ready .loader{
        width: 120px;
        height: 45px;
    }
    .loader{
        height: 45px;
        top: 50%;
    }
    .head__tel{
        line-height: 1;
        display: inline-block;
    }
    .menu__socials{
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 50px !important;
        .mdgroup{
            margin-bottom: 0;
            margin-right: auto;
            height: 43px;
            width: 76px;
        }
    }
    .menu__content .menu__socials a{
        height: 38px;
        width: 38px;
        &:before{
            font-size: 38px;
        }
    }
    .menu__content{
        padding: 15px 45px 50px;
    }
    .overlay{
        z-index: 999
    }
    .menu__content .menu__in a{
        margin-bottom: 26px;
        text-decoration: none;
        font-size: 20px;
        span{
            border-bottom: 1px solid #735b8a;
        }
        &.active span{
            border-bottom-color: #6c1c6d
        }
    }

    .menu__content .menu__in a:before{
        display: none;
    }
    .menu__detect{
        position: relative;
        top: 0 !important;
        transform: none !important;
        left: 0;
        width: 100%;
        height: 75px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        font-size: 24px;
        font-weight: 500;
        text-transform: uppercase;
        background: #442463;
        z-index: 1;
        .menu__detect__close{
            content: '';
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 24px;
            color: #e2068c;
        }
    }

    .menu__in{
        padding-bottom: 35px;
    }
    .mobile-submenu{
        padding-bottom: 35px;
        a{
            color: #d50b88 !important;
            position: relative;
            padding-left: 28px;
            margin-bottom: 20px !important;
            font-size: 16px !important;
            &:after{
                content: '';
                position: absolute;
                left: 0;
                top: 6px;
                width: 4px;
                height: 4px;
                background: #e2068c;

            }
            span, p{
                display: inline;
                border-bottom: 1px solid #6c1c6d;
            }
        }

    }
    .mobile-scroll-helper{
        width: 600px;
        height: 2px;
        display: block;
    }
    .genplan-scheme{
        min-width: 600px;
        margin-bottom: 30px !important;
    }
    .genplan{
        overflow: hidden;
    }
    .mobile-menu{
        .head__buts{
            position: static;
            justify-content: center;
            align-items: center;
            .popup__lnk{
                margin: 0 17px;
            }
            .popup__lnk, .video__lnk:before, .icon-i_panoram:before{
                color: #e2068c;
            }
            .video__lnk{
                margin-left: 15px;
            }
            .video__lnk:before{
                font-size: 27px;
            }
			.progress__lnk {
				font-size:36px;
				margin-right: 17px !important;
			}
        }
        .head__tel{
            font-family: 'Acrom';
            font-size: 20px;
            color: #fff;
            margin: 0 0 20px;
            text-align: center;
            line-height: 1.2
        }
        .head__tel .popup__lnk{
            font-size: 18px;
            font-weight: 500;
        }
        .menu__content .menu__bottom a{
            color: inherit;
        }
        .made__in{
            font-size: 18px;
            text-decoration: none;
            margin-top: 30px;
            display: inline-block;
            &>span{
                text-decoration: none;
                border-bottom: 1px solid #735b8a;
                &>span{
                    text-decoration: none;
                    color: #fff;
                }
            }
        }
        .but{
            width: 100%;
            background: transparent;
            margin-bottom: 42px;
            color: #fff;
            font-size: 12px;
            font-family: 'Acrom';
            font-weight: 600;
            margin-top: 25px;
        }
    }
    .content__block{
        padding-top: 160px;
        padding-bottom: 70px;
    }
    .breadcrumb__links{
        display: none;
    }
    h1{
        font-size: 32px;
        line-height: 1.2;
        margin-bottom: 20px;
    }
    .news{
        padding-bottom: 70px;
    }
    .news__item{
        padding-bottom: 70px;
        &:last-child{
            padding-bottom: 0;
        }
    }
    .news__paginator{
        padding-top: 65px;
    }
    .news__date{
        padding-left: 0;
        padding-bottom: 18px;
        font-size: 12px;
    }
    .news__name{
        max-width: 100%;
        a{ font-size: 16px; }
    }
    .news__description{
        padding: 20px 0;
        font-size: 15px;
    }
    .news__item__image{
        margin-bottom: 20px;
    }
    .news__btn a{
        font-size: 12px;
        padding-top: 14px;
        padding-bottom: 14px;
    }
    .news__btn{
        text-align: center;
    }
    .news__item:nth-child(2n){
        padding-top: 0;
    }
    .new .news__item{
        flex-direction: column;
    }
    .content__block .box{
        padding-left: 30px;
        padding-right: 30px;
    }
    .new {
        h1{
            font-size: 24px;
        }
        .breadcrumb{
            margin-bottom: 25px;
        }
        .news__date {
            padding-bottom: 25px;
            width: 100%;
        }
        .news__item__image{
            width: initial;
            min-width: initial;
            // max-width: 400px;
            margin: 0 -30px 45px;
            padding-top: 0;
            height: 380px;
            width: calc(100% + 50px)
        }
        .news__item__body{
            width: 100%;
            display: flex;
            flex-direction: column;
            .news__description{
                order: -2;
            }
            .news__arrows{
                order: -1;
            }
            &>div{
                width: 100%;
            }
        }
        .news__description h2{
            font-size: 20px;
            margin-bottom: 35px;
        }
        .news__arrows{
            padding-top: 15px;
            a{
                margin-right: 15px;
            }
        }
        .news__btn{
            padding-top: 30px;
            a{
                text-transform: uppercase;
                padding-left: 30px;
                padding-right: 30px;
            }
        }
        .news__item{
            padding-bottom: 0;
        }
        .news__description p:last-child{margin-bottom: 0;}
        .news__description p:first-child{margin-top: 0;}
    }
    .news__item__noimage{
        padding-top: 0 !important;
    }
    .select-mobile{
        border: none;
        background: #f4f4f4;
        height: 75px;
    }
    .select__indicator--mobile{
        background: #f4f4f4;
        width: 60px;
        pointer-events: none;
    }
    .select-mobile select{
        padding-left: 20px;
        padding-right: 65px;
    }
    .select__indicator--mobile:before{
        font-size: 15px;
        color: #442463;
        right: 20px;
    }
    .news{
        .select-mobile select{
            padding-left: 65px;
            text-align: center;
            text-align-last:center;
        }
    }
    .select-mobile__icon{
        position: absolute;
        top: 50%;
        left: 22px;
        transform: translateY(-50%);
        font-size: 35px;
        color: #e2068c;
        pointer-events: none;
    }
    .news__body{
        padding-top: 20px;
    }
    .btn_subscribe {
        position: relative;
        top:0;
        margin-bottom: 30px;
    }

    .content__menu{
        margin: 0 30px;
    }
    .content__menu .box{
        flex-direction: column;
        padding: 30px 0;
    }
    .content__menu a{
        width: 100%;
        // font-size: 12px;
        justify-content: flex-start;
        padding: 12px 25px;
    }
    .content__menu a i:before{
        font-size: 20px;
    }
    .content__menu a i{
        margin-right: 8px;
    }
    .about .slider{
        margin: 0;
    }
    .content__block--slider{
        padding: 60px 0;
    }
    .content__block--slider .content__block--slider__item{
        padding: 0;
    }
    .slick-slider .slick-arrow.slick-next {
        right: -25px;
    }
    .slick-slider .slick-arrow.slick-prev {
        left: -25px;
    }
    .slick-slider .slick-arrow.slick-next:hover{
        transform: translateY(-50%);
        right: -25px;
    }
    .slick-slider .slick-arrow.slick-prev:hover{
        transform: translateY(-50%);
        left: -25px;
    }
    .content__block.about .about__in{
        display: block;
        padding-top: 0;
    }
    .content__block.about .about__in .about__in--text{
        padding: 35px 0;
        width: 100%;
        font-size: 16px;
        line-height: 1.5;
    }
    .content__block.about .about__in .main__item--but{
        max-width: 195px;
        margin: 35px auto 0;
        text-transform: uppercase;
    }
    .content__block{
        font-size: 12px;
    }
    .content__block--slider{
        margin-top: 0;
    }
    .slick-slider .slick-arrow:before{
        font-size: 30px;
        color: #ccc !important;
    }
    .video__type i:before{
        font-size: 76px;
    }
    .video__type i{
        margin-left: -38px;
        margin-top: -38px;
        width: 76px;
        height: 76px;
    }
    .flat-choose .box{
        padding: 50px;
        min-height: 0;
    }
    .flat-choose .box__title{
        font-size: 36px;
        font-weight: 400;
        text-transform: uppercase;
        text-align: left;
        line-height: 1.2;
        margin-bottom: 90px;
        margin-top: 0;
    }
    .flat-choose__links{
        font-size: 14px;
        line-height: 18px;
        bottom: 15%;
        text-transform: uppercase;
        display: flex;
        flex-direction: column;
        a{
            width: 100%;
            display: -webkit-flex;
            display: flex;
            -webkit-align-items: center;
            align-items: center;
            padding: 0;
            margin-bottom: 35px;
            text-align: left;
            &:last-child{
                margin-bottom: 0;
            }
        }
    }
    .flat-choose .flat-choose__links span{
        border: none;
    }
    .flat-choose .flat-choose__links i{
         height: auto; margin: 0 20px 0 0;
    }
    .flat-choose .flat-choose__links i:before{font-size: 35px; height: auto; margin: 0;}
    .advants{
        padding: 50px 0 0;
    }
    .advants__item{
        display: block !important;
        margin: 0 auto;
    }
    .advants__item__title i:before{
        font-size: 35px !important;
    }
    .advants__item__title{
        font-size: 12px;
        justify-content: center;
        padding: 20px 10px;
        line-height: 1.2;
    }
    .about__in img{
        display: block;
        margin: 0 auto;
    }
    .advants__item__title i{width: auto; margin-right: 15px;}
    .contacts-map{
        height: auto;
        margin-top: 10px;
        margin-bottom: 0;
    }
    .contacts-map__block {
        position: static;
        padding: 0;
        width: auto;
        margin-bottom: 30px;
    }
    #contacts-map{
        height: 500px;
        margin: 0 -30px;
    }
    .contacts-lnk{
        bottom: 20px;
        z-index: 1;
        left: 50%;
        transform: translateX(-50%);
    }
    .ymaps-2-1-73-map-copyrights-promo{
        display: none !important;
    }
    .contacts-map__block h2{
        font-size: 20px;
        margin-bottom: 7px;
    }
    .contacts-map__block__in > div{
        margin-bottom: 25px;
    }
    .contacts-map__social{
        justify-content: center;
    }
    .contacts-lnk{
        width: 265px;
        font-size: 12px;
    }
    .contacts-market-title{
        width: 200px;
        height: 63px;
        font-size: 16px;
    }
    .modalwin__box{
        padding: 60px 20px;
    }
    .modal__title{
        font-size: 22px;
    }
	.modalwin.modalwin--installments .modal__title {
		margin-bottom:20px
	}
	.modalwin.modalwin--installments {
        font-size: 14px;
        line-height: 1.2;
	}
    .modalwin.modalwin--callback .modalwin__box{
        margin-bottom: 0;
    }
    .ReactModal__Overlay{
        z-index: 999;
    }
    .title{
        font-size: 28px;
    }
    .contacts .title {
        padding: 30px 0;
        margin-bottom: 0;
    }
    .contacts-tab{
        padding-top: 20px;
        padding-bottom: 0;
        img{
            width: 100%;
            display: block;
            margin: 0 auto 40px;
            max-width: 350px;
        }
    }
    .contacts-tab table, .contacts-tab tbody, .contacts-tab table tr, .contacts-tab table td{
        display: block;
        width: 100% !important;
        padding: 0 !important;
    }
    .select-mobile--break select{
        opacity: 0;
    }
    .select-mobile__title{
        display: block;
        color: #e2068c;
        font-size: 16px;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-right: 65px;
        padding-left: 75px;
        span{
            text-align: left;
            display: block;
        }
    }
    .list ul li, .decoration-slider__item__body ul li, .privacy ul li, .contacts-tab ul li{
        padding-left: 25px;
    }
    .docs-filter{
        background: none;
        padding: 0 !important;
        display: flex;
        flex-direction: column;
    }
    .docs-filter .select{
        width: 100%;
        display: block;
        margin: 0;
    }
    .docs__group__name{
        text-align: left;
        font-size: 26px;
        margin-bottom: 0;
        margin-top: 40px;
        line-height: 1.1;
    }
    .docs__group__body{
        margin: 0;
    }
    .docs__item{
        width: auto;
        margin: 0;
        padding: 40px 30px;
        margin: 0 -30px;
        display: block;
        &:nth-child(2n) {
            background-color: #f4f4f4;
            &:before {
                background-color: #fff;
            }
        }
    }
    .docs__item:before{
        position: relative;
        top: 0;
        left: 0;
        transform: none;
        margin: 0 auto 20px;
        // display: block;
    }
    .docs__date{
        font-size: 12px;
        padding-bottom: 15px;
    }
    .docs__description{
        font-size: 15px;
        padding: 16px 0;
    }
    .docs__btn{
        text-align: center;
        padding-top: 10px;
        a{
            text-transform: uppercase;
            font-size: 12px;
        }
    }
    .about .content__menu{
        display: none;
    }
    .residental__block{
        margin-bottom: 60px;
    }
    .residental-slider{
        margin-bottom: 30px;
    }
    .residental__icon{
        margin-bottom: 0;
    }
    .residental__block{
        // background: none !important;
        height: auto;
        background: none !important;
        padding: 180px 0 0;
        background-attachment: initial;
        width: auto;
    }
     .residental__block__image{
        height: 200px;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: 50% 50%;
        margin: 0 -30px 20px;
        // background-attachment: fixed;
     }
    .residental__text{
        position: static;
        color: #000;
        width: 100%;
        background: none;
    }
    .residental__title{
        font-size: 28px;
        color: #e2068c;
    }
    .residental__text > div{
        max-width: 100%;
    }
    .gallery .advants .slider{
        padding-bottom: 0;
    }
    .slider__item__text{
        display: none;
    }
    .gallery-slider{
        padding-left: 30px;
        padding-right: 30px;
    }
    .progress .albums{
        margin-left: -29px;
    }
    .album, .progress .album{
        width: calc(50% - 29px);
        margin-left: 29px;
        // margin: 0 0 30px;
    }
    .slider__item__gallery-text{
        // display: none;
        padding: 15px 20px 40px;
        font-size: 14px;
    }
    .modal-gallery{
        .modal__body, .modal__inner, .slider {
        height: 100%;
        }
        .slick-dots{
            position: absolute;
            bottom: 0;
            padding-bottom: 10px;
            button:before{
                background: #fff;
            }
            .slick-active button:before{
                background: #e2068c;
            }
        }

    }
    .modal .close__modal{
        top: 20px;
        right: 20px;
    }
    .modal .slider .slider__item > div{
        padding-top: 0;
        height: 100vh;
    }
    .developer__body {
        padding-top: 40px !important;
        padding-bottom: 40px !important;
        flex-direction: column;
    }
    .developer__img{
        width: 100%;
        min-width: 100%;
        padding-top: 0;
        margin: 0;
        height: 200px;
        background-size: contain;
        background-repeat: no-repeat;
        margin-bottom: 30px;
        background-color: #f4f4f4;
    }
    .developer__body__text{
        padding-right: 0;
    }
    .developer__title{
        line-height: 1.1;
        font-size: 28px;
        margin-bottom: 20px;
    }
    .awards__item{
        width: 100%;
        max-width: 340px;
    }
    .awards .slider{
        padding-bottom: 60px;
    }
    .awards__title{
        font-size: 28px;
        padding: 60px 0 30px;
    }
    .progress-title{
        line-height: 1.1;
        font-size: 25px;
        margin-bottom: 30px;
        span{
            display: block;
            white-space: nowrap;
            margin: 0 -30px;
        }
    }
    .video-overlay__title{
        display: none;
    }
    .gallery .slider{
        padding-bottom: 40px;
    }
    .progress .slider__item > div{
        height: 200px;
    }
    .infra-filter{
        position: relative;
        left: 0;
        top: 0;
        width: 100%;
    }
    .infra-filter__title{
        display: none;
    }
    #infra-map{
        margin: 0 -30px;
    }
    .infra-map-container{
        margin-top: 40px;
        margin-bottom: 60px;
    }
    .infra-filter__body{
        padding: 60px 0 0;
    }
    .main-icon-content__title{
        font-size: 14px;
        padding: 10px;
    }
    .main-icon-content__body{
        height: 60px;
        padding: 0 15px;
    }
    .main-icon-content{
        font-size: 12px;
        width: 174px;
    }
    .main-icon-tooltip{
        width: 195px;
        height: 170px;
        left: 4px;
        top: -172px;
        background-size: 100% 100%;
    }
    h1{
        max-width: 100% !important;
    }
    .privacy h1{
        font-size: 22px;
    }
    .privacy p:first-child{
        margin-top: 0;
    }
    .privacy h2{
        margin-top: 40px;
        font-size: 18px;
    }
    .purchase__block{
        flex-direction: column;
        padding-top: 0;
    }
    .purchase__item{
        width: 100%;
        padding-top: 0;
        margin-bottom: 22px;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
    }
    .purchase .title{
        display: none;
    }
    .purchase__item__body{
        width: 100%;
        height: auto;
        bottom: 0;
        top: auto;
        padding: 25px 30px 25px 38px;
        font-size: 12px;
        position: relative;
        margin-top: 180px;
    }
    .purchase__item__title{
        font-size: 30px;
        margin-bottom: 20px;
    }
    .purchase__item .btn{
        margin-top: 25px;
    }
    .purchase__block{
        margin-bottom: 40px;
    }
    .purchase__promotions{
        margin-left: 0;
    }
    .promotions__item{
        margin: 0 auto;
        display: block;
        width: 100%;
        margin-bottom: 20px;
        max-width: 400px;
    }
    .promotions__item__body{
        padding: 23px;
    }
    .promotions__item__title{
        font-size: 14px;
        min-height: 0;
        padding-bottom: 15px;
    }
    .promotions__item__text{
        font-size: 15px;
    }
    .promotions__btn{
        padding-top: 20px;
    }
    .promotions__btn .btn{
        padding-left: 50px;
        padding-right: 50px;
    }
    .purchase__item .btn{
        width: 100%;
        text-align: center;
        max-width: 250px;
        padding-left: 10px;
        padding-right: 10px;
    }
    .row-mobile{
        margin-bottom: 20px;
    }
    .row-mobile > div{
        display: flex;
        &:first-child{
            .row-mobile__title, .row-mobile__body{
                padding-top: 25px;
            }
        }
        &:last-child{
            .row-mobile__title, .row-mobile__body{
                padding-bottom: 25px;
            }
        }
    }
    .row-mobile__title{
        padding: 15px;
        background: #b37db7;
        color: #fff;
        width: 50%;
    }
    .row-mobile__body{
        padding: 15px;
        background: #f4f4f4;
        width: 50%;
    }
    .mortgage__block, .payment__block{
        padding-top: 0;
    }
    .mortgage .purchase__item__body, .payment .purchase__item__body{
        width: 100%;
        bottom: auto;
        top: 0;
        margin-top: 0;
        margin-bottom: 180px;
    }
    .mortgage__block .purchase__item{
        height: auto;
    }
    .mortgage__block{
        margin:0 -30px 60px;
        .btn{
            max-width: 185px;
        }
    }
    .mortgage .purchase__item, .payment .purchase__item{
        padding-top: 0;
    }
    .mortgage .purchase__item__title, .payment .purchase__item__title{
        font-size: 24px;
    }
    .payment .payment__block{
        margin:0 -30px 60px;
        .btn{
            max-width: 185px;
        }
        &+.payment__block{
            margin-left: 0;
            margin-right: 0;
            margin-bottom: 0;
        }
    }
    .payment-block__title{
        font-size: 22px;
        text-align: left;
        text-transform: uppercase;
        margin-bottom: 20px;
    }
    .payment-block__body{
        flex-direction: column;
    }
    .payment-block__text{
        width: 100%;
        margin: 0;
        font-size: 16px;
    }
    .payment-block__text h2{
        font-size: 18px;
        margin-bottom: 20px;
    }
    .payment .payment-block__image{
        display: none;
    }
    .promotion h1{
        font-size: 28px;
    }
    .promotion .payment__block{
        padding-top: 20px;
    }
    .promotion .payment-block__image{
        width: calc(100% + 60px);
        padding-top: 0;
        margin: 0 -30px 30px;
        height: 300px;
    }
    .promotion .promotions__btn{
        padding-top: 30px;
        text-align: center;
    }
    .promotion .payment__block{
        margin-bottom: 60px;
    }
    .promotion .title{
        font-size: 22px;
        margin-bottom: 20px;
    }
    .additional{
        padding-top: 60px;
    }
    .additional_body{
        width: 100%;
        margin-top: 180px;
    }
    .additional_right .additional_body{
        margin-top: 250px
    }

    .styles__item{
        width: 100%;
    }
    .styles__item__image{
        padding-top: 0;
        position: absolute;
        height: 100%;
    }
    .styles__item__body{
        min-height: 0;
        position: relative;
        margin-bottom: 150px;
    }
    .decoration .breadcrumb{
        padding-bottom: 0;
    }
    .decoration__descr{
        text-align: left;
    }
    .styles{
        margin-bottom: 30px;
    }
    .decoration .block{
        flex-direction: column;
        .block__item{
            width: 100%;
        }
    }
    .icons__title{
        line-height: 1;
        padding-top: 30px;
        font-size: 28px;
        margin-bottom: 30px;
    }
    .icons__item{
        width: 100%;
        margin: 0;
    }
    .decoration-slider1{

    }
    .block__item:first-child{
        margin-top: 0;
    }
    .block__item__text i{
        width: auto;
        height: auto;
        right: 0;
        top: -90px;
        font-size: 110px;
    }
    .decoration-slider2{
        margin-bottom: 60px;
    }
    .styles__item--large .styles__item__body{
        width: 100%;
        height: auto;
        margin-bottom: 150px;
        padding: 40px 30px;
    }
    .styles__item--large .styles__item__title{
        margin-bottom: 30px;
    }
    .styles__item--middle .styles__item__body{
        width: 100%;
        padding: 40px 30px;
        height: auto;
    }
    .decoration-slider__item{
        flex-direction: column;
    }
    .decoration-slider__item__img{
        order: -1;
        width: calc(100% + 60px);
        padding-top: 75%;
        margin-bottom: 30px;
    }
    .decoration-slider__item__body table, 
    .decoration-slider__item__body table tbody,
    .decoration-slider__item__body table tr,
    .decoration-slider__item__body table td {
        display: block;
        width: 100%;
    }

    .decoration-slider__item__body table td {
        display: block;
        padding: 0 !important;
    }
    .list ul li, .decoration-slider__item__body ul li, .privacy ul li, .contacts-tab ul li{
        margin-bottom: 15px;
        font-size: 16px;
        line-height: 1.5;
    }
    .slider__counter .slick-slider .slick-arrow.slick-next{
        right: 30px !important;
    }
    .slider__counter .slider__iterator{
        right: 30px;
        left: 30px;
        width: auto;
        font-size: 16px;
    }
    .slider__counter .slick-slider .slick-arrow.slick-prev{
        left: 30px !important;
    }
    .slider__counter .slick-slider .slick-arrow{
        transform: none;
    }
    .slider__counter .slick-slider .slick-arrow:before{
        font-size: 22px;
    }
    .slick-slider .slick-arrow.slick-prev:hover, .slick-slider .slick-arrow.slick-next:hover{
        transform: none;
    }
    .slider__counter{
        margin: 0;
    }
    .slider__counter  .slick-list{
        transition: height 300ms;
    }
    .decoration-slider__item{
        padding-left: 0;
        padding-right: 0;
    }
    .slider__counter{
        margin-bottom: 60px;
    }
    .decoration .title{
        font-size: 22px;
        font-weight: 500;
        margin-bottom: 30px;
    }
    .decoration__3d{
        margin-bottom: 60px;
    }
    .btn{
        padding-left: 25px;
        padding-right: 25px;
    }
    .slick-slider .slick-arrow.slick-next:hover, .slick-slider .slick-arrow.slick-prev:hover{
        // transform: translateY(-50%);
    }
    .content__menu{
        display: none;
    }
    .decoration-slider1,  .decoration-slider2 {
        .slick-slider .slick-arrow{
            height: 0;
            padding-top: 66%;
            top: 0;
            display: flex !important;
            align-items: center;
            transform: none !important;
        }
    }
    .decoration-slider2 .slick-slider .slick-arrow{
        padding-top: 173px;
    }

    .realty-commerce .params .advants .box{
        padding: 0;
    }

    .promotions .purchase__promotions{
        padding-top: 0;
    }
    .header-block__right{
        min-width: 185px;
    }
    .mobile-actions{
        margin-bottom: 40px;
        .header-block__right{
            margin-left: 0;
            margin-right: 0;
            border-color: #fff;
            padding: 0 12px 12px;
        }
        .header-block__left{
            margin-bottom: 30px;
            text-align: center;
        }
        .header-block__left__body{
            color: #fff;
        }
        .header-block__right__title, .header-block__left__title{
            background: #442463;
            color: #fff;
        }
        .header-block__left__body i{
            top: -12px;
        }
    }
    .header-block{
        top: 80px;
        padding: 10px;
        .header-block__left__body{
            font-size: 24px;
        }
        .header-block__left__body i{
            top: -10px;
            right: -11px;
        }
        .header-block__right{
            margin-left: 15px;
            margin-right: 0;
            padding: 0 10px;
        }
        .header-block__left__title, .header-block__right__title{
            padding: 0 10px;
            // white-space: nowrap;
            box-sizing: border-box;
        }
        .header-block__right{
            font-size: 18px;
        }
        .header-block__right span{
            font-size: 16px;
        }
        .header-block__left__title, .header-block__right__title{
            font-size: 12px;
        }
    }
   .content__block{
        padding-top: 90px;
    }
    .page-counter .content__block{
        padding-top: 160px;
    }
    .header-block .header-block__left__body i{
        // padding: 4px;
        line-height: 1;
        text-align: center;
    }
    .purchase .purchase__item{
        height: auto;
    }
	.benefits {
		margin:60px 0;
	}
	.benefits .benefits__item {
		width: 100%;
		padding: 0 10px;
		margin-bottom:0;
	}
	.benefits .benefits__item i {
		font-size: 60px!important;
		line-height: 60px;
	}
	.benefits .benefits__text {
		font-size:14px;
		min-height:auto;
	}
	
	.modalwin--scheme .modalwin__box {
		display:block;
		height:auto;
        padding: 60px 20px 20px;
    }
	.modalwin--scheme .modalwin__row,
	.modalwin--scheme .modalwin__top,
	.modalwin--scheme .modalwin__bottom {
		display:block;
	}
	.modalwin--scheme .modalwin__bottom {
		padding-bottom:100px;
	}
	.modalwin--scheme .modal__title {
		margin-bottom:30px;
	}
	.modalwin--scheme .modalwin__box p {
		margin-bottom:30px;
		font-size:12px;
	}
	.modalwin--scheme .modalwin__box img {
		position:relative;
		left:0;
		transform:none;
	}
	.modalwin--scheme .modalwin__bottom > div {
		height:auto;
	}
    .news__slider {
        .news__slider--item--text--big {
            font-size: 14px;
            line-height: 1.2;
        }
    }
    .news__slider .slick-slider .slick-arrow {
        display: none !important;
    }
	
	.faq {
		&__body {
			padding:0;
			margin: 10px auto 0;
		}
		&__title {
			font-size: 18px;
			padding: 13px 40px 13px 0;
			&:before {
				top: 24px;
				width: 18px;
				height: 2px;
			}
			&:after {
				top: 16px;
				width: 2px;
				height: 18px;
			}
		}
		&__content {
			display: none;
			padding: 15px 40px 30px 0;
			font-size: 14px;
			p {
				margin-bottom:15px;
			}
		}
	}
	
	
	.unsubscribe-page{
		padding-top: 80px;
	}
	.unsubscribe-block {
		height: calc(100vh - 80px);
	}
	.unsubscribe-block__inner {
		font-size:29px;
		span:before {
			left: -8px;
			right: -8px;
			top: 56%;
			height: 3px;
		}
		a {
		    width: 220px;
			height: 60px;
			line-height: 60px;
			margin: 60px auto 0;
		}
	}

} //1000
@media (max-width: 680px) {
    .slider__item > div{
        padding-top: 0;
        height: 300px;
    }
    .albums, .progress .albums{
        margin: 0;
    }
    .album, .progress .album{
        width: 100%;
        margin: 0 auto 30px;
        max-width: 380px;
        float: none;
    }
    .news__body{
        margin-left: 0;
    }
    .news__item{
        width: 100%;
        margin-left: 0;
    }
	.benefits {
		padding:0;
	}
	.benefits .benefits__item {
		width: 100%;
	}
    .ready .loader {
        transform: scale(1.1) !important;
    }
}
@media (max-width: 370px) {
    .select-mobile__title{
        padding-left: 55px;
        padding-right: 50px;
    }
    .select__indicator--mobile{
        width: 45px;
    }
    .select-mobile__icon{
        left: 12px;
        font-size: 30px;
    }
    .select__indicator--mobile:before{
        right: 12px;
        font-size: 13px;
    }
    .header .box {
        padding-left: 15px;
        padding-right: 15px;
    }
    .loader .logo, .ready .loader{
        width: 100px;
        height: 38px;
    }
    .loader{
        height: 38px;
        top: 20px;
    }
    .ready .loader{
        left: 15px;
    }
    .head__tel{
        margin-right: 15px;
    }
    h1{
        font-size: 28px;
    }
    .purchase__item__body{
        padding-left: 30px;
    }

    .home-mobile .main__item--life{
        padding: 20px 30px;
    }

    .home-mobile .main__item--life .main__item--title{
        font-size: 26px;
        line-height: 28px;
    }

    .h1--promotion{
      padding-top: 70px;
    }

    .h1--promotion .promotion-icon{
      right: auto !important;
      left: 0 !important;
      top: 30px !important;
    }

    .parking .flat-list th, .parking .flat-list td{
      padding: 10px 5px;
    }
	
    .news__slider .news__slider--item .news__slider--item--text {
        max-height: 50px;
		overflow:hidden;
    }
    .ready .loader {
        transform: scale(1) !important;
        left: 5px !important;
    }
}
