.home{
	position: absolute;
	overflow: hidden;
	top: 120px;
	bottom: 0;
	left: 0;
	right: 0;
}
.home> div{
	transition: 2000ms;
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	transform: scale(.35);
	transition-delay: 1s;
}
.mobile-arrow{
	display: none;
}

.ready.main-loading .home> div{
    transform: scale(.35);
}
.ready .home> div{
	transform: scale(1);
}
.ready.main-loading .home__item > div > div{
    opacity: 0;
}
.ready .home__item > div > div{
	opacity: 1;
}
.home__container{
	display: flex;
	flex-wrap: wrap;
}
.home__item{
	position: relative;
	transform: scale(.5);
	opacity: 0;
	transition: 500ms;
}

.map_static{
	background: url('/img/map.jpg') 100% 100% no-repeat;
	background-size: cover;
}

.home-mobile .map_static{
	background: url('/img/map_mob.jpg') 50% 0 no-repeat;
	background-size: cover;
}
.popover{
	width: 300px;
}
.home__item:nth-child(5) { transition-delay: 0.7s; }
.home__item:nth-child(4) {transition-delay: 1.0s; }
.home__item:nth-child(3) {transition-delay: 0.8s; }
.home__item:nth-child(2) {transition-delay: 0.7s; }
.home__item:nth-child(1) {transition-delay: 0.9s; }
.home__item:nth-child(10) {transition-delay: 1.0s; }
.home__item:nth-child(9) {transition-delay: 0.8s; }
.home__item:nth-child(8) {transition-delay: 0.7s; }
.home__item:nth-child(7) {transition-delay: 0.4s; }
.home__item:nth-child(6) {transition-delay: 0.9s; }
.home__item:nth-child(5) {transition-delay: 0.7s; }
.home__item:nth-child(15) {transition-delay: 1.0s; }
.home__item:nth-child(14) {transition-delay: 0.9s; }
.home__item:nth-child(13){transition-delay: 0.8s; }
.home__item:nth-child(12) {transition-delay: 1.0s; }
.home__item:nth-child(11) {transition-delay: 0.7s; }
.home__item:nth-child(20) {transition-delay: 0.7s; }
.home__item:nth-child(19) {transition-delay: 1.0s; }
.home__item:nth-child(18) {transition-delay: 0.8s; }
.home__item:nth-child(17) {transition-delay: 0.7s; }
.home__item:nth-child(16) {transition-delay: 0.9s; }
.home__item:nth-child(25) {transition-delay: 1.0s; }
.home__item:nth-child(24) {transition-delay: 0.8s; }
.home__item:nth-child(23) {transition-delay: 0.7s; }
.home__item:nth-child(22) {transition-delay: 0.9s; }
.home__item:nth-child(21) {transition-delay: 0.7s; }

.ready.main-loading .home__item{
    transform: scale(.5);
    opacity: 0;
}
.ready .home__item{
	transform: scale(1);
	opacity: 1;
}
.home__item > div{
	position: absolute;
	top: 30px;
	left: 30px;
	right: 30px;
	bottom: 30px;
	overflow: hidden;
}
.home__container{
	position: relative;
	transition-timing-function: cubic-bezier(.51,.92,.24,1);
}


.animation-ready .home__container{
    transition: 600ms;
}

.animation-ready .home__container.react-draggable-dragging{
	transition: 0ms;
}

.home__arrow {
	position: absolute;
	cursor: pointer;
	color: #e2068c;
	opacity: 0;
	transition: 3000ms;

	&__top, &__bottom{
		left: 50%; transform: translateX(-50%);
		width: 28px;
		height: 38px;
		font-size: 38px;
	}
	&__left, &__right{
		top: 50%; transform: translateY(-50%);
		width: 38px;
		height: 28px;
		font-size: 28px;
	}

	&__top{
		top: 20px;
	}
	&__bottom{
		bottom: 20px;
	}
	&__left{
		left: 20px;
	}
	&__right{
		right: 20px;
	}

	i{
		position: absolute;
		top: 0;
		left: 0;
		transition: 300ms;
		z-index: 1;             
		&:hover {
			transform: scale(1.3) !important;
       }
	}
}
.ready.main-loading .home__arrow{
    opacity: 0;
}
.ready .home__arrow{
	opacity: 1;
}
.home__arrow__thumbs {
	position: absolute;
	pointer-events: none;
	transform: scale(0);
	transition: 600ms;
    width: 16.7vw;
    height: 10.2vw;
    max-width: 321px;
    max-height: 196px;
    border: solid 18px #fff;
    background-size: cover;
    background-position: 50% 50%;
    background-repeat: no-repeat;

	&__top{
		top: 45px;
		left: 12px;
		transform-origin: top left;
	}
	&__bottom{
		bottom: 45px;
		right: 12px;
		transform-origin: bottom right;
	}
	&__left{
		left: 45px;
		top: 12px;
		transform-origin: top left;
	}
	&__right{
		right: 45px;
		top: 12px;
		transform-origin: top right;
	}
}
.home__arrow:hover .home__arrow__thumbs{
       transform: scale(1);
}
.mw400{
	max-width: 400px;
}