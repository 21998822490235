/*----------animations names-----------*/
@keyframes logo_item1_anim {
    0% {
        opacity: 0;
    }
    33% {
        opacity: 1;
    }
    66% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

@-webkit-keyframes logo_item1_anim {
    0% {
        opacity: 0;
    }
    33% {
        opacity: 1;
    }
    66% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

@-webkit-keyframes zoomInLogo {
    from {
        opacity: 0;
        -webkit-transform: translate(-50%, -50%) scale(1);
        transform: translate(-50%, -50%) scale(1);
    }

    50% {
        opacity: 1;
    }
}

@keyframes zoomInLogo {
    from {
        opacity: 0;
        -webkit-transform: translate(-50%, -50%) scale(1);
        transform: translate(-50%, -50%) scale(1);
    }

    50% {
        opacity: 1;
    }
}


@-webkit-keyframes zoomNormalLogo {
    from {
    }
    to {
        -webkit-transform: translate(-50%, -50%) scale(1);
        transform: translate(-50%, -50%) scale(1);
    }
}

@keyframes zoomNormalLogo {
    from {
    }
    to {
        -webkit-transform: translate(-50%, -50%) scale(1);
        transform: translate(-50%, -50%) scale(1);
    }
}


@-webkit-keyframes anim_zoom {
    from {
    }
    to {
        -webkit-transform: scale(1.1);
        transform: scale(1.1);
    }
}
@keyframes anim_zoom {
    from {
    }
    to {
        -webkit-transform: scale(1.1);
        transform: scale(1.1);
    }
}


@-webkit-keyframes anim_zoom_modal {
    from {
    }
    to {
        -webkit-transform: scale(1);
        transform: scale(1);
        opacity: 1;
    }
}
@keyframes anim_zoom_modal {
    from {
    }
    to {
        -webkit-transform: scale(1);
        transform: scale(1);
        opacity: 1;
    }
}

@-webkit-keyframes anim_zoom_modal_close {
    from {
        -webkit-transform: scale(1);
        transform: scale(1);
        opacity: 1;
    }
    to {
        -webkit-transform: scale(0.5);
        transform: scale(0.5);
        opacity: 0;
    }
}
@keyframes anim_zoom_modal_close {
    from {
        -webkit-transform: scale(1);
        transform: scale(1);
        opacity: 1;
    }
    to {
        -webkit-transform: scale(0.5);
        transform: scale(0.5);
        opacity: 0;
    }
}

@-webkit-keyframes anim_left {
    from {
    }
    to {
        -webkit-transform: translateX(-8%);
        transform: translateX(-8%);
    }
}

@keyframes anim_left {
    from {
    }
    to {
        -webkit-transform: translateX(-8%);
        transform: translateX(-8%);
    }
}


@-webkit-keyframes anim_right {
    from {
    }
    to {
        -webkit-transform: translateX(8%);
        transform: translateX(8%);
    }
}

@keyframes anim_right {
    from {
    }
    to {
        -webkit-transform: translateX(8%);
        transform: translateX(8%);
    }
}


@-webkit-keyframes anim_top {
    from {
    }
    to {
        -webkit-transform: translateY(-15%);
        transform: translateY(-15%);
    }
}

@keyframes anim_top {
    from {
    }
    to {
        -webkit-transform: translateY(-15%);
        transform: translateY(-15%);
    }
}

@keyframes FadeIn {
    from {
        transform: translateY(-40px);
        opacity: 0;
    }
    to {
        transform: translateY(0);
        opacity: 1;
    }
}

@-webkit-keyframes rollIn {
    from {
        opacity: 1;
        -webkit-transform: translate3d(-200%, 0, 0) rotate3d(0, 0, 1, -120deg);
        transform: translate3d(-200%, 0, 0) rotate3d(0, 0, 1, -120deg);
    }

    to {
        opacity: 1;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}

@keyframes rollIn {
    from {
        opacity: 1;
        -webkit-transform: translate3d(-200%, 0, 0) rotate3d(0, 0, 1, -120deg);
        transform: translate3d(-200%, 0, 0) rotate3d(0, 0, 1, -120deg);
    }

    to {
        opacity: 1;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}


@-webkit-keyframes rollIn_else {
    from {
        opacity: 1;
        -webkit-transform: translate3d(200%, 0, 0) rotate3d(0, 0, 1, 120deg);
        transform: translate3d(200%, 0, 0) rotate3d(0, 0, 1, 120deg);
    }

    to {
        opacity: 1;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}

@keyframes rollIn_else {
    from {
        opacity: 1;
        -webkit-transform: translate3d(200%, 0, 0) rotate3d(0, 0, 1, 120deg);
        transform: translate3d(200%, 0, 0) rotate3d(0, 0, 1, 120deg);
    }

    to {
        opacity: 1;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}

/*----------end animations names-----------*/