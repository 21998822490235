.page-counter .home-mobile{
	padding-top: 160px;
}
.home-mobile{
	padding-top: 80px;
	.findflat-link-abs{
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
	.home__item{
		// height: calc(100vh - 55px);
		// min-height: 400px;
		margin-bottom: 55px;
	}
	.home__item > div{
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		box-sizing: border-box;
	}
	.main__item--video__content .main__item--video__links{
		padding: 0 30px;
	}
	.main__item--video__content{
		padding-left: 25px;
		padding-right: 25px;
		padding-top: 45px;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
	}
	.main__item--video__content .main__item--title{
		padding: 0 ;
		position: static;
		height: auto;
		text-align: left;
		display: block;
	}
	.main__item--video__content .main__item--video__links i{
		height: auto;
		margin-bottom: 15px;
	}
	.main__item--video__content .main__item--video__links i:before{
		font-size: 35px;
	}
	.main__item--photo .main__item--title{
		z-index: 2
	}
	& > div{
		display: flex;
		flex-direction: column;
	}
	
	// .home__item_202 {
	// 	order: -17;
	// }
	// .home__item_0 {
	// 	order: -18;
	// }
	// .home__item_214{
	// 	order: -16;
	// }
	// .home__item_215{
	// 	order: -15;
	// }
	// .home__item_236{
	// 	order: -16;
	// }
	// .home__item_228{
	// 	order: -14;
	// }
	// .home__item_213{
	// 	order: -13;
	// }
	// .home__item_229, .home__item_212{
	// 	order: -13;
	// }
	// .home__item_video{
	// 	order: -10;
	// }
	// .home__item_findflat{
	// 	order: -9;
	// }
	// .home__item_ecology{
	// 	order: -8;
	// }
	// .home__item_mortgage{
	// 	order: -8;
	// }
	// .home__item_comfort{
	// 	order: -7;
	// }
	// .home__item_photos{
	// 	order: -6;
	// }
	// .home__item_map{
	// 	order: -5;
	// }
	// .home__item_life{
	// 	order: -4;
	// }
	// .home__item_news{
	// 	order: -3;
	// }
	// .home__item_flatprice{
	// 	order: -12;
	// }
	// .home__item_tc{
	// 	order: -2;
	// 	margin-bottom: 0;
	// }
	// .home__item_tc .mobile-arrow{
	// 	display: none !important;
	// }

	// .home__item_undefined{
	// 	// order: -1;
	// 	margin-bottom: 0;
	// }
	
  .home__item_248, .home__item_247 {
    display: none;
  }
  .home__item_news{
    order: -1;
  	height: calc(var(--vh, 1vh)*100 - 130px)!important;
  }
	.home__item_269 {
		order: -1;
	}
  .home__item:last-child .mobile-arrow{
    display: none !important;
  }
  .home__item:last-child{margin-bottom: 0;}
	.landscape, .portrait{
		display: none;
	}
	.home__item > div{
		overflow: visible;
	}
	.mobile-arrow{
		display: block;
	    position: absolute;
	    bottom: -41px;
	    height: 55px;
	    left: 0;
	    width: 100%;
	    display: flex;
	    align-items: center;
	    justify-content: center;
	    z-index: 2;
	}
	.mobile-arrow i{
		font-size: 50px;
		color: #dadada;
	}

    .main__item--but i:before{
        font-size: 34px;
        color: #e2068c;
    }
    .main__item--tc .main__item--but i:before{
		color: #fff;
    }
    .main__item--but{
        font-size: 12px;
        max-width: 260px;
        padding: 0 30px;
    }
    i.icon-i_heart:before {
    	color: #4b2365
    }
    .residental__icon a>span, .residental__icon span>span{
    	border-bottom: none;
    }
}

@media (orientation: landscape) {
	.home-mobile{
		.landscape{
			display: block;
		}
		.home__item{
			padding-top: 58%;
		}
		.home__item_video{
			padding-top: calc(58% - 80px)
		}
		.main__item--life .main__item--title{
			font-size: 26px;
			line-height: 1;
		}
		.main__item--ecology{
			flex-direction: column;
			justify-content: center;
			// align-items: center;
			padding: 20px;
			height: 100%;
		}
		.main__item--ecology .main__item--but{
			align-self: flex-start;
		}
		.main__item--mortgage{
			flex-direction: column;
			justify-content: center;
			// align-items: center;
			padding: 20px;
			height: 100%;
		}
		.main__item--mortgage .main__item--but{
			align-self: flex-start;
		}
		.news__slider{
			padding-bottom: 60px;
		}
		.main__item--news .slick-slider .slick-arrow{
			bottom: -60px;
		}
		.main__item--photo .main__item--title{
			padding-bottom: 20px;
		}
		.main__item--photo .main__item--title p{
			font-size: 16px;
			line-height: 1.2
		}
	}
}

@media (orientation: portrait) {
	.home-mobile{
		.portrait{
			display: block;
		}
		.home__item{
			padding-top: 0;
			height: calc(100vh - 130px);
			min-height: 380px;
			&:first-child {
				height: calc(var(--vh, 1vh) * 100 - 130px);
				min-height: 250px;
				.home__item__bg {
					background-position: 50% 30%;
				}
			}
		}
		.home__item_video{
			height: calc(100vh - 135px);
		}
		.main__item--video__content .main__item--video__links{
			position: static;
			flex-direction: column;
		}
		.main__item--video__content .main__item--video__links i{
			margin: 0 20px 0 0;
		}
		.main__item--video__content .main__item--video__links a{
			width: 100%;
			display: flex;
			align-items: center;
			padding: 0;
			margin-bottom: 12%;
			text-align: left;
			&:last-child{
				margin-bottom: 45px;
			}
		}
		.main__item--video:before{
			display: none;
		}
		.home__item__bg{
			// background-size: contain;
		}
		.main__item--flatprice .main__item--flatprice__but{
			opacity: 1;
		}
		.main__item--findflat{
			left: 0;
			min-height: 60%;
			height: auto;
			top: auto;
			bottom: 0;
		}
		.main__item--findflat .main__item--title{
			font-size: 26px;
		}
		.main__item--findflat .main__item--but{
			display: flex;
			justify-content: center;
			align-items: center;
			line-height: 1;
		}
		.main__item--but.main__item--but__star i{
			margin-right: 10px;
		}
		.main__item--ecology .main__item--title{
			font-size: 28px;
			text-transform: uppercase;
			line-height: 1.2;
		}
		.main__item--ecology{
			padding: 40px 25px;
			height: 100%;
			display: flex;
			align-items: center;
			justify-content: space-between;
			text-align: left;
			flex-direction: column;
		}
		.main__item--mortgage .main__item--title{
			font-size: 35px;
			text-transform: uppercase;
			line-height: 1.2;
			span {
				font-size: 35px;
			}
		}
		.main__item--mortgage{
			padding: 40px 25px;
			height: 100%;
			display: flex;
			align-items: center;
			justify-content: flex-end;
			text-align: left;
			flex-direction: column;
		}
		.main__item--comfort{
			padding: 40px 25px;
			height: 100%;
			display: flex;
			align-items: flex-start;
			justify-content: space-between;
			text-align: left;
			flex-direction: column;
			top: 0;
			left: 0;
			& > div{
				max-width: 750px;
				margin: 0 auto 10px;
				padding-right: 50px;
				line-height: 1.5
			}
		}
		.main__item--comfort .main__item--title{
			font-size: 32px;
			padding-right: 0;
			line-height: 1
		}
		.main__item--comfort .main__item--but {
			margin: auto auto 0;
		}
		.main__item--but{
			min-height: 65px;
		}
		.main__item--photo .main__item--title{
			font-size: 24px;
			width: 100%;
			text-align: right;
			padding-right: 30px;
			bottom: 130px;
		}
		.main__item--but.main__item--but__brush{
			margin-bottom: 40px;
			margin-left: 0;
			margin-right: 0;
			left: 50%;
			transform: translateX(-50%);
			right: auto;
		}
		.main__item--life{
			right: 0;
		}
		.main__item--life{
			justify-content: flex-start;
			padding: 35px 30px;
			.main__item--but{
				margin-top: auto;
			}
		}
		.main__item--news{
			width: 100%;
			height: auto;
			top: auto;
			bottom: 0;
			padding: 30px;
			justify-content: flex-start;
		}
		.main__item--news .news__slider{
			padding-bottom: 0;
		}
		.main__item--news .main__item--title{
			font-size: 28px;
			margin: 0;
			line-height: 1;
			padding-bottom: 20px;
		}
		.slick-slider{
			position: initial;
		}
		.main__item--news .slick-slider .slick-arrow.slick-next{
			left: auto !important;
			right: 30px !important;
			bottom: auto !important;
			top: 32px !important;
			height: 45px !important;
		}
		.main__item--news .slick-slider .slick-arrow.slick-prev{
			left: auto !important;
			right: 70px !important;
			bottom: auto !important;
			top: 32px !important;
			height: 45px !important;
		}
		.main__item--but.main__item--but__white.main__item--but--bott{
			position: absolute;
			bottom: 40px;
			left: 50%;
			transform: translateX(-50%);
			max-width: 245px;
		}
		.main__item--map{
			background: rgba(68,36,99,.9);
			top: auto;
			bottom: 0;
			left: 0;
			right: 0;
			width: auto;
		}
		.main__item--map .main__item--map__in{
			background: transparent;
			font-size: 16px;
			text-align: center;
			p{
				font-size: 28px;
			}
		}
		.main__item--map .main__item--map__but{
			position: static;
			transform: none;
			margin: 0 0 40px;
			width: 100%;
			text-align: center;
			a{
				margin: 0 auto;
				background: transparent;
				border: 2px solid #e2068c;
				color: #fff;
			}
		}
		.main__item--map .main__item--but i{
			margin-right: 10px;
		}

		.main__item--video__content{
			padding-left: 42px;
		}
		.main__item--video__content .main__item--title{
			line-height: 1.2;
			font-size: 32px;
		}
		.main__item--video__content .main__item--video__links{
			padding: 0;
		}
		.main__item--comfort .main__item--but,
	    .main__item--but.main__item--but__white, .main__item--findflat .main__item--but, 
	    .main__item--but,
	    .main__item--but.main__item--but__brush{
	    	max-width: 260px;
	    	padding-left: 25px;
	    	padding-right: 25px;
	    	margin-left: auto;
	    	margin-right: auto;
	    }
	    .main__item--but.main__item--but__brush{
	    	margin-right: 30px;
	    }
	    .main__item--but i{
	    	margin-right: 20px;
	    }
		.main__item--tc .main__item--title{
			font-size: 29px;
		}
	}
}